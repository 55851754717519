import Swal from "sweetalert2";
import { loginRequest } from "../pages/Login/authConfig";

export function parseJwt(token) {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );
  return JSON.parse(jsonPayload);
}

export const setLocalStorageValues = (response) => {
  const jwt = parseJwt(response.idToken);
  const email = response?.account?.username;
  const username =
    jwt.given_name !== "unknown" && jwt.given_name !== "undefined" ? jwt.given_name+" "+jwt.family_name : email;
  const duration = (jwt.exp - new Date().getTime() / 1000).toString();
  localStorage.setItem("cx_access_token", response.idToken);
  localStorage.setItem("username", username);
  localStorage.setItem("user_email", email);
  localStorage.setItem("token_expiry", jwt.exp.toString());
  localStorage.setItem("token_duration", duration);
  localStorage.setItem("home_account_id", response.account.homeAccountId);
};

export const extendSession = ({props,setToken}) => {
  const request = {
    ...loginRequest,
    account: props?.msalContext.accounts[0],
    redirectUri: "/auth/redirect",
    state: window.location.href,
  };
  // localStorage.clear();
  props?.msalContext.instance
    .acquireTokenSilent(request)
    .then((response) => {
      setLocalStorageValues(response);
      setToken(response.idToken);
    })
    .catch((e) => {
      props.msalContext.instance.acquireTokenPopup(request)
          .then((response) => {
            setLocalStorageValues(response);
            setToken(response.idToken);
          }).catch(()=>{
            Swal.fire({
              title: "Oops...",
              text: "Not able to log you in , Check with your administrator",
              icon: "error",
              "data-test-id": "loginError",
            });
          })
    });
};
