import { useNavigate } from "react-router-dom";
import "./components.css";
import { appendQueryParamToURL } from "../helpers";

export default function Notfound() {
    const navigate = useNavigate();

  const navigateToHome = () => {
      const urlWithParams = appendQueryParamToURL(process.env.REACT_APP_BASE_URL);
      navigate(urlWithParams);
  };

    return (
      <div className="notfounddiv">
        <span className="oopstitle">Oops!</span>
        <span className="pagenftitle">404 Not Found</span>
        <span>Sorry, an error has occured, Requested page not found!</span>
        <br />
        <button
          type="submit"
          className="takeMeHomeButton"
          onClick={() => navigateToHome()}
        >
          Take Me Home
        </button>
      </div>
    );
}
