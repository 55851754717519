import {
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import React from "react";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import { styled } from "@mui/material/styles";
import {
  mapDispatchToProps,
  mapStateToProps,
  appendQueryParamToURL,
} from "../helpers";
import { connect } from "react-redux";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});
const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));
const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const DrawerComponent = (props) => {
  let stepperLength = localStorage.getItem("stepperLength");

  const onClickListButton = (index, obj) => {
    !props.isSubmitted && props.saveApiCall();
    clickOnListButton(index, obj);
  };

  const clickOnListButton = (index, obj) => {
    if (obj === "Home") {
      props.handleDrawerOpen(index + 1);
      localStorage.removeItem("appId");
      localStorage.removeItem("loan-app-dashboard");
      localStorage.removeItem("userName");
      const urlWithParams = appendQueryParamToURL(
        process.env.REACT_APP_BASE_URL
      );
      props.navigatePage(urlWithParams);
    } else {
      if (props.disable) {
        return;
      }
      props.stepperBackStep(false);
      props.activeStep(0);
      props.handleDrawerOpen(index + 1);
    }
  };
  return (
    <Drawer
      variant="permanent"
      open={
        props.open || (props.isHovered && !props.isClicked) || props.isHovered
      }
      SlideProps={{ unmountOnExit: true }}
      onMouseEnter={props.handleMouseEnter}
      onMouseLeave={props.handleMouseLeave}
    >
      <List style={{ paddingTop: "80px" }}>
        {props.sideMenuButtons.map((object, index) => (
          <ListItem
            className={object.className}
            key={object.buttonName}
            disablePadding
            sx={{ display: "block" }}
            style={{
              backgroundColor:
                index + 1 === parseInt(props.pageIndex) ? "#ECFDF3" : "white",
              pointerEvents: props.disable ? "none" : "auto",
              opacity: props.disable ? 0.2 : 1,
            }}
          >
            <ListItemButton
              onClick={() => onClickListButton(index, object.buttonName)}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: props.open || props.isHovered ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <object.icon
                  color={
                    index + 1 === parseInt(props.pageIndex)
                      ? "#00634A"
                      : "#344054"
                  }
                />
              </ListItemIcon>
              <ListItemText
                primary={
                  <span className="listItemIconTag">{object.buttonName}</span>
                }
                sx={{ opacity: props.open || props.isHovered ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      {/* <List style={{ position: "absolute", top: 0, left: 0, paddingTop: "60px"}}>
        <DrawerHeader>
          <IconButton onClick={props.setOpen}>
            <MenuRoundedIcon/>
          </IconButton>
        </DrawerHeader>
      </List> */}
    </Drawer>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(DrawerComponent);
export { DrawerComponent };
