import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { DialogTitle, Divider, IconButton } from "@mui/material";
import coapplicatlist from "./coapplicant.json";
import JsonSchemaForms from "../../../json_schema_forms";
import { connect } from "react-redux";
import {
  mapDispatchToProps,
  mapStateToProps,
  setValueByRecursion,
} from "../../../helpers";
import _ from "lodash";
import CloseIcon from "@mui/icons-material/Close";
import React, { useEffect, useState } from "react";
import { CustomDialog } from "../save_grid";
import { submitCall } from "../constants";
import { saveLoanCall } from "../constants";

const CoApplicant = (props) => {
  const [state, setState] = React.useState(props.componentState);
  const [jsonSchema, setJsonSchema] = React.useState(
    _.cloneDeep(coapplicatlist)
  );
  const [isFinishButtonDisabled, setIsFinishButtonDisabled] = useState(true);

  const [
    isCoApplicantStateLossDialogOpen,
    setIsCoApplicantStateLossDialogOpen,
  ] = useState(false);
  const [isSubmitLoanDialogOpen, setIsSubmitLoanDialogOpen] = useState(false);

  useEffect(() => {
    return () => {
      setJsonSchema(_.cloneDeep(coapplicatlist));
    };
  }, []);
  useEffect(() => {
    if (!_.isEmpty(props.componentState)) {
      setState(props.componentState);
    }
    let coApplicantDetails = _.get(
      props.componentState,
      "CoApplicant.CoApplicant",
      {}
    );
    let flag = false;
    let mandatoryFields = ["FirstName", "LastName", "Email"];

    if (coApplicantDetails.length > 0) {
      for (let i = 0; i < coApplicantDetails.length; i++) {
        const applicant = coApplicantDetails[i];
        for (let field of mandatoryFields) {
          if (
            props?.errorsDictionary?.hasOwnProperty(field) ||
            !applicant?.hasOwnProperty(field)
          ) {
            flag = true;
            break;
          }
        }
      }
      setIsFinishButtonDisabled(flag);
    }
  }, [props?.componentState, props.errorsDictionary]);

  useEffect(() => {
    if (props.componentSchema?.coapplicatlist) {
      setJsonSchema(props.componentSchema["coapplicatlist"]);
    }
  }, [props.componentSchema]);

  useEffect(() => {
    if (!_.isEmpty(props.saveLoanDataRes)) {
      if (props.saveLoanDataRes?.status === 200) {
        props.getLoanData(localStorage.getItem("appId"));
      }
    }
  }, [props.saveLoanDataRes]);

  useEffect(() => {
    if (!_.isEmpty(props.getLoanDataWithIdRes)) {
      if (props.getLoanDataWithIdRes?.status === 200) {
        props.copyStateToRedux(props.getLoanDataWithIdRes?.data);
      }
    }
  }, [props.getLoanDataWithIdRes]);

  const onLoadFunc = (updatedSchema) => {
    props.copySchema(updatedSchema, "coapplicatlist");
  };

  const updateJsonSchema = () => {
    let updatedSchema = jsonSchema;
    let tempJsonElement =
      jsonSchema.properties.CoApplicant.properties.CoApplicant;
    tempJsonElement.length = 0;
    let elementPath = tempJsonElement?.path;
    updatedSchema = setValueByRecursion(
      updatedSchema,
      elementPath,
      (i) => tempJsonElement,
      []
    );
    setJsonSchema(updatedSchema);
  };

  const onYesCoApplicantStateLossDialog = () => {
    props.getLoanData(localStorage.getItem("appId"));
    updateJsonSchema();
    let stateCoApps = state?.CoApplicant?.CoApplicant;
    let loanObjCoApps = props.getLoanDataWithIdRes?.data?.applications;
    stateCoApps.map((coAppData, index) => {
      if (
        coAppData?.Email ===
        loanObjCoApps?.[index + 1]?.borrower?.emailAddressText
      ) {
      } else {
        state?.CoApplicant?.CoApplicant?.splice(index, 1);
        updateJsonSchema();
        props.copyStateToRedux(state);
      }
    });
    props.onClose();
    setIsCoApplicantStateLossDialogOpen(false);
    setIsSubmitLoanDialogOpen(true);
  };

  const onCancel = () => {
    setIsCoApplicantStateLossDialogOpen(true);
  };

  const onNoCoApplicantStateLossDialog = () => {
    setIsCoApplicantStateLossDialogOpen(false);
  };

  const onCloseSubmitDialog = () => {
    setIsCoApplicantStateLossDialogOpen(false);
    setIsSubmitLoanDialogOpen(false);
  };

  const onSubmitLoan = () => {
    props.onClose();
    setIsCoApplicantStateLossDialogOpen(false);
    setIsSubmitLoanDialogOpen(false);
    submitCall(props, props.encompassLoanObject);
  };

  const changeHandler = (updatedState, updatedSchema) => {
    props.copyStateToRedux(updatedState);
    props.copySchema(updatedSchema, "coapplicatlist");
    setState(updatedState);
  };
  const finish = () => {
    props.onFinish();
    props.onClose();
    saveLoanCall(props, props.encompassLoanObject);
  };

  return (
    <div>
      <Dialog
        fullWidth
        maxWidth={false}
        sx={{ maxWidth: "1100px", margin: "auto" }}
        open={props.open}
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{ fontSize: "16px", fontFamily: "Roboto-Medium" }}
        >
          Add Co-Applicants
          <Divider />
        </DialogTitle>
        <IconButton
          aria-label="close"
          // onClick={props.onClose}
          onClick={onCancel}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <DialogContentText className="contentClassName">
            Start adding co-applicants by clicking on the button
          </DialogContentText>
          <JsonSchemaForms
            state={state}
            jsonData={jsonSchema}
            changeHandler={changeHandler}
            pageKey={"CoApplicants"}
            onLoadFunc={onLoadFunc}
          />
        </DialogContent>
        <DialogActions>
          <Button className="inActiveNoButton" onClick={onCancel}>
            Cancel
          </Button>
          <Button
            className={
              isFinishButtonDisabled ? "disable_button" : "activeYesButton"
            }
            disabled={isFinishButtonDisabled}
            onClick={finish}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <CustomDialog
        open={isCoApplicantStateLossDialogOpen}
        onClose={onNoCoApplicantStateLossDialog}
        onSubmit={onYesCoApplicantStateLossDialog}
        content="All the changes you have made for co-applicant will be lost. Are you sure you want to cancel?"
      />
      <CustomDialog
        open={isSubmitLoanDialogOpen}
        onClose={onCloseSubmitDialog}
        onSubmit={onSubmitLoan}
        content="Do you want to submit the loan?"
      />
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CoApplicant);
