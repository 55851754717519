import * as React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import "./components.css";

export default function RadioButtonsGroup(props) {
  const { label, options, onChange, enumValues } = props;
  return (
    <FormControl>
      <label
        id="demo-radio-buttons-group-label"
        className="titleClassName"
        style={{ fontSize: "14px" }}
      >
        {label}
      </label>
      <RadioGroup
        data-testid="radiogroup"
        aria-labelledby="demo-radio-buttons-group-label"
        defaultValue="female"
        name="radio-buttons-group"
        onAnimationStart={(e) => props.onLoad(e)}
        onChange={(e) => onChange(e)}
      >
        {options?.map((option, index) => (
          <FormControlLabel
            key={index}
            value={enumValues[index]}
            checked={enumValues[index] === props?.value}
            control={
              <Radio
                className="radioButtonClassName"
                sx={{
                  "& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root)":
                    {
                      fontSize: '18px'
                    },
                  "& .MuiSvgIcon-root + .MuiSvgIcon-root": {
                    fontSize: '18px'
                  },
                }}
              />
            }
            label={<span className="checkboxLabelClassName">{option}</span>}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
}
