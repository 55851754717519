import { getLabel } from '../helper';
import questionsData from '../userQuestionnaire/questions.json';
import axios from 'axios';
import { API_URL } from '../../redux/constants';
import './Summary.css';
import ModeEditIcon from '@mui/icons-material/ModeEdit'; // Assuming ModeIcon is an edit icon

const Summary = ({ handleFormSubmit, handleEdit, userCode, refinanceData }) => {
    const handleSubmission = () => {
        axios.put(`${API_URL}/customer-service/api/v1/refi/update/${userCode}`, refinanceData)
            .then((response) => {
                handleFormSubmit(response.data);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const formatCurrency = (value) => {
        if (!isNaN(value)) {
            // Format the number as currency
            let formatted = value.toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
                minimumFractionDigits: 2,
            });

            // Replace the currency symbol with a symbol followed by a space
            formatted = formatted.replace(/\$/g, '$ ');

            return formatted;
        }
        return value;
    };

    const capitalizeFirstLetter = (string) => {
        if (typeof string !== 'string') return string;

        // Add spaces before capital letters and capitalize the first character of each word
        return string
            // Insert a space before all caps and convert underscores/hyphens to spaces
            .replace(/([A-Z])|[_-]+/g, ' $1')
            // Trim spaces at the beginning and replace multiple spaces with a single space
            .trim()
            .replace(/\s\s+/g, ' ')
            // Capitalize the first letter of each word
            .replace(/\b\w/g, letter => letter.toUpperCase());
    };
    const renderValue = (value, label) => {
        if (label.toLowerCase() === 'creditscore' || label.toLowerCase() === 'numberofunits') {
            return value;
        }
        if (typeof value === 'object' && value !== null) {
            // Map over the entries of the object and display key-value pairs
            return Object.entries(value).map(([key, val]) => {
                // Directly use the value here without any modification
                return (
                    <div key={key}>
                        <strong> {capitalizeFirstLetter(key)}: </strong> {val}
                    </div>
                );
            });
        }
        const fieldType = /^\d+$/.test(value);

        if (/^\d+$/.test(value)) {
            return formatCurrency(parseInt(value));
        } else if (typeof value === 'number' && fieldType) {
            return formatCurrency(value);
        }

        // Directly return the value if it's not an object
        return value;
    };

    return (
        <div className='previewContainer'>
            <div className='inside-preview'>
                {questionsData.map((question) => (
                    <div className='item' key={question.field}>
                        <div className='itemContainer'>
                            <div className='leftColumn'>
                                {(getLabel(question.text))}
                                <div className='edit-icon'>
                                    <ModeEditIcon onClick={handleEdit} />
                                </div>
                            </div>
                            <div className='rightColumn'>

                                {renderValue(refinanceData[question.field], question.field)}

                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <div className="submit-buttons">
                <button onClick={handleSubmission}>Submit</button>
            </div>
        </div>
    );
};

export default Summary;
