import React, { useEffect } from "react";
import JsonSchemaForms from "../../../json_schema_forms";
import list_Of_Liabilities from "./liabilities.json";
import { connect } from "react-redux";
import {
    mapDispatchToProps,
    mapStateToProps
} from "../../../helpers";
import _ from "lodash";
function ManageLiabilities(props) {
    const [state, setState] = React.useState(props.componentState);
    const [jsonSchema, setJsonSchema] = React.useState(_.cloneDeep(list_Of_Liabilities));
    useEffect(() => {
        return () => {
            setJsonSchema(_.cloneDeep(list_Of_Liabilities))
        }
    }, [])
    useEffect(() => {
        if (!_.isEmpty(props.componentState)) {
            setState(props.componentState);
        }
    }, [props.componentState]);
    useEffect(() => {
        if (props.componentSchema?.listOfLiabilities) {
            setJsonSchema(props.componentSchema["listOfLiabilities"]);
        }
    }, [props.componentSchema]);




    const onLoadFunc = (updatedSchema) => {
        props.copySchema(updatedSchema, "listOfLiabilities")
    }
    const changeHandler = (updatedState, updatedSchema) => {
        props.copyStateToRedux(updatedState);
        props.copySchema(updatedSchema, "listOfLiabilities");
        setState(updatedState);
    }
    return (
        <JsonSchemaForms
            state={state}
            jsonData={jsonSchema}
            changeHandler={changeHandler}
            pageKey={"ManageLiabilities"}
            onLoadFunc={onLoadFunc}
        />
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageLiabilities);