import { InputLabel, TextField, Tooltip } from "@mui/material";
import NumberFormat from "react-number-format";
import React from "react";
import "./components.css";

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(props, ref) {
  const { onChange, min, max, ...other } = props;
  return (
    <NumberFormat
      {...other}
      isAllowed={(values) => {
        const { formattedValue, floatValue } = values;
        return (
          formattedValue === "" || (floatValue >= min && floatValue <= max) || formattedValue === "-"
        );
      }}
      onValueChange={(values) => {
        if (values.value !== props.value) {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }
      }}
      decimalScale="2"
      thousandSeparator
      isNumericString
      allowNegative={min !== 0}
    />
  );
});

export default function NumberComponent(props) {
  const {
    label,
    onChange,
    required,
    name,
    value,
    minimum,
    maximum,
    tooltip,
    prefix,
    error,
    helperText,
  } = props;
  let min, max;

  if (minimum !== undefined && maximum !== undefined) {
    min = parseInt(minimum);
    max = parseInt(maximum);
  }

  const blockInvalidChar = (e) =>
    ["e", "E"].includes(e.key) && e.preventDefault();

  const blockDot = (e) => ["e", "E", "."].includes(e.key) && e.preventDefault();

  const handleOnChange = (e) => {
    onChange(e);
  };
  const tooltipMsg = () => {
    if (tooltip) {
      return tooltip;
    } else if (min !== undefined && max !== undefined) {
      return `(Ex:- ${prefix === "$" ? formatter.format(min) : min} to ${prefix === "$" ? formatter.format(max) : max
        } range)`;
    }
  };


  return (
    <div
      className="flexAlignColumnClassName"
      style={{ marginBottom: helperText ? "10px" : "0px" }}
      onClick={(e) => { e.preventDefault() }}
    >
      <InputLabel
        htmlFor="outlined-adornment-amount"
        required={required}
        className="labelClassName alignRowClassName flex-start"
      >
        {label}
        {tooltipMsg() && (
          <Tooltip data-testid="tooltip" title={tooltipMsg()} arrow>
            <span className="tooltipInfoIcon">i</span>
          </Tooltip>
        )}
      </InputLabel>
      <TextField
        data-testid="textfield"
        type={prefix === "$" ? null : "number"}
        inputProps={{
          min: min,
          max: max,
        }}
        name={name}
        autoComplete="off"
        id="outlined-adornment-amount"
        className="textFieldInputComponent"
        onKeyDown={prefix === "$" ? blockInvalidChar : blockDot}
        value={!isNaN(value) ? value.toString() : ""}
        onAnimationStart={(e) => props.onLoad(e)}
        onChange={(e) => handleOnChange(e)}
        required={required}
        InputProps={{
          inputComponent: prefix === "$" && NumberFormatCustom,
          classes: {
            root: error
              ? "outlinedErrorInputRoot menuItemOptionTag"
              : "outlinedInputRoot menuItemOptionTag",
            focused: error
              ? "outlinedErrorInputFocused"
              : "outlinedInputFocused",
            notchedOutline: "outlinedInput",
          },
          startAdornment: prefix && (
            <div style={{ paddingRight: 5 }}>{prefix}</div>
          ),
        }}
        FormHelperTextProps={{
          classes: {
            root: "helperTextClassName",
          },
        }}
        helperText={helperText}
        error={error}
      />
    </div>
  );
}
