import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Stack } from "@mui/material";
import "./components.css";

function ConsentButtons(props) {
  const [value, setValue] = useState();
  const theme = createTheme({
    palette: {
      neutral: {
        main: "#00634A",
        contrastText: "#fff",
      },
    },
  });

  useEffect(() => {
    setValue(props?.value);
  }, [props?.value]);

  const onClick = (e) => {
    setValue(e.target.value);
    props.onClick(e);
  };
  return (
    <ThemeProvider theme={theme}>
    <Stack
      style={{
        pointerEvents: props.disabled ? "none" : "auto",
        marginLeft: "auto", // Align to the right
      }}
      spacing={2}
      direction="row"
    >
      <Button
        value="I Agree"
        name={props.name}
        color="neutral"
        onTransitionEnd={(e) => props.onLoad(e)}
        onClick={(e) => onClick(e)}
        className={
          ["I Agree", "i Agree"].includes(value)
            ? "activeYesButton"
            : "inActiveNoButton"
        }
        data-testId={props.name + "True"}
      >
        I Agree
      </Button>
      <Button
        value="I Decline"
        name={props.name}
        color="neutral"
        onTransitionEnd={(e) => props.onLoad(e)}
        onClick={(e) => onClick(e)}
        className={
          ["I Decline", "i Decline"].includes(value)
            ? "activeYesButton"
            : "inActiveNoButton"
        }
        data-testId={props.name + "False"}
      >
        I Decline
      </Button>
    </Stack>
    </ThemeProvider>
  );
}

export default ConsentButtons;
