import React, { useEffect, useState, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./home.css";
import { connect } from "react-redux";
import {
  appendQueryParamToURL,
  getAccessToken,
  getTokenExpiryTime,
  getUserEmail,
  mapDispatchToProps,
  mapStateToProps,
} from "../../../../helpers/index";
import _ from "lodash";
import Loader from "../../../../components/Loader";
import HeaderComponent from "../../../../components/HeaderComponent";

import {
  Card,
  CardContent,
  CardHeader,
  Typography,
  Box,
  Button,
  Chip,
  Divider
} from "@mui/material";

import axios from "axios";
import { API_URL } from "../../../../redux/constants";
import EConsent from "./eConsent.js";
import ConsumerConsent from "./ConsumerConsent.js";
import { HighlightOff } from "@mui/icons-material";
import {SOURCE} from "../../../../constants";

function Home(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const [data, setData] = useState();
  const [expandedCardId, setExpandedCardId] = useState(null);
  const [viewButtonClicked, setViewButtonClicked] = useState(false);
  const [eConsentConsumerCreditConsent, seteConsentConsumerCreditConsent] =
    useState({
      next: false,
      eConsent: "",
      ConsumerCreditConsent: "",
      showModels: false,
      input: "",
      load: false,
      loanLockStatus: false
    });
  const [titleanimation, setTitleAnimation] = useState(0.3)
  const [changeanimation, setchangeAnimation] = useState(true)

  useEffect(() => {
    const initialize = () => {
      localStorage.removeItem("bidentifier");
      props.showLoader(true);
      const queryParams = new URLSearchParams(location.search);
      const id = queryParams.get('id');
      if (id) {
        syncLoan(id);
      }else {
        handleLoans();
      }
    };
    const syncLoan =  (id) => {
      const decodedId = decodeURIComponent(id);
      const cleanedId = decodedId.replace(/[{}]/g, '');
      props.syncEncompassLoanToLocalDB(cleanedId) .then((response) => {
        handleLoans();
      })
      .catch((error) => {
        console.log(error);
      });
    };
    const handleLoans = () => {
      props.getLoans();
      props.showLoader(false);
    };
    initialize();
  }, [location.search]);

  useEffect(() => {
    let loans = _.get(props?.getLoansDataRes, "data");
    if (loans) {
      setData(loans);
    }
  }, [props.getLoansDataRes]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (titleanimation === 0.3) {
        setTitleAnimation(-9.3)
      } else if (titleanimation === -9.3) {
        setTitleAnimation(-19)
      } else if (titleanimation === -19) {
        setTitleAnimation(-28.2)
        setTimeout(() => {
          setchangeAnimation(false)
          setTitleAnimation(0.3)
          setTimeout(() => {
            setchangeAnimation(true)
          }, 400)
        }, 400)
      }
    }, 800)

    return () => {
      clearInterval(intervalId)
    }

  }, [titleanimation])

  const navigateTo = (input) => {
    if (expandedCardId === null || viewButtonClicked) {
      const urlWithParams = appendQueryParamToURL(
        input.applicationStatus === "InProgress"
          ? process.env.REACT_APP_LOAN_APPLN_URL
          : process.env.REACT_APP_DASHBOARD_URL
      );
      handleNavigate(input, urlWithParams);
    }
  };

  const handleViewClick = (uniqueId, event) => {
    event.stopPropagation();
    setExpandedCardId(expandedCardId === uniqueId ? null : uniqueId);
    if (event.target.tagName === "BUTTON") {
      setViewButtonClicked(true); // Set viewButtonClicked to true only if the "View" button is clicked
    } else {
      setViewButtonClicked(false); // Reset viewButtonClicked if click occurs anywhere else within the card
    }
  };

  const renderCoApplicants = (coApplicants, userEmail) => {
    const coApplicantRows = [];
    coApplicantRows.push(
      <div className="coApplicantRow" key="coApplicantRow">
        {coApplicants?.map(
          (coApplicant, index) =>
            userEmail === coApplicant.userEmail && (
              <div className="coApplicantCard" key={index}>
                <CardContent>
                  <Typography
                    variant="subtitle1"
                    style={{ fontWeight: "bold" }}
                  >
                    Co-Applicant 1 ( You )
                  </Typography>
                  <Typography variant="subtitle1">
                    Borrower: {coApplicant.borrowerFullName}
                  </Typography>
                  <Divider />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      marginTop: "2%",
                    }}
                  >
                    <Typography
                      sx={{ marginRight: "3.5%" }}
                      variant="subtitle2"
                    >
                      Co Borrower:
                    </Typography>
                    <Typography variant="subtitle2">
                      {coApplicant.coBorrowerFullName}
                    </Typography>
                  </div>
                  <div
                    style={{ display: "flex", justifyContent: "flex-start" }}
                  >
                    <Typography
                      sx={{ marginRight: "3.5%" }}
                      variant="subtitle2"
                    >
                      Email:
                    </Typography>
                    <Typography variant="subtitle2">
                      {coApplicant.userEmail}
                    </Typography>
                  </div>
                  <div
                    style={{ display: "flex", justifyContent: "flex-start" }}
                  >
                    <Typography
                      sx={{ marginRight: "3.5%" }}
                      variant="subtitle2"
                    >
                      Phone:
                    </Typography>
                    <Typography variant="subtitle2">
                      {coApplicant.userPhone}
                    </Typography>
                  </div>
                  <div
                    style={{ display: "flex", justifyContent: "flex-start" }}
                  >
                    <Typography
                      sx={{ marginRight: "3.5%" }}
                      variant="subtitle2"
                    >
                      Status:
                    </Typography>
                    <Typography variant="subtitle2">
                      {" "}
                      {coApplicant.status}
                    </Typography>
                  </div>
                </CardContent>
              </div>
            )
        )}
        {coApplicants?.map(
          (coApplicant, index) =>
            userEmail !== coApplicant.userEmail && (
              <div className="coApplicantCard" key={index}>
                <CardContent>
                  <Typography
                    variant="subtitle1"
                    style={{ fontWeight: "bold" }}
                  >
                    Co-Applicant{" "}
                    {coApplicants.filter((each) => userEmail === each.userEmail)
                      .length
                      ? index + 2
                      : index + 1}
                  </Typography>
                  <Typography variant="subtitle1">
                    Borrower: {coApplicant.borrowerFullName}
                  </Typography>
                  <Divider />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      marginTop: "2%",
                    }}
                  >
                    <Typography
                      sx={{ marginRight: "3.5%" }}
                      variant="subtitle2"
                    >
                      Co Borrower:
                    </Typography>
                    <Typography variant="subtitle2">
                      {coApplicant.coBorrowerFullName}
                    </Typography>
                  </div>
                  <div
                    style={{ display: "flex", justifyContent: "flex-start" }}
                  >
                    <Typography
                      sx={{ marginRight: "3.5%" }}
                      variant="subtitle2"
                    >
                      Email:
                    </Typography>
                    <Typography variant="subtitle2">
                      {coApplicant.userEmail}
                    </Typography>
                  </div>
                  <div
                    style={{ display: "flex", justifyContent: "flex-start" }}
                  >
                    <Typography
                      sx={{ marginRight: "3.5%" }}
                      variant="subtitle2"
                    >
                      Phone:
                    </Typography>
                    <Typography variant="subtitle2">
                      {coApplicant.userPhone}
                    </Typography>
                  </div>
                  <div
                    style={{ display: "flex", justifyContent: "flex-start" }}
                  >
                    <Typography
                      sx={{ marginRight: "3.5%" }}
                      variant="subtitle2"
                    >
                      Status:
                    </Typography>
                    <Typography variant="subtitle2">
                      {" "}
                      {coApplicant.status}
                    </Typography>
                  </div>
                </CardContent>
              </div>
            )
        )}
      </div>
    );

    return (
      <div className="coApplicantsContainer">
        <div className="coapplicant">
          <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
            Co-Applicant Details
          </Typography>
        </div>
        {coApplicantRows}
      </div>
    );
  };

  const renderHeaderAction = (input) => (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Chip
        label={
          input.applicationStatus === "InProgress"
            ? "InProgress"
            : input.applicationStatus === "Consent Not Provided"
              ? "Consent Not Provided"
              : "Submitted"
        }
        className={
          input.applicationStatus === "InProgress"
            ? "inProgressStatus"
            : input.applicationStatus === "Consent Not Provided"
              ? "notprovided"
              : "status"
        }
      />
    </Box>
  );

  const handleNavigate = (input, url) => {
    const appId = _.get(input, "uniqueId", undefined);
    const loanGuid = _.get(input, "loanGuid", undefined);
    let bIdent = _.get(input, "bidentifier", 0);
    localStorage.setItem("bidentifier", bIdent);
    if (appId !== undefined) {
      localStorage.setItem("userName", input.firstName + " " + input.lastName);
      localStorage.setItem("appId", input.uniqueId);
    }
    if (loanGuid !== undefined) {
      localStorage.setItem("loanGuid", input.loanGuid);
    }
    navigate(url);
  };

  const fetchIpAddress = async () => {
    try {
      const response = await fetch("https://api.ipify.org?format=json");
      const data = await response.json();
      return data.ip;
    } catch (error) {
      console.error("Error fetching IP address:", error);
    }
  };

  const EConsentApiCall = async (accpetedornot) => {
    seteConsentConsumerCreditConsent((prevConsents) => ({
      ...prevConsents,
      load: true,
    }));
    try {
      let ipAddress = await fetchIpAddress();
      let borId = eConsentConsumerCreditConsent.input.bidentifier;
      let fullName = getCustomerName(eConsentConsumerCreditConsent.input);
      const body = {
        status: accpetedornot === "I Agree" ? "Accepted" : "Declined",
        borrowerId: borId,
        fullName: fullName,
        email: eConsentConsumerCreditConsent.input.userEmail,
        dateAccepted: new Date().toISOString(),
        ipAddress: ipAddress,
        source: SOURCE,
      };
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getAccessToken()}`,
        "x-useremail": getUserEmail(),
        "x-expires-in": getTokenExpiryTime(),
      };
      const res = await axios.post(
        `${API_URL}/customer-service/api/v1/cx/loans/${eConsentConsumerCreditConsent.input.loanGuid}/eConsent`,
        body,
        {
          headers: headers,
        }
      );

      if (res.status === 200) {
        seteConsentConsumerCreditConsent((prevConsents) => ({
          ...prevConsents,
          next: true,
          load: false,
        }));
      }
    } catch (error) {
      seteConsentConsumerCreditConsent((prevConsents) => ({
        ...prevConsents,
        load: false,
      }));
      console.log(error);
    }
  };

  const ConsumerCreidtApiCall = async (acceptedornot) => {
    seteConsentConsumerCreditConsent((prevConsents) => ({
      ...prevConsents,
      load: true,
    }));
    try {
      let ipAddress = await fetchIpAddress();
      let borId = eConsentConsumerCreditConsent.input.bidentifier;
      let fullName = getCustomerName(eConsentConsumerCreditConsent.input);
      let firstSpaceIndex = fullName.indexOf(' ');
      const body = {
        status: acceptedornot === "I Agree" ? "Accepted" : "Declined",
        borrowerId: borId,
        firstName: fullName.substring(0, firstSpaceIndex),
        lastName: fullName.substring(firstSpaceIndex + 1),
        email: eConsentConsumerCreditConsent.input.userEmail,
        dateAccepted: new Date().toISOString(),
        ipAddress: ipAddress,
        source: SOURCE,
        inquiryType: "Soft",
      };
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getAccessToken()}`,
        "x-useremail": getUserEmail(),
        "x-expires-in": getTokenExpiryTime(),
      };

      const res = await axios.post(
        `${API_URL}/customer-service/api/v1/cx/loans/${eConsentConsumerCreditConsent.input.loanGuid}/creditConsent`,
        body,
        {
          headers: headers,
        }
      );

      if (res.status === 200) {
        navigateTo(eConsentConsumerCreditConsent.input);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updateEconsent = (obtainedConsent) => {
    seteConsentConsumerCreditConsent((prevConsents) => ({
      ...prevConsents,
      eConsent: obtainedConsent,
    }));
    EConsentApiCall(obtainedConsent);
  };

  const updateConsumerCreditConset = (obtainedConsent) => {
    seteConsentConsumerCreditConsent((prevConsents) => ({
      ...prevConsents,
      ConsumerCreditConsent: obtainedConsent,
    }));

    ConsumerCreidtApiCall(obtainedConsent);
  };

  const getCustomerName = (input) => {
    if (input?.bidentifier?.endsWith('0')) {
      if (input.bidentifier.length === 2) {
        const coapplicant = input.coApplicants?.find(applicant => applicant.userEmail === input.userEmail);
        return coapplicant?.borrowerFullName;
      }
      return input?.borrowerFullName;
    }
    if (input?.bidentifier?.endsWith('1')) {
      if (input.bidentifier.length === 2) {
        const coapplicant = input.coApplicants?.find(applicant => applicant.userEmail !== input.userEmail);
        return coapplicant?.coBorrowerFullName;
      }
      return input?.coBorrowerFullName;
    }
  };

  const EConsentConsumerCreditConsent = useMemo(
    () =>
      (props) => {
        return (
          <Box className="eConsent-modelbox-main-con">
            <Box className="eConsentModalShadow"></Box>
            {eConsentConsumerCreditConsent.next === false ? (
              eConsentConsumerCreditConsent.load === true ? (
                <Loader />
              ) : eConsentConsumerCreditConsent.loanLockStatus ? (
                <Box className="eConsent-modelbox">
                  <span>{eConsentConsumerCreditConsent.input}</span>
                  <footer>
                    <button
                      type="button"
                      onClick={() => seteConsentConsumerCreditConsent(
                        (prevConsents) => ({
                          ...prevConsents,
                          showModels: false,
                          loanLockStatus: false
                        })
                      )}
                      className="selected"
                      style={{marginRight: 0}}
                    >
                      Close
                    </button>
                  </footer>
                </Box>
              ) : (
                <Box className="eConsent-modelbox">
                  <div className="econsent-modal-closeIcon">
                  <HighlightOff onClick={() => seteConsentConsumerCreditConsent(
                    (prevConsents) => ({
                      ...prevConsents,
                      showModels: false,
                      loanLockStatus: false
                    })
                  )
                  }/>
                  </div>
                  <EConsent
                    coBorrowerFullName={
                      getCustomerName(props?.eConsentConsumerCreditConsent?.input)
                    }
                  />
                  <footer>
                    <button
                      type="button"
                      onClick={() => {
                        updateEconsent("I Agree");
                      }}
                      className={
                        eConsentConsumerCreditConsent.eConsent === "I Agree"
                          ? "selected"
                          : "unselected"
                      }
                    >
                      I Agree
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        updateEconsent("I Decline");
                      }}
                      className={
                        eConsentConsumerCreditConsent.eConsent === "I Decline"
                          ? "selected"
                          : "unselected"
                      }
                    >
                      I Decline
                    </button>
                  </footer>
                </Box>
              )
            ) : eConsentConsumerCreditConsent.load === true ? (
              <Loader />
            ) : (
              <Box className="eConsent-modelbox">
              <div className="econsent-modal-closeIcon">
              <HighlightOff onClick={() => seteConsentConsumerCreditConsent(
                (prevConsents) => ({
                  ...prevConsents,
                  showModels: false,
                  loanLockStatus: false
                })
              )
              }/>
              </div>
                <ConsumerConsent
                  coBorrowerFullName={
                    getCustomerName(props?.eConsentConsumerCreditConsent?.input)
                  }
                />
                <footer>
                  <button
                    type="button"
                    onClick={() => {
                      updateConsumerCreditConset("I Agree");
                    }}
                    className={
                      eConsentConsumerCreditConsent.ConsumerCreditConsent ===
                        "I Agree"
                        ? "selected"
                        : "unselected"
                    }
                  >
                    I Agree
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      updateConsumerCreditConset("I Decline");
                    }}
                    className={
                      eConsentConsumerCreditConsent.ConsumerCreditConsent ===
                        "I Decline"
                        ? "selected"
                        : "unselected"
                    }
                  >
                    I Decline
                  </button>
                </footer>
              </Box>
            )}
          </Box>
        );
      },
    [eConsentConsumerCreditConsent]
  );

  const onCardClickFunc = (input) => {
      if (
        input.applicationStatus === "Consent Not Provided"
      ) {
        props.showLoader(true);
        props.getLoanLockStatus(input.loanGuid)
          .then(( ) => {
            props.showLoader(false);
              seteConsentConsumerCreditConsent((prevConsents) => ({
                ...prevConsents,
                load: false,
                showModels: true,
                loanLockStatus: false,
                input: input
              }));
          })
          .catch((error) => {
            props.showLoader(false);
            if(error.status === 403) {
            seteConsentConsumerCreditConsent((prevConsents) => ({
              ...prevConsents,
              showModels: true,
              loanLockStatus: true,
              input: error.data.error
            }));
            console.log(error);
          }
          });
      } else {
        navigateTo(input);
      }
    }

  return (
    <div>
      <div className="mainCardDiv">
        {props.toggleLoader && <Loader />}
        {eConsentConsumerCreditConsent.showModels && (
          <EConsentConsumerCreditConsent eConsentConsumerCreditConsent={eConsentConsumerCreditConsent} />
        )}
        <HeaderComponent />
        <div className="headerDiv">
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              gap: "14px",
            }}
          >
            <Typography className="title">
              Your Mortgage
              {changeanimation ? <span style={changeanimation ? { marginTop: `${titleanimation}%` } : { marginTop: `${titleanimation}%`, transition: `all .0s linear` }} className="titleanimation">
                <span style={titleanimation === 0.3 ? { opacity: "100%" } : { opacity: "0%" }}>Organized...</span>
                <span style={titleanimation === -9.3 ? { opacity: "100%" } : { opacity: "0%" }}>Simplified...</span>
                <span style={titleanimation === -19 ? { opacity: "100%" } : { opacity: "0%" }}>Amplified...</span>
                <span style={titleanimation === -28.2 ? { opacity: "100%" } : { opacity: "0%" }}>Organized...</span>
              </span> :
                <span style={{ marginTop: `0.3%` }} className="titleanimation-2">
                  <span>Organized...</span>
                </span>
              }

            </Typography>
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: "500",
                lineHeight: "19.36px",
                color: "#9E9E9E",
              }}
            >
              Unlock your path to homeownership with our tailored mortgage
              solutions. We're here to guide you through the journey of securing
              your dream home.
            </Typography>
          </Box>
          <Button
            className="createButton"
            onClick={() => {
              window.localStorage.setItem("stepperLength", 2);
              navigateTo({ applicationStatus: "InProgress" });
            }}
          >
            Create New Application
          </Button>
        </div>
        <div className="applicationsDataMainDiv">
          <Box className="typo" style={{ marginBottom: "20px" }}>
            <Typography variant="h5">Your Mortgage:</Typography>
          </Box>
          <div className="applicationsDataDiv">
            {typeof data === "object" && data?.length > 0 ? (
              data?.map((input, index) => (
                <Card
                  key={index}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: expandedCardId === input.uniqueId ? "100%" : "auto",
                    height:
                      expandedCardId === input.uniqueId ? "auto" : "250px", // Fixed height
                    borderRadius: "10px",
                    marginBottom: "10px",
                    cursor: "pointer",
                  }}
                  onClick={() => onCardClickFunc(input) }
                >
                  <CardHeader
                    title="Loan No."
                    subheader={input.loanNumber}
                    titleTypographyProps={{
                      color: "textSecondary",
                      variant: "subtitle1",
                    }}
                    subheaderTypographyProps={{
                      color: "textPrimary",
                      variant: "subtitle2",
                    }}
                    action={renderHeaderAction(input)}
                  />
                  <Divider />
                  <div style={{ display: "flex" }}>
                    <div style={{ width: 400 }}>
                      <CardContent style={{ flex: 1 }}>
                        {input.bidentifier === "0" ? (
                          <Typography color="textSecondary" variant="subtitle1">
                            Borrower Name: <b>{input.borrowerFullName}</b> ( You
                            )
                          </Typography>
                        ) : (
                          <Typography color="textSecondary" variant="subtitle1">
                            Borrower Name: <b>{input.borrowerFullName}</b>
                          </Typography>
                        )}
                        {input.coBorrowerFullName !== "" && (

                          input?.bidentifier?.endsWith("1") ?
                            <Typography color="textSecondary" variant="subtitle1">
                              Co-Borrower Name: <b>{input.coBorrowerFullName}</b> ( You )
                            </Typography> : <Typography color="textSecondary" variant="subtitle1">
                              Co-Borrower Name: <b>{input.coBorrowerFullName}</b>
                            </Typography>
                        )}
                        {input?.coApplicants?.length > 0 &&
                          input?.coApplicants.map(
                            (each) =>
                              each.userEmail === input.userEmail && (
                                <Typography
                                  color="textSecondary"
                                  variant="subtitle1"
                                >
                                  Co-Applicants : <b>{each.borrowerFullName}</b>{" "}
                                  ( You )
                                </Typography>
                              )
                          )}
                        {input?.coApplicants?.length > 0 && (
                          <Typography
                            color="textSecondary"
                            variant="subtitle1"
                            style={{
                              backgroundColor: "#F9F9F9",
                              maxWidth: "45%",
                              marginTop: "2%",
                            }}
                          >
                            <Button
                              onClick={(event) =>
                                handleViewClick(input.uniqueId, event)
                              }
                              style={{
                                textTransform: "none",
                                color: "#125437",
                              }}
                            >
                              {`View Co-Applicants(${input?.coApplicants?.length})`}
                            </Button>
                          </Typography>
                        )}
                        {expandedCardId === input.uniqueId && (
                          <Box
                            sx={{
                              maxWidth: "50%",
                              display: "flex",
                              justifyContent: "space-between",
                              marginTop: "5%",
                            }}
                          >
                            <Button
                              onClick={(event) => handleViewClick(null, event)}
                              variant="outlined"
                              style={{
                                textTransform: "none",
                                backgroundColor: "#e9f6f1",
                                color: "#125437",
                                border: "1px solid #125437",
                              }}
                            >
                              Close
                            </Button>
                            <Button
                              onClick={() => {
                                if (
                                  input.applicationStatus ===
                                  "Consent Not Provided"
                                ) {
                                  seteConsentConsumerCreditConsent(
                                    (prevConsents) => ({
                                      ...prevConsents,
                                      showModels: true,
                                      input: input,
                                    })
                                  );
                                } else {
                                  navigateTo(input);
                                }
                              }}
                              variant="outlined"
                              style={{
                                textTransform: "none",
                                backgroundColor: "#125437",
                                color: "#ffffff",
                                border: "1px solid #125437",
                              }}
                            >
                              Open
                            </Button>
                          </Box>
                        )}
                      </CardContent>
                    </div>
                    <div style={{ flexGrow: 1 }}>
                      {expandedCardId === input.uniqueId &&
                        input.coApplicants &&
                        input.coApplicants.length > 0 && (
                          <>
                            <CardContent
                              style={{
                                flex: 1,
                                minWidth: 200,
                                display: "flex",
                                flexDirection: "column",
                                padding: 0,
                              }}
                              onClick={(e) => e.stopPropagation()}
                            >
                              <Box sx={{ flexGrow: 1 }} />
                              {renderCoApplicants(
                                input.coApplicants,
                                input.userEmail
                              )}
                            </CardContent>
                          </>
                        )}
                    </div>
                  </div>
                </Card>
              ))
            ) : (
              <Box>
                <Typography
                  variant="body1"
                  align="center"
                  color="textSecondary"
                >
                  You don't have any mortgage history. Please click "Create New
                  Application" to start one.
                </Typography>
              </Box>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);
export { Home };
