import * as React from "react";
import { IMaskInput } from "react-imask";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import "./components.css";
import { FormHelperText, Tooltip } from "@mui/material";

const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, mask, ...other } = props;

  return (
    <IMaskInput
      {...other}
      mask={mask}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
    />
  );
});

export default function MaskComponent(props) {
  const {
    value,
    onChange,
    mask,
    name,
    label,
    required,
    error,
    helperText,
    pattern,
  } = props;

  return (
    <div className="flexAlignColumnClassName" onClick={(e) => { e.preventDefault() }}>
      <InputLabel
        htmlFor="formatted-text-mask-input"
        className="labelClassName alignRowClassName flex-start"
      >
        {label}
        <Tooltip data-testid="tooltip" title={mask && `(Ex:- ${mask})`} arrow>
          <span className="tooltipInfoIcon">i</span>
        </Tooltip>
      </InputLabel>
      <FormControl variant="standard">
        <Input
          data-testid="input"
          style={{ padding: 10 }}
          value={value.toString()}
          onAnimationStart={(e) => props.onLoad(e)}
          onChange={(e) => onChange(e)}
          name={name}
          disableUnderline={true}
          inputProps={{ mask: mask, pattern: pattern }}
          className="textFieldInputComponent"
          classes={{
            root: "outlinedInputRoot",
            focused: "outlinedInputFocused",
            notchedOutline: "outlinedInput",
          }}
          inputComponent={TextMaskCustom}
          required={required}
          error={error}
        />
        <FormHelperText>{helperText}</FormHelperText>
      </FormControl>
    </div>
  );
}
