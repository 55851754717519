const ConsumerConsent = (props) => {
  return (
    <div>
      <h4>Authorization for Credit Check and Document Verification</h4>
        <p>
            I, <strong>{props?.coBorrowerFullName}</strong>, hereby authorize Bond Street Mortgage to access information from my personal credit profile as maintained by the three major credit bureaus, namely Equifax, Experian, and TransUnion, in accordance with the provisions of the Fair Credit Reporting Act (FCRA).
        </p>
        <p>
            This authorization is provided explicitly for the purpose of prequalifying me for credit options offered by Bond Street Mortgage. I understand that the information obtained from my credit profile will be used solely for this purpose.
        </p>
        <p>
            I acknowledge and authorize Bond Street Mortgage to perform a hard inquiry on my credit report as part of the application process.
        </p>
        <p>
            Furthermore, I grant Bond Street Mortgage permission to verify any documents that may be necessary in connection with my mortgage application.
        </p>
        <p>
            I acknowledge that the credit information obtained for my pre-qualification may differ from the information accessed by a credit grantor at a later date for making an actual credit decision.
        </p>
        <p>
            By signing this form, I confirm that I have read and understood the terms outlined herein and voluntarily authorize Bond Street Mortgage to proceed with the actions described above.
        </p>
      {/* <h4>Consumer Consent</h4>
      <br />
      <h5>{props?.coBorrowerFullName}</h5>
      <p>
        By signing this form, I provide explicit instructions
        to Bond Street Mortgage, in accordance with the Fair Credit Reporting
        Act, to access information from my personal credit profile or other
        related details from the three major credit bureaus. I authorize Bond
        Street Mortgage to use this information solely for the purpose of
        prequalifying me for credit options. Furthermore, I grant Bond Street
        Mortgage permission to verify any documents necessary in connection with
        my mortgage application. I acknowledge that the credit information
        obtained for my pre-qualification might differ from the information
        accessed by a credit grantor at a later date for making an actual credit
      </p> */}
    </div>
  );
};

export default ConsumerConsent;
