import { mapDispatchToProps, mapStateToProps } from "../../../helpers";
import { connect } from "react-redux";
import JsonSchemaForms from "../../../json_schema_forms";
import loanDetails from "../loan_details/loanDetails.json";
import listOfLiabilities from "../liabilities/liabilities.json";
import personalInformation from "../personal_information/personal_information.json";
import expenses from "../expenses/expenses.json";
import assets from "../listOfAssets/list_of_Assets.json";
import employmentAndIncom from "../employment_and_income/employment_and_income.json";
import declarations from "../declarations/declarations.json";
import React, { useState } from "react";
import {
  Tabs as MuiTabs,
  Tab,
  Card,
  CardContent,
  Typography,
  Grid,
  Box,
  Tabs,
  Divider,
  Button,
  Tooltip,
} from "@mui/material";
import _ from "lodash";
import PropTypes from "prop-types";
import "./tabs_component.scss";
import CoApplicantSummary from "../coapplicant/coapplicant_summmary";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3, padding: 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const Summary = (props) => {
  let [jsonArray, setJsonArray] = React.useState([
    { data: personalInformation, pageKey: "personalInformation" },
    { data: loanDetails, pageKey: "loanDetails" },
    { data: employmentAndIncom, pageKey: "EmploymentAndIncome" },
    { data: assets, pageKey: "listOfAssets" },
    { data: listOfLiabilities, pageKey: "listOfLiabilities" },
    { data: declarations, pageKey: "declarations" },
  ]);
  const [value, setValue] = React.useState(1);

  React.useEffect(() => {
    let tempJsonArray = jsonArray;
    if (!(_.isEmpty(props.componentSchema) || _.isEmpty(jsonArray))) {
      for (let j = 0; j < tempJsonArray.length; j++) {
        tempJsonArray[j].data = _.get(
          props.componentSchema,
          tempJsonArray[j].pageKey,
          tempJsonArray[j].data
        );
      }
      setJsonArray(tempJsonArray);
    }
  }, [props.componentSchema]);

  const changeHandler = (index) => {
    props.changePageIndex(index);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const onLoad = (updatedSchema, pageKey) => {
    props.copySchema(updatedSchema, pageKey);
    let tempJsonArray = jsonArray;
    for (let j = 0; j < tempJsonArray.length; j++) {
      if (tempJsonArray[j].pageKey === pageKey) {
        tempJsonArray[j].data = updatedSchema;
        break;
      }
    }
    setJsonArray(tempJsonArray);
  }

  const renderJsonSchemaForms = () => {
    return jsonArray.map((input, index) => (
      <JsonSchemaForms
        key={index}
        jsonData={input.data}
        pageKey={input.pageKey}
        state={props.componentState}
        summary={true}
        edit={true}
        clickEditHandler={() => {
          changeHandler(index + 2);
        }}
        onLoadFunc={(updatedSchema) => {
          onLoad(updatedSchema, input.pageKey);
        }}
      />
    ));
  };

  return (
    <div>
      {_.isEmpty(props.componentState) || !props.display ? (
        <div></div>
      ) : (
        <div>
          {props.componentState?.CoApplicant?.CoApplicant?.length > 0 ? (
            <div
              style={{
                backgroundColor: "white",
                borderRadius: 4,
              }}
            >
              <Box sx={{ width: "100%" }}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    textColor="primary"
                    classes={{
                      scrollButtons: "tabsComponentScrollButtonsClassName",
                      indicator: "tabsComponentTabIndicatorClassName",
                    }}
                    fullWidth={true}
                  >
                    <Tab
                      className="tabComponentTabClassName"
                      label="Main Applicant"
                      classes={{
                        selected: "tabComponentSelectedTabClassName",
                      }}
                      sx={{
                        padding: 0,
                      }}
                      {...a11yProps(0)}
                    />
                    <Tab
                      className="tabComponentTabClassName"
                      label="Co Applicants Details"
                      classes={{
                        selected: "tabComponentSelectedTabClassName",
                      }}
                      sx={{
                        padding: 0,
                      }}
                      {...a11yProps(1)}
                    />
                  </Tabs>
                </Box>
              </Box>
              <div
                role="tabpanel"
                hidden={value !== 0}
                id={`tabpanel-0`}
                aria-labelledby={`tab-0`}
              >
                {value === 0 && renderJsonSchemaForms()}
              </div>
              <div
                role="tabpanel"
                hidden={value !== 1}
                id={`tabpanel-1`}
                aria-labelledby={`tab-1`}
              >
                {value === 1 && (
                  <CoApplicantSummary coapplicants={props?.componentState?.CoApplicant?.CoApplicant} edit={true} onEditCoApplicantsFunc={()=>props.onEditCoApplicantsFunc()} />
                )}
              </div>
            </div>
          ) : (
            <div>{renderJsonSchemaForms()}</div>
          )}
        </div>
      )}
    </div>
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(Summary);
