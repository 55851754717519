import Navbar from "./navbar/Navbar";
import Footer from "./footer/Footer";
import UserCodeSubmission from "./userCodeSubmission/UserCodeSubmission";

import "./index.css";

const RefinanceHome = () => {

    return (
        <div className="page-container">
            <div className="navigation-bar">
                <Navbar />
            </div>
            <div className="App">
                <UserCodeSubmission />
            </div>
            <div className='app-footer'>
                <Footer />
            </div>
        </div>
    );

};

export default RefinanceHome;