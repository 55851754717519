import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Slide,
  Stack,
  Typography,
} from "@mui/material";
import WarningIcon from "@mui/icons-material/Warning";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const SessionTimeoutDialog = ({ open, onLogout, onContinue }) => {
  return (
    <Dialog open={open} TransitionComponent={Transition}>
      <DialogTitle>Session Timeout</DialogTitle>
      <IconButton disableRipple>
        <WarningIcon sx={{ fontSize: "8rem", color: "#DCC207" }} />
      </IconButton>
      <DialogContent>
        <DialogContentText>
          <Typography variant="h6">
            The current session is about to expire soon Would you like to
            continue the session?
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Stack spacing={2} direction="row">
          <Button
            variant="contained"
            className="sessionLogout"
            onClick={onLogout}
          >
            Logout
          </Button>
          <Button
            variant="contained"
            className="activeYesButton"
            onClick={onContinue}
          >
            Continue Session
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};
export default SessionTimeoutDialog;
