/*eslint-disable*/
import * as reduxModule from "redux";
import { applyMiddleware, compose, createStore } from "redux";
import {userCreateReducer} from "./reducers/combineReducers";
import thunk from "redux-thunk";

/*
Fix for Firefox redux dev tools extension
 */
reduxModule.__DO_NOT_USE__ActionTypes.REPLACE = "@@redux/INIT";

const composeEnhancers =
  process.env.NODE_ENV !== "production" &&
  typeof window === "object" &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
      })
    : compose;

const enhancer = composeEnhancers(applyMiddleware(thunk));

const store = createStore(userCreateReducer(), enhancer);
store.asyncReducers = {};

export const injectReducer = (key, reducer) => {
  store.asyncReducers[key] = reducer;
  store.replaceReducer(userCreateReducer(store.asyncReducers));
  return store;
};
const dispatch = store.dispatch;
export { dispatch };
export default store;
