import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import "./components.css";
import { TextField, Tooltip } from "@mui/material";

export default function TextfieldComponent(props) {
  const {
    label,
    value,
    onChange,
    required,
    name,
    error,
    helperText,
    pattern,
    type,
    multiline,
    rows,
    prefix,
    tooltip,
    testId,
    disabled,
    autoFocus
  } = props;
  const handleChange = (e, v) => {
    onChange(e, v);
  };
  const returnValue = (data) => {
    if (prefix && isNaN(data)) {
      return 0.0;
    } else {
      return data;
    }
  };

  let firstFields = ["FirstName", "AddressLine1", "WhoReferredYou?"];


  return (
    <div className={multiline ? "textArea" : "flexAlignColumnClassName"} onClick={(e) => { e.preventDefault() }}>
      <InputLabel
        htmlFor="outlined-adornment-amount"
        required={required}
        className="labelClassName alignRowClassName flex-start"
      >
        {label}
        {tooltip && (
          <Tooltip title={tooltip} arrow>
            <span className="tooltipInfoIcon">i</span>
          </Tooltip>
        )}
      </InputLabel>
      <TextField
        data-testid={testId}
        name={name}
        type={type}
        autoComplete="off"
        id="outlined-adornment-amount"
        className="textFieldInputComponent"
        style={{ height: multiline ? "100%" : "auto" }}
        value={returnValue(value)}
        onAnimationStart={(e) => props.onLoad(e)}
        onChange={(e, v) => handleChange(e, v)}
        required={required}
        multiline={multiline}
        rows={rows}
        inputProps={{ pattern: pattern }}
        disabled={disabled}
        InputProps={{
          classes: {
            root: error
              ? "outlinedErrorInputRoot"
              : multiline
                ? "multilineOutlinedInputRoot"
                : "outlinedInputRoot",
            focused: error
              ? "outlinedErrorInputFocused"
              : "outlinedInputFocused",
            notchedOutline: "outlinedInput",
          },
          startAdornment: prefix && (
            <div style={{ paddingRight: 5 }}>{prefix}</div>
          ),
        }}
        FormHelperTextProps={{
          classes: {
            root: "helperTextClassName",
          },
        }}
        helperText={helperText}
        error={error}
        autoFocus={firstFields.includes(name)}
      />
    </div>
  );
}
