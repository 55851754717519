import React, { useEffect, useState } from "react";
import JsonSchemaForms from "../../../json_schema_forms";
import { connect } from "react-redux";
import EmploymentAndIncomeJson from "./employment_and_income.json";
import _, { set } from "lodash";
import {
  mapDispatchToProps,
  mapStateToProps,
  setValueByRecursion,
} from "../../../helpers";

function EmploymentAndIncome(props) {
  const [state, setState] = useState(props.componentState);
  const [jsonSchema, setJsonSchema] = React.useState(_.cloneDeep(EmploymentAndIncomeJson));
  useEffect(() => {
    return () => {
      setJsonSchema(_.cloneDeep(EmploymentAndIncomeJson))
    }
  }, [])

  useEffect(() => {
    if (!_.isEmpty(props.componentState)) {
      setState(props.componentState);
    }
  }, [props.componentState]);

  useEffect(() => {
    if (props.componentSchema?.EmploymentAndIncome) {
      setJsonSchema(props.componentSchema["EmploymentAndIncome"]);
    }
  }, [props.componentSchema]);

  useEffect(() => {

    if (jsonSchema.properties.EmploymentAndIncome.properties.Borrower.properties.employerInformation.length === 0) {
      jsonSchema.properties.EmploymentAndIncome.properties.Borrower.properties.employerInformation.buttonName = "Add Employment"

      setTimeout(() => {
        setJsonSchema(jsonSchema)
      }, 0)

    } else {
      jsonSchema.properties.EmploymentAndIncome.properties.Borrower.properties.employerInformation.buttonName = "Add Additional Employment"
      setJsonSchema(jsonSchema)
    }
  }, [jsonSchema.properties.EmploymentAndIncome.properties.Borrower.properties.employerInformation.length])

  useEffect(() => {

    if (jsonSchema.properties.EmploymentAndIncome.properties.CoBorrower.properties.employerInformation.length === 0) {
      jsonSchema.properties.EmploymentAndIncome.properties.CoBorrower.properties.employerInformation.buttonName = "Add Employment"


      setJsonSchema(jsonSchema)


    } else if (jsonSchema.properties.EmploymentAndIncome.properties.CoBorrower.properties.employerInformation.length > 0) {
      jsonSchema.properties.EmploymentAndIncome.properties.CoBorrower.properties.employerInformation.buttonName = "Add Additional Employment"
      setJsonSchema(jsonSchema)
    }
  }, [jsonSchema.properties.EmploymentAndIncome.properties.CoBorrower.properties.employerInformation.length])


  const calcTotals = (object) => {
    let baseIncome = parseFloat(object?.basePay);
    let overtimeIncome = parseFloat(object?.militaryPayConditional1?.overtime);
    let bonusesIncome = parseFloat(object?.militaryPayConditional1?.bonuses);
    let commissionsIncome = parseFloat(object?.militaryPayConditional1?.commissions);
    let otherIncome = parseFloat(object?.other);
    let militaryCombatPay = parseFloat(object?.militaryPayConditional1?.militaryCombatPay);
    let militaryFlightPayIncome = parseFloat(object?.militaryPayConditional1?.militaryFlightPay);
    let militaryHazardPayIncome = parseFloat(object?.militaryPayConditional1?.militaryHazardPay);
    let militaryOverseasPayIncome = parseFloat(object?.militaryPayConditional1?.militaryOverseasPay);
    let militaryPropPayIncome = parseFloat(object?.militaryPayConditional1?.militaryPropPay);
    let clothingAllowance = parseFloat(object?.militaryPayConditional1?.clothingAllowance);
    let rationsAllowance = parseFloat(object?.militaryPayConditional1?.rationsAllowance);
    let variableHousingAllowance = parseFloat(object?.militaryPayConditional1?.variableHousingAllowance);
    let quartersAllowance = parseFloat(object?.militaryPayConditional1?.quartersAllowance);
    let monthlyIncomeOrLoss = parseFloat(object?.employmentObj?.monthlyIncomeOrLoss);

    object["totalBorrowerIncome"] = _.sum([
      baseIncome ? baseIncome : 0,
      overtimeIncome ? overtimeIncome : 0,
      bonusesIncome ? bonusesIncome : 0,
      commissionsIncome ? commissionsIncome : 0,
      otherIncome ? otherIncome : 0,
      militaryCombatPay ? militaryCombatPay : 0,
      militaryFlightPayIncome ? militaryFlightPayIncome : 0,
      militaryHazardPayIncome ? militaryHazardPayIncome : 0,
      militaryOverseasPayIncome ? militaryOverseasPayIncome : 0,
      militaryPropPayIncome ? militaryPropPayIncome : 0,
      clothingAllowance ? clothingAllowance : 0,
      rationsAllowance ? rationsAllowance : 0,
      variableHousingAllowance ? variableHousingAllowance : 0,
      quartersAllowance ? quartersAllowance : 0,
      monthlyIncomeOrLoss ? monthlyIncomeOrLoss : 0,
    ]);
    object.conditional2 = {};
    object.conditional2["totalMilitaryEntitlements"] = _.sum([
      militaryCombatPay ? militaryCombatPay : 0,
      militaryFlightPayIncome ? militaryFlightPayIncome : 0,
      militaryHazardPayIncome ? militaryHazardPayIncome : 0,
      militaryOverseasPayIncome ? militaryOverseasPayIncome : 0,
      militaryPropPayIncome ? militaryPropPayIncome : 0,
      clothingAllowance ? clothingAllowance : 0,
      rationsAllowance ? rationsAllowance : 0,
      variableHousingAllowance ? variableHousingAllowance : 0,
      quartersAllowance ? quartersAllowance : 0,
    ])
  };

  const onLoadFunc = (updatedSchema) => {
    props.copySchema(updatedSchema, "EmploymentAndIncome")
  }

  const handleEmploymentArray = (state) => {
    state?.EmploymentAndIncome?.Borrower?.employerInformation?.map(
      (object, index) => {
        calcTotals(object);
      }
    );
    let totalAdditionalIncome =
      state?.EmploymentAndIncome?.additionalIncome?.reduce(
        function (a, b) {
          let total = a + parseFloat(b["amount"]);
          if (!isNaN(total)) return total;
          else return a;
        },
        0
      );
    state?.EmploymentAndIncome?.CoBorrower?.employerInformation?.map(
      (object, index) => {
        calcTotals(object);
      }
    );

    state = setValueByRecursion(
      state,
      ["EmploymentAndIncome", "totalAdditionalIncome"],
      (i) => totalAdditionalIncome,
      []
    );
    return state;
  }
  const changeHandler = (updatedState, updatedSchema) => {
    updatedState = handleEmploymentArray(updatedState)
    props.copyStateToRedux(updatedState);
    props.copySchema(updatedSchema, "EmploymentAndIncome");
    setState(updatedState);
  };

  useEffect(() => {
    if (state?.EmploymentAndIncome?.additionalIncome?.length > 0) {
      const totalAmount = state?.EmploymentAndIncome?.additionalIncome?.reduce((acc, obj) => parseInt(acc) + parseInt(obj.amount), 0);
      state.EmploymentAndIncome.totalAdditionalIncome = totalAmount
    }
  }, [state?.JointCredit?.AreYouApplyingForAJointCreditWithACoBorrower])

  return (
    <JsonSchemaForms
      state={state}
      changeHandler={changeHandler}
      jsonData={jsonSchema}
      pageKey={"EmploymentAndIncome"}
      onLoadFunc={onLoadFunc}
    />
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmploymentAndIncome);
