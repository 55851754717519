import React from 'react';
import "../json_schema_forms/helpers/renderer.css";
import "./components.css";
import { mapDispatchToProps, mapStateToProps } from '../helpers';
import { connect } from 'react-redux';

const ScrollingTextWidget = (props) => {
 
  const { text, title } = props;

  let BorrowerFirstName=props?.componentState
  ?.ApplicantDetails?.BorrowerDetails?.FirstName;
  let BorrowerLastName=props?.componentState
  ?.ApplicantDetails?.BorrowerDetails?.LastName

  const dateFormatOptions = { weekday: 'long', month: 'long', day: 'numeric', year: 'numeric' };
  const currentDate = new Date().toLocaleDateString('en-US', dateFormatOptions);

  return (
    <div className="scrolling-text-widget-container">
      <div className="scrolling-text-widget">
        <span className="titleClassName">{title}</span>
        <span style={{marginBottom: '10px', fontWeight: 'bold'}}>{BorrowerFirstName + " " + BorrowerLastName}</span>
        <div className="scrolling-text-content">
          <span
            className="textClassName"
            style={{ whiteSpace: "pre-wrap" }}
            dangerouslySetInnerHTML={{
              __html: text
                ?.replace(/&nbsp;/g, '\u00A0') // Replace &nbsp; with a non-breaking space
                .replace(/<strong>/g, '<b>') // Replace <strong> with <b> for bold start tag
                .replace(/<\/strong>/g, '</b>') // Replace </strong> with </b> for bold end tag
                .replace('{currentDate}', currentDate)
                .split('\n')
                .join('<br/>')
            }}
          /></div>
      </div>
    </div>
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(ScrollingTextWidget);