import FilePresent from "@mui/icons-material/FilePresent";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import { Button } from "@mui/material";
import LoopIcon from "@mui/icons-material/Loop";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import "./DocumentsManager.css";
import { useEffect, useState } from "react";

export function RenderData(props) {
  const {
    file,
    documentsList,
    showUploadButton,
    showPreviewAndDltButtons,
    fileHandler,
    openFile,
    deleteFile,
  } = props;
  // const titleDecorator = (text) => {
  //   let title = text.charAt(0).toUpperCase() + text.slice(1);
  //   title = title.replace(/([A-Z])/g, " $1");
  //   return title;
  // };

  const [detailsToShow, setDetails] = useState({ Guid: "", bid: "" });

  useEffect(() => {
    let loanGuid = localStorage.getItem("loanGuid");
    let bidentifier = localStorage.getItem("bidentifier");

    if (bidentifier === "0") {
      setDetails({ Guid: loanGuid, bid: "_borrower1" });
    } else if (bidentifier === "1") {
      setDetails({ Guid: loanGuid, bid: "_coborrower1" });
    } else {
      if (bidentifier.endsWith("0")) {
        setDetails({
          Guid: loanGuid,
          bid: `_borrower${parseInt(bidentifier[0]) + 1}`,
        });
      } else if (bidentifier.endsWith("1")) {
        setDetails({
          Guid: loanGuid,
          bid: `_coborrower${parseInt(bidentifier[0]) + 1}`,
        });
      }
    }
  }, []);

  return (
    <div
      style={{
        height: "80vh",
      }}
    >
      {/* <div className="reqHeaderMainDiv">
        <span className="reqHeader">Requested Documents Uploader</span>
        <br />
        <span className="helperTextMsg">
          <b>Note:</b> 1) After submitting documents if any document is not
          uploaded and if it shows error, please delete that document and
          reupload it again.
          <br />
          2) Supported files include the following extensions only: *.pdf,
          *.docx, *.doc, *.jpeg, and *.jpg
        </span>
      </div> */}
      {/* {file?.map((objectData, objectDataIndex) => (
        <>
          <span className="titleClassName">{objectData?.title}</span>
          {objectData?.documentTypes?.map(
            (childObjectData, childObjectDataIndex) => (
              <div>
                <div className="fileUploadMainDiv">
                  <span className="fileName">{childObjectData?.title}</span>
                  {showUploadButton && (
                    <span className="uploadButton">
                      <label class="custom-file-upload">
                        <input
                          type="file"
                          value={(e) => e.target.value}
                          multiple={true}
                          onChange={(e) =>
                            fileHandler(
                              e,
                              childObjectData.id,
                              childObjectData.title,
                              objectDataIndex
                            )
                          }
                        />
                        Upload
                      </label>
                    </span>
                  )}
                </div>
                <div className="alignItemsInColumn">
                  {documentsList?.[objectDataIndex].map((fileDataObject) =>
                    fileDataObject.id === childObjectData.id ? (
                      <div className="alignInSameRowClassName">
                        <div
                          className={
                            fileDataObject?.status === "ERROR"
                              ? "errorDocumnetManinDiv"
                              : "documnetManinDiv"
                          }
                        >
                          <span
                            className={
                              fileDataObject?.status === "ERROR"
                                ? "whiteColor spanIcons"
                                : "spanIcons"
                            }
                          >
                            <FilePresent />
                          </span>
                          <span style={{ width: "100%" }}>
                            {fileDataObject.name}
                          </span>
                          {showPreviewAndDltButtons && (
                            <div className="alignToFLexEnd">
                              <Button
                                className="save_button"
                                onClick={() => openFile(fileDataObject?.data)}
                              >
                                Preview
                              </Button>
                              <span
                                className={
                                  fileDataObject?.status === "ERROR"
                                    ? "whiteColor spanIcons"
                                    : "spanIcons"
                                }
                              >
                                <DeleteIcon
                                  onClick={() =>
                                    deleteFile(
                                      fileDataObject?.data,
                                      objectDataIndex
                                    )
                                  }
                                />
                              </span>
                            </div>
                          )}
                        </div>
                        {!showUploadButton &&
                          fileDataObject?.status === "WAITING" && (
                            <FileUploadIcon style={{ color: "#00634a" }} />
                          )}
                        {!showUploadButton &&
                          fileDataObject?.status === "PENDING" && (
                            <LoopIcon
                              className="loaderIcon"
                              style={{ color: "#00634a" }}
                            />
                          )}
                        {!showUploadButton &&
                          fileDataObject?.status === "SUCCESS" && (
                            <CheckCircleIcon style={{ color: "#00634a" }} />
                          )}
                        {!showUploadButton &&
                          fileDataObject?.status === "ERROR" && (
                            <ErrorIcon style={{ color: "red" }} />
                          )}
                      </div>
                    ) : null
                  )}
                </div>
              </div>
            )
          )}
        </>
      ))} */}

      <div className="iframediv" style={{ overflow: "auto" }}>
        <iframe
          id="myFrame"
          src={`${process.env.REACT_APP_DOC_DEPOT}?GUID=${detailsToShow.Guid}&fileContactId=${detailsToShow.bid}`}
          style={{
            height: "100%",
            width: "100%",
          }}
        />
      </div>
    </div>
  );
}
