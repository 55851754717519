import React from "react";
import { connect } from "react-redux";
import { mapDispatchToProps, mapStateToProps } from "../../../helpers";

const Econsent = () => {
  return (
    <div style={{height:20}}>
      <script src="https://widget.ellieservices.com/latest/launcher.js"></script>
      <div
        className="em-widget"
        data-width="100%"
        data-height="800px"
        data-min-height="800px"
        data-site-id={process.env.REACT_APP_CC_WIDGET_ID}
        data-config='{"externalLoginWindow":"true"}'
        data-name="ecc-borrower-portal"
        data-aria-label="Borrower Portal"
      ></div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Econsent);
export {Econsent};