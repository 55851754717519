import * as React from "react";
import TextField from "@mui/material/TextField";
import { InputAdornment, InputLabel, Tooltip } from "@mui/material";
import "./components.css";
import moment from "moment";
import {
  LocalizationProvider,
  DesktopDatePicker,
} from "@mui/x-date-pickers";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import dayjs, { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

export default function DatePickerComponent(props) {
  const { value, required, onChange, label, view, name } = props;
  const twentyOneYearsAgo = dayjs().subtract(21, 'year');
  const defaultCalendarMonth = name === "DateOfBirth" && !value ? twentyOneYearsAgo : undefined;

  const handleChange = (newValue: Dayjs | null) => {
    let val = {
      target: {
        value: newValue ? moment(new Date(newValue).toLocaleDateString()).format(view === "year" ? "YYYY" : "YYYY-MM-DD") : ""
      },
    };
    onChange(val);
  };
  const onKeyDown = (e) => {
      e.preventDefault()
  }
  return (
    <div className="flexAlignColumnClassName" onClick={(e) => { e.preventDefault() }}>
      <InputLabel
        htmlFor="outlined-adornment-amount"
        required={required}
        className="labelClassName"
      >
        {label}
      </InputLabel>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DesktopDatePicker
          inputFormat={view === "year" ? "YYYY" : "MM/DD/YYYY"}

          views={view === "year" ? ['year'] : ['year', 'month', 'day']}
          value={value||null}
          onChange={handleChange}
          disableMaskedInput={true}
          className="menuItemOptionTag textFieldInputComponent"
          maxDate={name === "DateOfBirth" ? twentyOneYearsAgo : undefined}
          defaultCalendarMonth={defaultCalendarMonth}
          InputProps={{
                classes: {
                  root: "outlinedInputRoot",
                  focused: "outlinedInputFocused",
                  notchedOutline: "outlinedInput",
                  input: value && "ml"
                },
                endAdornment: (
                  <InputAdornment>
                    <CalendarMonthIcon />
                  </InputAdornment>
                ),
              }}
          renderInput={(params) => (
            <TextField
              {...params}
                data-testid="textfield"
                onKeyDown={onKeyDown}
            />
          )}
        />
      </LocalizationProvider>
      <div className="datePickerIconsDiv">
        {value && (
          <Tooltip title="Clear date" data-testid="tooltip">
            <HighlightOffIcon
             data-testid="icon"
              onClick={() => handleChange("")}
              style={{ cursor: "pointer" }}
            />
          </Tooltip>
        )}
      </div>
    </div>
  );
}
