import React, { useEffect } from "react";

import { connect } from "react-redux";
import { getAccessToken, getTokenExpiryTime, getUserEmail, mapDispatchToProps, mapStateToProps } from "../helpers";
import axios from "axios";
import { SAML_API_PATH } from "../constants";

function SAMLSSO() {

    const [relayState] = React.useState(new URLSearchParams(window.location.search).get("RelayState"));
    const [samlRequest] = React.useState(new URLSearchParams(window.location.search).get("SAMLRequest"))
    const [html, setHtml] = React.useState({ __html: "" })

    useEffect(() => {
        const params = new URLSearchParams({
            RelayState: relayState,
            access_token: getAccessToken(),
            SAMLRequest: samlRequest
        }).toString();

        axios
            .post(SAML_API_PATH, params, {
                headers: {
                    Authorization: `Bearer ${getAccessToken()}`,
                    "x-useremail": getUserEmail(),
                    "x-expires-in": getTokenExpiryTime(),
                    "Content-Type": "application/x-www-form-urlencoded",
                }
            })
            .then((response) => {
                setHtml({ __html: response.data.toString() });
            })
            .catch((error) => {
                console.log("Error in call to get saml token ,", error);
            });

    }, [relayState]);

    useEffect(() => {
        if (html) {
            setTimeout(() => {
                const form = document.getElementById("samlForm");
                if (form) {
                  form.submit();
                }
              }, 0); 
        }
    }, [html]);

    return <div dangerouslySetInnerHTML={html} />
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SAMLSSO);


