import React from "react";
import { traverseJSONSchema } from "./helpers/renderer";
import { setValueByRecursion } from "../helpers/index";
import _ from "lodash";
import {
  createDefaultValueArray, generateObjectPath, getHiddenStatePaths, getHiddenStatePathsForArray,
  manageDependentRequired,
  manageDependentSchemas,
  manageIfThenElse,
} from "./helpers";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  copyRawComponentState,
  updateErrorsDictionary,
} from "../redux/actions/actions";

class JsonSchemaForms extends React.PureComponent {
  initialJson = {};
  validator;
  key = "";
  summary=false;
  constructor(props) {
    super(props);
    this.initialJson = props.jsonData;
    this.editButton = props.edit
    this.key = props.pageKey;
    let ref = props.inputRef;
    if (ref) {
      ref.current = this;
    }
    this.summary=_.get(props,"summary",this.summary);
    let rawState = _.get(this.props?.rawComponentState, this.key, {});
    this.state = {
      jsonData: props.jsonData,
      rawComponentState: rawState,
      componentState: props.state,
      error: false,
      errorMsg: "",
      schemaInitialLoad:false,
    };
    this.arraySnapshot={};
  }

  /**
   * TEMP METHOD .TO REFACTOR
   * Only called once after the jsonSchema is fully traversed . This is done to update schema in the case of
   * saved applications. Sequence of operations
   * -> INitial render data through .json files (call traverseschema)
   * -> after initial render is done we will get paths to each flat(i.e type is anything except object and array) element in json
   * -> once paths are generated we will call this , based on the state that we receive from the backend the schema is
   * changed accordingly .
   * Using a state variable schemaInitialLoad to make sure this happens only once (todo: completedApplication case doesn't
   * work when schemaInitialLoad is used . making necessary changes for completedapplication case.)
   * @param data
   */
  updateSchemaOnLoad=(data)=>{
    if(this.summary && this.editButton){
      if(!this.state["schemaInitialLoad"]){
        this.setState({...this.state,schemaInitialLoad:true})
        this.recurUpdateSchemaOnLoad(data);
      }
    }
    else if(this.summary && !this.editButton){
      this.recurUpdateSchemaOnLoad(data);
    }
  }
  recurUpdateSchemaOnLoad(data){
    if(data){
      for (const [key, value] of Object.entries(data)){
        if(value.type==="object"){
          this.updateSchemaOnLoad(value.properties)
        }
        else if(value.path) {
          let e={
            target:{
              name: key,
              value: this.getFieldValue(value.path)
            }
          };
          this.onInitialLoadFunc(e, data)
        }
      }
    }
  }
  componentDidUpdate() {
    let stateCopy = this.state;

    this.setState({ ...stateCopy, componentState: this.props.state }, () => {
      stateCopy = this.state;
    });
    //added the below line to fix mi6-1358
    if (stateCopy["jsonData"] !== this.props.jsonData) {
      this.setState({ ...stateCopy, jsonData: this.props.jsonData });
    }
  }

  componentWillUnmount(){
    let tempState=this.state
    this.setState({...tempState,schemaInitialLoad:false});
    this.arraySnapshot={};
    this.props.updateErrorsDictionary({});

  }

  getFieldValue = (schemaPath) => {
    return _.get(
      this.state["componentState"],
      generateObjectPath(schemaPath),
      ""
    );
  };

  onInitialLoadFunc = (e, item) => {
    let tempJsonData = _.cloneDeep(this.state["jsonData"]);
    let componentStateCopy = _.cloneDeep(this.state["componentState"]);

    e={
      ...e,
      target:{
        name:item.path[item.path.length-1],
        value: this.getFieldValue(item.path)
      }
    }
    tempJsonData = this.applySubSchemaConditionally(
      tempJsonData,
      componentStateCopy,
      item,
      e
    );
    if(JSON.stringify(tempJsonData) !== JSON.stringify(this.state["jsonData"])){
      this.props.onLoadFunc(tempJsonData);
    }
  };

  onChange = (e, v, item) => {
    let tempJsonData = _.cloneDeep(this.state["jsonData"]);
    let componentStateCopy = _.cloneDeep(this.state["componentState"]);
    let rawComponentStateCopy = _.cloneDeep(this.state["rawComponentState"]);
    let statePath = generateObjectPath(item.path);
    let value = item?.type === "checkbox" ? e.target?.checked : e.target?.value;
    rawComponentStateCopy = setValueByRecursion(
      rawComponentStateCopy,
      item.path,
      (i) => value,
      []
    );
    this.props.copyRawComponentState(rawComponentStateCopy, this.key);
    componentStateCopy = setValueByRecursion(
      componentStateCopy,
      statePath,
      (i) => value,
      createDefaultValueArray(statePath)
    );
    tempJsonData = this.applySubSchemaConditionally(
      tempJsonData,
      componentStateCopy,
      item,
      e
    );
    tempJsonData = this.updateJsonDataErrors(e, item, tempJsonData);
    componentStateCopy=this.applyStateConditionally(tempJsonData,this.state["jsonData"],item,componentStateCopy);

    this.setState(
      {
         jsonData: tempJsonData,
         componentState: componentStateCopy,
         rawComponentState: rawComponentStateCopy,
      },
      () => {
        this.props.changeHandler(
          _.cloneDeep(this.state["componentState"]),
          _.cloneDeep(this.state["jsonData"])
        );
      }
    );


  };

  /**
   *
   * @param updatedSchema
   * @param oldSchema
   * @param item :null case is for array
   * @param componentState
   * @returns {*}
   */
  applyStateConditionally(updatedSchema, oldSchema, item, componentState) {
    if(_.isEqual(updatedSchema,oldSchema)){
      return componentState;
    }
    let paths = [];
    if (item === null) {
      paths = getHiddenStatePathsForArray(updatedSchema, oldSchema, componentState, [],[]);//for array of objects only

    } else {
      paths = getHiddenStatePaths(updatedSchema, oldSchema, item, componentState, []);
    }
    for (let i = 0; i < paths.length; i++) {
      if (!_.get(updatedSchema, paths[i], {}).hasOwnProperty('type')) { //schema is no longer visible in ui
        _.unset(componentState, generateObjectPath(paths[i]));
      }
    }
    return componentState;
  }


  /**
   * Makes use of error dictionary created in updateJsonDataErrors for error validation
   * @param path
   * @returns {{}|*}
   */
  
  getSchemaForArray = (path) => {
    let schema;
    let schemaOfParent = _.cloneDeep(
      _.get(this.state["jsonData"], path.slice(0, path.length - 1))
    );
    let form = _.get(
      this.state["componentState"],
      generateObjectPath(path)
    );
    schema = this.applyIfThenElseRecursively(schemaOfParent["items"], form);
    let oldSchema=this.arraySnapshot[path.toString()];
    if(oldSchema===undefined){
      oldSchema=schemaOfParent["items"];
    }
    form=this.applyStateConditionally(schema,oldSchema,null,form);
    let state=setValueByRecursion(this.state["componentState"],generateObjectPath(path),()=>form,[]);
    this.setState({
      ...this.state,
      "componentState":state
    });
    if(_.get(schemaOfParent,path[path.length - 1])!==undefined){
      for (const [key, value] of Object.entries(_.get(schemaOfParent,path[path.length - 1]))){
          if (value["error"]){
            schema["properties"][key]["error"] =true;
          }
      }
    }
    this.arraySnapshot=setValueByRecursion(this.arraySnapshot,[path.toString()],()=>schema,[])
    return schema;
  };

  updateJsonDataErrors = (e, item, jsonData) => {
    let updatedErrorDict = this.props.errorsDictionary;
    if ((item.type === "number" && e.target.value === '') || (e.target?.validity && !e.target?.checkValidity())) {
      updatedErrorDict[e.target.name] = item.errorMsg;
      item.path.push("error");
      jsonData = setValueByRecursion(jsonData, item.path, (i) => true, []);
    } else {
      delete updatedErrorDict[e.target.name];
      item.path.push("error");
      jsonData = setValueByRecursion(jsonData, item.path, (i) => false, []);
    }
    if (updatedErrorDict !== this.props.errorsDictionary) {
      this.props.updateErrorsDictionary(updatedErrorDict);
    }
    return jsonData;
  };
  applySubSchemaConditionally = (jsonData, formData, item, e) => {
    jsonData = this.manageDependencies(jsonData, item, e);
    jsonData = this.applyIfThenElseRecursively(jsonData, formData);
    return jsonData;
  };

  applyIfThenElseRecursively = (jsonData, formData) => {
    let recur = (schema, form) => {
      if (schema === undefined) {
        return {};
      }
      if (form === undefined) {
        return schema;
      }
      let newSchema = schema;
      if (newSchema.properties !== undefined) {
        for (const key in newSchema.properties) {
          newSchema["properties"][key] = recur(
            newSchema["properties"][key],
            form[key]
          );
        }
      }
      if (newSchema["if"] !== undefined) {
        newSchema = manageIfThenElse(form, newSchema);
      }
      if (newSchema["allOf"] !== undefined) {
        newSchema = this.applyIfThenElseForAllOf(form, schema);
      }
      return newSchema;
    };
    return recur(jsonData, formData);
  };

  applyIfThenElseForAllOf = (form, schema) => {
    for (let j = 0; j < schema["allOf"].length; j++) {
      schema["if"] = _.get(schema["allOf"][j], "if");
      schema["then"] = _.get(schema["allOf"][j], "then");
      schema["else"] = _.get(schema["allOf"][j], "else");
      schema = manageIfThenElse(form, schema);
    }
    return schema;
  };

  /**
   * manages schema and property dependencies. returns modified jsonObject
   * @param jsonData
   * @param item
   * @param event which triggered the method
   */
  manageDependencies = (jsonData, item, event) => {
    let parent = _.get(
      jsonData,
      item?.path?.slice(0, item.path.length - 2),
      jsonData
    );
    let dependentSchemas = _.get(parent?.dependentSchemas, event.target?.name);
    let dependentRequired = _.get(
      parent?.dependentRequired,
      event.target?.name
    );
    let pathToUpdateIn = item?.path?.slice(0, item?.path?.length - 2);
    jsonData = manageDependentRequired(
      jsonData,
      dependentRequired,
      pathToUpdateIn,
      event
    );
    jsonData = manageDependentSchemas(
      jsonData,
      dependentSchemas,
      pathToUpdateIn,
      item,
      event
    );
    return jsonData;
  };


  /**
   *
   * @param {*} jsonElement
   * @param {*} operation  1:add;0:delete
   * @param {*} currentIndex array index
   */
  manageArray = (jsonElement, operation, currentIndex) => {
    let tempState = this.state;
    let arrayState = [];
    let updatedSchema = _.cloneDeep(tempState["jsonData"]);
    let tempJsonElement = _.cloneDeep(jsonElement);
    tempJsonElement.length =
      operation === 1 ? tempJsonElement.length + 1 : tempJsonElement.length - 1;
    let elementPath = tempJsonElement.path;
    if (operation === 0) {
      let pathWithIndex = _.clone(elementPath);
      arrayState = _.get(
        tempState["componentState"],
        generateObjectPath(pathWithIndex)
      );
      if (arrayState !== undefined) {
        arrayState = arrayState.filter((item, i) => i !== currentIndex)
        // arrayState.splice(currentIndex, 1);
        tempState["componentState"] = setValueByRecursion(
          tempState["componentState"],
          generateObjectPath(elementPath),
          (i) => arrayState,
          createDefaultValueArray(generateObjectPath(elementPath))
        );
      }
    }
    updatedSchema = setValueByRecursion(
      updatedSchema,
      elementPath,
      (i) => tempJsonElement,
      createDefaultValueArray(generateObjectPath(elementPath))
    );
    this.setState(
      {
        ...tempState,
        jsonData: updatedSchema,
        componentState: tempState["componentState"],
      },
      () => {
        this.props.changeHandler(
          _.cloneDeep(this.state["componentState"]),
          _.cloneDeep(this.state["jsonData"])
        );
      }
    );
  };

  render() {
    return (
      <div>
        {this.state &&
          traverseJSONSchema(this.state["jsonData"], {
            onChange: this.onChange,
            getFieldValue: this.getFieldValue,
            manageArray: this.manageArray,
            getSchemaForArray: this.getSchemaForArray,
            summary: this.summary,
            editButton :this.editButton,
            pageKey: this.key,
            clickEditHandler: this.props.clickEditHandler,
            onInitialLoad: this.onInitialLoadFunc
          })}
      </div>
    );
  }
}
function mapStateToProps({ userReducer }) {
  return {
    rawComponentState: userReducer.rawComponentState,
    errorsDictionary: userReducer.errorsDictionary,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      copyRawComponentState: copyRawComponentState,
      updateErrorsDictionary: updateErrorsDictionary,
    },
    dispatch
  );
}
export default connect(mapStateToProps, mapDispatchToProps)(JsonSchemaForms);
export {JsonSchemaForms}; 
