import React, { useEffect } from 'react';
import { getLabel } from '../helper';
import InputAdornment from '@mui/material/InputAdornment';
import ListItemButtonStack from './RadioGroup';
import './UserQuestionnaire.css';
import PersonalInformation from './PersonalInformation';
import AdressInformation from './AddressInformation';
import FormControl from '@mui/material/FormControl';
import Checkbox from '@mui/material/Checkbox';
import { useState } from 'react';
import CustomTextField from './CustomTextField';
import { Tooltip } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const QuestionBox = ({ question, answer, onChange, errors, setErrors }) => {
  const [showDropDown, setShowDropDown] = useState(false);
  const [shrinkLabel, setShrinkLabel] = useState(!!answer);

  const handleAnswerChange = (newValue, key) => {

    
    onChange(newValue);
  };

  const formatNumberWithCommas = (value) => {
    if (value === '' || value === null || isNaN(value)) {
      return value;
    }
    else if (question.field === "creditScore") {
      return value
    }
    return parseFloat(value).toLocaleString();
  };

  const handleTextChange = (event) => {
    let rawValue = event.target.value.replace(/,/g, '');
    if (/^\d*$/.test(rawValue)) {
      if (rawValue.length > 9) {
        rawValue = rawValue.slice(0, 9);
      }
      const validationError = validateAmount(rawValue);
      setErrors((prevErrors) => ({
        ...prevErrors,
        [question.field]: validationError,
      }));
      onChange(rawValue);
    }
  };

  const validateAmount = (value) => {
    if (question.field === 'creditScore') {
      if (value.trim() === '') {
        return 'Credit score is required(300-850)';
      }
    }
    else {
      if (value.trim() === '') {
        return 'Amount is required';
      }
    }
    return '';
  };

  const handleTextFieldClick = () => {
    setShrinkLabel(true);
  };

  return (
    <div className="question-box">
      <div className="question">
        {question.text}
        <div className='tool-tip'>
          <b> Tip:</b> {question.tooltip}
        </div>

      </div>
      <div className="answer">
        {question.type === 'personalInformation' ? (
          <div className='info'>
            <PersonalInformation answer={answer} errors={errors} setErrors={setErrors} onChange={onChange} />
          </div>
        ) : question.type === 'addressInformation' ? (
          <AdressInformation answer={answer} errors={errors} setErrors={setErrors} onChange={onChange} />
        ) :
          question.type === 'singleChoice' ? (
            <ListItemButtonStack answer={answer} handleAnswerChange={handleAnswerChange} options={question.options} />
          ) :
            question.type === 'multipleChoice' ? (
              <div className="checkbox-group">
                {question.options.map((option, index) => (
                  <div className='checkbox-item'>
                    <label key={index} className="checkbox-label">
                      <Checkbox
                        value={option.value}
                        checked={answer.includes(option.value)}
                        onChange={() => {
                          if (answer.includes(option.value)) {
                            handleAnswerChange(
                              answer
                                .split(', ')
                                .filter((value) => value !== option.value)
                                .join(', ')
                            );
                          } else {
                            const updatedChoices = answer
                              ? `${answer}, ${option.value}`
                              : option.value;
                            handleAnswerChange(updatedChoices);
                          }
                        }}
                        sx={{ '&.Mui-checked': { color: '#00634a' } }} />
                      {option.text}
                    </label>
                  </div>
                ))}
              </div>) :
              question.type === "amount" ? (
                <div>
                  <FormControl fullWidth sx={{ m: 1 }}>
                    <CustomTextField
                      id={question.field}
                      InputProps={{
                        startAdornment: formatNumberWithCommas(answer) ? (
                          <InputAdornment position="start">$</InputAdornment>
                        ) : null,
                      }}
                      label={getLabel(question.field)}
                      
                      name='amount'
                      className='custom-amount'
                      defaultValue={formatNumberWithCommas(answer)}
                      value={formatNumberWithCommas(answer)}
                      onChange={handleTextChange}
                      
                      onClick={handleTextFieldClick}
                      
                      helperText={errors[question.field]}
                    />
                  </FormControl>

                </div>
              ) : (
                <FormControl fullWidth sx={{ m: 1 }}>
                  <CustomTextField
                    id={question.field}
                    label={getLabel(question.field)}
                    defaultValue={formatNumberWithCommas(answer)}
                    value={formatNumberWithCommas(answer)}
                    onChange={handleTextChange}
                    InputLabelProps={{ shrink: shrinkLabel }}
                    onClick={handleTextFieldClick}
                    
                    helperText={errors[question.field]}
                  />
                </FormControl>
              )}
      </div>
    </div>
  );
};

export default QuestionBox;