import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Stack } from "@mui/material";
import "./components.css";

function StackButtons(props) {
  const [value, setValue] = useState();
  const theme = createTheme({
    palette: {
      neutral: {
        main: "#00634A",
        contrastText: "#fff",
      },
    },
  });

  useEffect(() => {
    setValue(props?.value);
  }, [props?.value]);

  const onClick = (e) => {
    setValue(e.target.value);
    props.onClick(e);
  };
  return (
    <ThemeProvider theme={theme}>
      <Stack
        style={{
          pointerEvents: props.disabled ? "none" : "auto",
        }}
        spacing={2}
        direction="row"
      >
        <Button
          value="Yes"
          name={props.name}
          color="neutral"
          onTransitionEnd={(e) => props.onLoad(e)}
          onClick={(e) => onClick(e)}
          className={
            ["yes", "Yes"].includes(value)
              ? "activeYesButton"
              : "inActiveNoButton"
          }
          data-testId={props.name + "True"}
        >
          Yes
        </Button>
        <Button
          value="No"
          name={props.name}
          color="neutral"
          onTransitionEnd={(e) => props.onLoad(e)}
          onClick={(e) => onClick(e)}
          className={
            ["no", "No"].includes(value)
              ? "activeYesButton"
              : "inActiveNoButton"
          }
          data-testId={props.name + "False"}
        >
          No
        </Button>
      </Stack>
    </ThemeProvider>
  );
}

export default StackButtons;
