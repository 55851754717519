import React, { useEffect, useRef, useState } from "react";
import JsonSchemaForms from "../../../json_schema_forms";
import personal_information from "./personal_information.json";
import _, { set } from "lodash";
import {
  mapDispatchToProps,
  mapStateToProps,
  setValueByRecursion,
} from "../../../helpers";
import { connect } from "react-redux";
import { el } from "date-fns/locale";
import { copySchema, copyStateToRedux } from "../../../redux/actions/actions";

function PersonalInformation(props) {
  const [state, setState] = useState(props.componentState);

  const [jsonSchema, setJsonSchema] = React.useState(
    _.cloneDeep(personal_information)
  );

  useEffect(() => {
    return () => {
      setJsonSchema(_.cloneDeep(personal_information));
    };
  }, []);

  useEffect(() => {
    if (state?.ApplicantDetails === undefined) {
      setState({
        ...state,
        ApplicantDetails: {
          BorrowerDetails: {
            FirstName: localStorage.getItem("username").split(" ")[0],
            LastName: localStorage.getItem("username").split(" ")[
              localStorage.getItem("username").split(" ").length - 1
            ],
            Email: localStorage.getItem("user_email"),
          },
        },
      });
      props.copyStateToRedux({
        ...state,
        ApplicantDetails: {
          BorrowerDetails: {
            FirstName: localStorage.getItem("username").split(" ")[0],
            LastName: localStorage.getItem("username").split(" ")[
              localStorage.getItem("username").split(" ").length - 1
            ],
            Email: localStorage.getItem("user_email"),
          },
        },
      });
    }
  }, []);

  useEffect(() => {
    if (!_.isEmpty(props.componentState)) {
      setState(props.componentState);
    }
  }, [props.componentState]);

  useEffect(() => {
    if (props.componentSchema?.personalInformation) {
      setJsonSchema(props.componentSchema["personalInformation"]);
    }
  }, [props.componentSchema]);

  const onLoadFunc = (updatedSchema) => {
    props.copySchema(updatedSchema, "personalInformation");
  };

  const changeHandler = (updatedState, updatedSchema) => {
    let AreYouApplyingForAJointCreditWithACoBorrower = _.get(updatedState, [
      "JointCredit",
      "AreYouApplyingForAJointCreditWithACoBorrower",
    ]);

    if (AreYouApplyingForAJointCreditWithACoBorrower === "No") {
      if (
        _.get(updatedState, [
          "Declarations",
          "ApplicantDeclarations",
          "CoBorrowerDeclarations",
        ])
      ) {
        delete _.get(updatedState, ["Declarations", "ApplicantDeclarations"])[
          "CoBorrowerDeclarations"
        ];
      }
      if (
        _.get(updatedState, [
          "Declarations",
          "ApplicantDemographicInformation",
          "CoBorrowerDemographicInformation",
        ])
      ) {
        delete _.get(updatedState, [
          "Declarations",
          "ApplicantDemographicInformation",
        ])["CoBorrowerDemographicInformation"];
      }
    }

    if (updatedState?.Address?.BorrowerAddress?.BorrowerCurrentAddress?.Housing !== null && updatedState?.Address?.BorrowerAddress?.BorrowerCurrentAddress?.Housing === "Own") {
      let firstMrtgage = parseFloat(updatedState?.Address?.BorrowerAddress?.BorrowerCurrentAddress["FirstMortgage(P&I)"]);
      let hazrdInsurance = parseFloat(updatedState?.Address?.BorrowerAddress?.BorrowerCurrentAddress?.HazardInsurance);
      let mrtgageInsu = parseFloat(updatedState?.Address?.BorrowerAddress?.BorrowerCurrentAddress?.MortgageInsurance);
      let realEstTax = parseFloat(updatedState?.Address?.BorrowerAddress?.BorrowerCurrentAddress?.RealEstateTaxes);
      let secondMrtgage = parseFloat(updatedState?.Address?.BorrowerAddress?.BorrowerCurrentAddress["SecondMortgage(P&I)"]);
      let supplMenInsu = parseFloat(updatedState?.Address?.BorrowerAddress?.BorrowerCurrentAddress?.SupplementalInsurance);
      updatedState["Address"]["BorrowerAddress"]["BorrowerCurrentAddress"]["Total"] = Math.round(_.sum([
        firstMrtgage ? firstMrtgage : 0.00,
        hazrdInsurance ? hazrdInsurance : 0.00,
        mrtgageInsu ? mrtgageInsu : 0.00,
        realEstTax ? realEstTax : 0.00,
        secondMrtgage ? secondMrtgage : 0.00,
        supplMenInsu ? supplMenInsu : 0.00
      ]));
    }
    props.copySchema(updatedSchema, "personalInformation");
    setState(updatedState);
    props.copyStateToRedux(updatedState);
  };

  /**Temp fix give For MI6-3273 */
  useEffect(() => {
    if (state?.JointCredit?.AreYouApplyingForAJointCreditWithACoBorrower === "No") {

      /**MI6-3283 */
      delete state?.Address?.CoBorrowerAddress?.PreviousAddress
      if (state?.Address?.CoBorrowerAddress !== undefined) {
        state.Address.CoBorrowerAddress.PreviousAddress = []
        jsonSchema.properties.Address.properties.CoBorrowerAddress.properties.PreviousAddress.length = 0
        setJsonSchema(jsonSchema)
      }
      /**MI6-3283 */

      /*MI6-3294*/
      delete state?.EmploymentAndIncome?.CoBorrower
      /*MI6-3294*/

    }
    /**MI6-3300 */
    if (state?.JointCredit?.AreYouApplyingForAJointCreditWithACoBorrower === "No") {

      let filterdAdditionalIncome = []

      state?.EmploymentAndIncome?.additionalIncome?.map((each, index) => {
        if (each?.belongsTo === "CoBorrower") {
          delete state?.EmploymentAndIncome?.additionalIncome[index]
        } else {
          filterdAdditionalIncome.push({ ...each, belongsTo: undefined })
        }
      })

      if (state?.EmploymentAndIncome?.additionalIncome !== undefined) {
        delete state?.EmploymentAndIncome?.additionalIncome
        state.EmploymentAndIncome.additionalIncome = filterdAdditionalIncome
      }

    }
    if (state?.JointCredit?.AreYouApplyingForAJointCreditWithACoBorrower === "No") {
      let filterAssets = []
      state?.ManualAssetsEntry?.Assets.filter((each, index) => {
        if (each?.belongsTo === "CoBorrower" || each?.belongsTo === "Both") {
          return delete state?.ManualAssetsEntry?.Assets[index]
        } else {
          filterAssets.push({ ...each, belongsTo: undefined })
        }
      }
      )

      if (state?.ManualAssetsEntry?.Assets) {
        state.otherAssets = filterAssets;
        state.ManualAssetsEntry.Assets = filterAssets;
      }
      if (props?.componentSchema?.listOfAssets?.properties !== undefined) {
        props.componentSchema.listOfAssets.properties.ManualAssetsEntry.properties.Assets.length = filterAssets.length
      }
      copySchema(props.componentSchema)
    }
    /**MI6-3300 */



    if (state?.JointCredit?.AreYouApplyingForAJointCreditWithACoBorrower === "No") {
      if (state?.ApplicantDetails?.CoBorrowerDetails?.MaritalStatus?.IsThereAPersonWhoIsNotYourLegal?.IsThereAPersonWhoIsNotYourLegal !== "Yes") {
        delete state?.ApplicantDetails?.CoBorrowerDetails?.MaritalStatus?.IsThereAPersonWhoIsNotYourLegal?.DomesticRelationshipType
      }
    }

    // console.log(state, "before")
    setState(state)
    copyStateToRedux(state)

  }, [state?.JointCredit?.AreYouApplyingForAJointCreditWithACoBorrower])


  // console.log(state, "after")
  // console.log(jsonSchema, "schema")
  // console.log(props.componentState, "reduxstate")




  return (
    <JsonSchemaForms
      state={state}
      jsonData={jsonSchema}
      changeHandler={changeHandler}
      pageKey={"personalInformation"}
      onLoadFunc={onLoadFunc}
    />
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PersonalInformation);
