import axios from "axios";
import { API_URL, GET_LOANS, GET_LOAN_DATA, GET_DOC_REQUEST, GET_LOAN_LOCK_STATUS } from "../constants";
import {
    getAccessToken,
    getUserEmail,
    getTokenExpiryTime,
  } from "../../helpers/index";
function getHeaders() {
  return {
    "Content-Type": "application/json",
    Authorization: `Bearer ${getAccessToken()}`,
    "x-useremail": getUserEmail(),
    "x-expires-in": getTokenExpiryTime(),
  };
}
export const getLoans = () => async (dispatch) => {
  await axios
    .get(`${API_URL}/customer-service/api/v1/cx/applications`, {
      headers: getHeaders(),
    })
    .then((response) => {
      dispatch({
        type: GET_LOANS,
        payload: response,
      });
    })
    .catch((error) => {
      dispatch({
        type: GET_LOANS,
        payload:
          error.message === "Network Error" ? error.message : error.response,
      });
    });
};
export const getLoanData = (uuid) => async (dispatch) => {
  if (uuid !== undefined || uuid !== null) {
    await axios
      .get(`${API_URL}/customer-service/api/v1/cx/applications/${uuid}`, {
        headers: getHeaders(),
      })
      .then((response) => {
        dispatch({
          type: GET_LOAN_DATA,
          payload: response,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_LOAN_DATA,
          payload:
            error.message === "Network Error" ? error.message : error.response,
        });
      });
  }
};
export const getDocRequest = (uuid) => async (dispatch) => {
  await axios
    .get(`${API_URL}/docstore-service/api/v1/cx/loans/${uuid}/docRequest `, {
      headers: getHeaders(),
    })
    .then((response) => {
      dispatch({
        type: GET_DOC_REQUEST,
        payload: response,
      });
    })
    .catch((error) => {
      dispatch({
        type: GET_DOC_REQUEST,
        payload:
          error.message === "Network Error" ? error.message : error.response,
      });
    });
};
export const getLoanLockStatus = (uuid) => (dispatch) => {
  return new Promise((resolve, reject) => {
  axios
    .get(`${API_URL}/customer-service/api/v1/cx/lock/${uuid} `, {
      headers: getHeaders(),
    })
    .then((response) => {
      dispatch({
        type: GET_LOAN_LOCK_STATUS,
        payload: response,
      });
      resolve(response);
    })
    .catch((error) => {
      dispatch({
        type: GET_LOAN_LOCK_STATUS,
        payload:
          error.message === "Network Error" ? error.message : error.response,
      });
      reject(error.message === "Network Error" ? error.message : error.response);
    });
  });
};
