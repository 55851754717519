import {
  COPY_STATE_TO_REDUX,
  COPY_SCHEMA,
  COPY_RAW_COMPONENT_STATE,
  UPDATE_ERRORS_DICTIONARY,
  SHOW_LOADER, RESET_REDUX,
  ACTIVE_STEP,
  STEPPER_BACK
} from "../constants";

export const copyStateToRedux = (state) => async (dispatch) => {
  dispatch({
    type: COPY_STATE_TO_REDUX,
    payload: state,
  });
};

export const copySchema = (state, key) => async (dispatch) => {
  dispatch({
    type: COPY_SCHEMA,
    payload: { state: state, key: key },
  });
};

export const copyRawComponentState = (state, key) => async (dispatch) => {
  dispatch({
    type: COPY_RAW_COMPONENT_STATE,
    payload: { state: state, key: key },
  });
};

export const updateErrorsDictionary = (state) => async (dispatch) => {
  dispatch({
    type: UPDATE_ERRORS_DICTIONARY,
    payload: state,
  });
};
export const showLoader = (status) => async (dispatch) => {
  dispatch({
    type: SHOW_LOADER,
    payload: status,
  });
};

export const resetRedux=()=>async (dispatch)=>{
  dispatch({
    type: RESET_REDUX
  });
}
export const activeStep=(step)=>async (dispatch)=>{
  dispatch({
    type:ACTIVE_STEP,
    payload:step ,
  })
}

export const stepperBackStep=(status)=>async (dispatch)=>{
  dispatch({
    type:STEPPER_BACK,
    payload:status ,
  })

}