
import React, { useState, useEffect } from "react";
import CustomTextField from "./CustomTextField";
import './PersonalInformation.css';

const AddressInformation = ({ answer, errors, setErrors, onChange }) => {
  const validateAnswer = (field, value) => {
    switch (field) {
      case "address":
        if (!value || value.trim() === "") {
          return "Address is required";
        }
        break;
      case "cityName":
        if (!value || value.trim() === "") {
          return "City Name is required";
        }
        break;
      case "county":
        if (!value || value.trim() === "") {
          return "County is required";
        }
        break;
      case "stateCode":
        if (!value || value.trim() === "") {
          return "State code is required";
        }
        break;
      case "zipCode":
        if (!value || value === "") {
          return "Zip code is required";
        }
        break;
      default:
        break;
    }
    return "";
  };

  useEffect(() => {
    const initialErrors = {};
    for (const field in answer) {
      initialErrors[field] = validateAnswer(field, answer[field]);
    }
    setErrors(initialErrors);
  }, [answer]);

  const handleInputChange = (newValue, field) => {
    const validationError = validateAnswer(field, newValue[field]);
    setErrors((prevErrors) => ({
      ...prevErrors,
      [field]: validationError,
    }));
    onChange(newValue)
  };

  return (
    <div className="address-information-form">
      <div className="form-body">
        <CustomTextField
          label={<span style={{ fontWeight: 'bold' }}>Address</span>}
          className="custom-input address"
          defaultValue={answer.address}
          InputLabelProps={{ shrink: true }}
          
          helperText={errors.address}
          onChange={(event) =>
            handleInputChange({
              ...answer,
              ["address"]: event.target.value
            }, "address"
            )
          }
          required
        />
        <CustomTextField
          label={<span style={{ fontWeight: 'bold' }}>City Name</span>}
          className="custom-input city-name"
          defaultValue={answer.cityName}
          InputLabelProps={{ shrink: true }}
          
          helperText={errors.cityName}
          onChange={(event) =>
            handleInputChange({
              ...answer,
              ["cityName"]: event.target.value
            }, "cityName"
            )
          }
          required
        />
        <CustomTextField
          label={<span style={{ fontWeight: 'bold' }}>County</span>}
          className="custom-input county"
          defaultValue={answer.county}
          InputLabelProps={{ shrink: true }}
          
          helperText={errors.county}
          onChange={(event) =>
            handleInputChange({
              ...answer,
              ["county"]: event.target.value
            }, "county"
            )
          }
          required
        />
      </div>
      <div className="name-fields">
        <CustomTextField
          label={<span style={{ fontWeight: 'bold' }}>State Code</span>}
          className="custom-input state-code"
          defaultValue={answer.stateCode}
          InputLabelProps={{ shrink: true }}
          
          helperText={errors.stateCode}
          onChange={(event) =>
            handleInputChange({
              ...answer,
              ["stateCode"]: event.target.value
            }, "stateCode"
            )
          }
          required
        />
        <CustomTextField
          label={<span style={{ fontWeight: 'bold' }}>Zip Code</span>}
          className="custom-input zip-code"
          defaultValue={answer.zipCode}
          InputLabelProps={{ shrink: true }}
          
          helperText={errors.zipCode}
          onChange={(event) =>
            handleInputChange({
              ...answer,
              ["zipCode"]: event.target.value
            }, "zipCode"
            )
          }
          required
        />
      </div>
    </div>
  );
};

export default AddressInformation;