import React, { useEffect } from "react";
import "./DocumentsManager.css";
import SubmittedLoanDashboardSaveGrid from "../LoanDashboardSaveGrid/SubmittedLoanDashboardSaveGrid";
import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import { withSnackbar } from "../../../components/Snackbar";
import { connect } from "react-redux";
import ViewUploadedDocuments from "./view_uploaded_documents";
import { DeleteVerificationPopup } from "../../deleteVerificationModal";
import { RenderData as renderData } from "./renderData";
import { mapDispatchToProps, mapStateToProps } from "../../../helpers";

const DocumentsManager = (props) => {
  const [file, setFile] = React.useState([]);
  const [imagePreview, setImagePreview] = React.useState(null);
  const [documentsList, setDocumentsList] = React.useState([]);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [viewDocumentsDialog, setViewDocumentsDialog] = React.useState(false);
  const [openDltVerificationPopup, setOpenDltVerificationPopup] =
    React.useState(false);
  const [selectedFileData, setSelectedFileData] = React.useState();

  useEffect(() => {
    let loanGuid = localStorage.getItem("loanGuid");
    props.getDocRequest(loanGuid);
  }, []);

  useEffect(() => {
    let docReqRes = props.getDocRequestsRes;
    if (docReqRes?.status === 200) {
      setFile(docReqRes?.data?.documentGroups);
    }
    let tempArr = [];
    docReqRes?.data?.documentGroups?.map(() => {
      tempArr.push([]);
    });
    setDocumentsList(tempArr);
  }, [props.getDocRequestsRes]);

  const fileHandler = (e, id, title, mainIndex) => {
    let newAr = [...documentsList];
    let supportedDocTypes = [
      "image/jpeg",
      "application/pdf",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ];
    if (supportedDocTypes?.includes(e.target.files[0]?.type)) {
      newAr[mainIndex]?.push({
        id: id,
        title: title,
        name: e.target.files[0]?.name,
        data: e.target.files[0],
        status: "WAITING",
      });
      setDocumentsList(newAr);
    } else {
      props.showToast(
        "This file format will not be supported, Supported files include the following extensions only: *.pdf, *.docx, *.doc, *.jpeg, and *.jpg",
        "error"
      );
    }
  };

  const openFile = (e) => {
    const selectedFile = e;
    const reader = new FileReader();
    if (selectedFile.type.includes("image")) {
      reader.readAsDataURL(selectedFile);
      reader.onload = (readerEvent) => {
        setImagePreview(readerEvent.target.result);
      };
      toggleDialog();
    } else if (selectedFile.type.includes("application/pdf")) {
      setImagePreview(null);
      const blobFileData = new Blob([selectedFile], {
        type: "application/pdf",
      });
      const fileURL = URL.createObjectURL(blobFileData);
      const pdfWindow = window.open();
      pdfWindow.location.href = fileURL;
    } else {
      setImagePreview(null);
      const url = window.URL.createObjectURL(new Blob([selectedFile]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", selectedFile.name);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      props.showToast("Successfully downloaded", "success");
    }
  };

  const toggleDialog = () => {
    setOpenDialog(!openDialog);
  };

  const deleteFile = (fileData, mainIndex) => {
    let data = {
      data: fileData,
      mainIndex: mainIndex,
    };
    setSelectedFileData(data);
    handleDeleteVerificationPopup();
  };

  const handleDeleteVerificationPopup = () => {
    setOpenDltVerificationPopup(!openDltVerificationPopup);
  };

  const handleViewDocumentsDialog = () => {
    setViewDocumentsDialog(!viewDocumentsDialog);
  };

  const noFunc = () => {
    setSelectedFileData();
    handleDeleteVerificationPopup();
  };

  const yesFunc = () => {
    let newArr = [...documentsList];
    const index = newArr[selectedFileData?.mainIndex].findIndex(
      (object) => object.data === selectedFileData.data
    );
    if (index > -1) {
      newArr[selectedFileData?.mainIndex].splice(index, 1);
    }
    setDocumentsList(newArr);
    handleDeleteVerificationPopup();
  };

  const updateDocumentStatus = (status, mainIndex, childIndex) => {
    let newAr = [...documentsList];
    newAr[mainIndex][childIndex] = {
      ...newAr[mainIndex][childIndex],
      status: status,
    };
    setDocumentsList(newAr);
  };

  return (
    <div>
      {renderData({
        file: file,
        documentsList: documentsList,
        showUploadButton: true,
        showPreviewAndDltButtons: true,
        fileHandler: (e, nestedValKey, title, mainIndex) =>
          fileHandler(e, nestedValKey, title, mainIndex),
        openFile: (valueData) => openFile(valueData),
        deleteFile: (fileData, mainIndex) => deleteFile(fileData, mainIndex),
      })}
      {/* <SubmittedLoanDashboardSaveGrid
        open={props.open}
        hideSaveButton={true}
        hideBackButton={true}
        submitFunc={() => handleViewDocumentsDialog()}
      /> */}
      <Dialog
        open={openDialog}
        maxWidth="lg"
        PaperProps={{ style: { width: "100%" } }}
      >
        <DialogContent>
          <img src={imagePreview} style={{ width: "100%", height: "100%" }} />
        </DialogContent>
        <DialogActions>
          <Button className="save_button" onClick={() => toggleDialog()}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <ViewUploadedDocuments
        file={file}
        documentsList={documentsList}
        open={viewDocumentsDialog}
        updateDocumentStatusFunc={(status, mainIndex, childIndex) =>
          updateDocumentStatus(status, mainIndex, childIndex)
        }
        handleOnClose={() => handleViewDocumentsDialog()}
      />
      <DeleteVerificationPopup
        text="Are you sure, you want to delete the uploaded document ?"
        openPopup={openDltVerificationPopup}
        closePopup={() => handleDeleteVerificationPopup()}
        yesFunc={() => yesFunc()}
        noFunc={() => noFunc()}
      />
    </div>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(DocumentsManager));
