import React from "react";
import MuiAppBar from "@mui/material/AppBar";
import { useMsal } from "@azure/msal-react";
import { BASE_PATH } from "../constants";
import { BSMLogo } from "../assets/svgIconsList";
import { styled, Toolbar } from "@mui/material";
import ProfileCard from "../pages/loan_app/profile_card";
import { useNavigate } from "react-router-dom";
import { appendQueryParamToURL } from "../helpers";
import { IconButton } from "@mui/material";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";

const HeaderComponent = React.memo((props) => {
  let navigate = useNavigate();
  const { instance } = useMsal();
  const navigateHome = () => {
    localStorage.removeItem("appId");
    localStorage.removeItem("loan-app-dashboard");
    localStorage.removeItem("userName");
    const urlWithParams = appendQueryParamToURL(process.env.REACT_APP_BASE_URL);
    navigate(urlWithParams);
  };
  const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
  })(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: "white",
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  }));

  function signOutClickHandler() {
    const logoutRequest = {
      account: instance.getAccountByHomeId(
        window.localStorage.getItem("home_account_id")
      ),
      postLogoutRedirectUri: BASE_PATH,
    };
    const getLarFromLocalStorage = localStorage.getItem("lar");
    window.localStorage.clear();
    if(getLarFromLocalStorage !== null) {
      window.localStorage.setItem('lar', getLarFromLocalStorage);
    }
    window.sessionStorage.clear();
    instance.logoutRedirect(logoutRequest);
  }

  return (
    <div>
      <AppBar position="fixed">
        <Toolbar style={{ paddingLeft: "10px" }}>
          <div className="alignRowClassName">
            <div style={{ display: "flex", flexDirection: "row", gap: "5px" }}>
              {props.pageIndex >= 2 && (
                <IconButton onClick={props.setOpen}>
                  <MenuRoundedIcon />
                </IconButton>
              )}
              <div
                onClick={() => navigateHome()}
                data-testid="logo"
                className="logo"
              >
                <BSMLogo />
              </div>
            </div>
            <ProfileCard signOutClickHandler={() => signOutClickHandler()} />
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
});

export default HeaderComponent;
