import _ from "lodash";
import Ajv from "ajv";
import union from "lodash/union";
import { setValueByRecursion } from "../../helpers";

let ajv = new Ajv({ allErrors: true, strict: false, coerceTypes: true });

export function isObject(thing) {
  if (typeof File !== "undefined" && thing instanceof File) {
    return false;
  }
  return typeof thing === "object" && thing !== null && !Array.isArray(thing);
}

/* Gets the type of a given schema. */
export function getSchemaType(schema) {
  let { type } = schema;

  if (!type && schema.const) {
    return guessType(schema.const);
  }

  if (!type && schema.enum) {
    return "string";
  }

  if (!type && (schema.properties || schema.additionalProperties)) {
    return "object";
  }

  if (type instanceof Array && type.length === 2 && type.includes("null")) {
    return type.find((typ) => typ !== "null");
  }

  return type;
}
// In the case where we have to implicitly create a schema, it is useful to know what type to use
//  based on the data we are defining
export const guessType = function guessType(value) {
  if (Array.isArray(value)) {
    return "array";
  } else if (typeof value === "string") {
    return "string";
  } else if (value == null) {
    return "null";
  } else if (typeof value === "boolean") {
    return "boolean";
  } else if (!isNaN(value)) {
    return "number";
  } else if (typeof value === "object") {
    return "object";
  }
  // Default to string if we can't figure it out
  return "string";
};

/**
 *
 * @param schema
 * @param data
 * @returns {boolean|*}
 * reference : https://github.com/rjsf-team/react-jsonschema-form/blob/07afa978f4409426b3509129988608ec5421310e/packages/core/src/validate.js#L301
 */
export const isValid = (schema, data) => {
  const validate = ajv.compile(schema);
  return validate(data);
};

// Recursively merge deeply nested schemas.
// The difference between mergeSchemas and mergeObjects
// is that mergeSchemas only concats arrays for
// values under the "required" keyword, and when it does,
// it doesn't include duplicate values.
export function mergeSchemas(obj1, obj2) {
  var obj = Object.assign({}, obj1); // Prevent mutation of source object.
  return Object.keys(obj2).reduce((acc, key) => {
    const left = obj1 ? obj1[key] : {},
      right = obj2[key];
    if (obj1 && obj1.hasOwnProperty(key) && isObject(right)) {
      acc[key] = mergeSchemas(left, right);
    } else if (
      obj1 &&
      obj2 &&
      (getSchemaType(obj1) === "object" || getSchemaType(obj2) === "object") &&
      key === "required" &&
      Array.isArray(left) &&
      Array.isArray(right)
    ) {
      // Don't include duplicate values when merging
      // "required" fields.
      acc[key] = union(left, right);
    } else {
      acc[key] = right;
    }
    return acc;
  }, obj);
}

/**
 * Similar to mergeSchemas , except in the case of duplicate keys right obj value is taken
 * @param obj1
 * @param obj2
 * @returns {string}
 */
export function mergeExclusiveRight(obj1, obj2) {
  var obj = Object.assign({}, obj2); // Prevent mutation of source object.
  return Object.keys(obj1).reduce((acc, key) => {
    const left = obj1 ? obj1[key] : {},
      right = obj2[key];
    if (obj2 && obj2.hasOwnProperty(key) && isObject(left)) {
      acc[key] = mergeExclusiveRight(right, left);
    } else if (
      obj1 &&
      obj2 &&
      (getSchemaType(obj1) === "object" || getSchemaType(obj2) === "object") &&
      key === "required" &&
      Array.isArray(right) &&
      Array.isArray(left)
    ) {
      // Don't include duplicate values when merging
      // "required" fields.
      acc[key] = union(right, left);
    } else {
      acc[key] = left;
    }
    return acc;
  }, obj);
}
export const manageDependentRequired = (
  jsonData,
  dependentRequired,
  pathToUpdateIn,
  event
) => {
  if (dependentRequired === undefined) {
    return jsonData;
  }
  let valToUpdate = [];
  pathToUpdateIn.push("required");
  let dependencySet = new Set(dependentRequired);
  let initialSet = new Set(_.get(jsonData, pathToUpdateIn));
  if (event.target.value.length === 0) {
    let difference = new Set(
      [...initialSet].filter((x) => !dependencySet.has(x))
    );
    valToUpdate = [...difference];
  } else {
    valToUpdate = [...dependentRequired];
  }

  jsonData = setValueByRecursion(
    jsonData,
    pathToUpdateIn,
    (i) => valToUpdate,
    []
  );
  return jsonData;
};

export const manageDependentSchemas = (
  jsonData,
  dependentSchemas,
  pathToUpdateIn,
  item,
  event
) => {
  if (dependentSchemas === undefined) {
    return jsonData;
  }
  let valToUpdate = _.get(
    jsonData,
    item?.path?.slice(0, item?.path?.length - 1)
  );

  pathToUpdateIn.push("properties");
  for (const [variable_key, variable_value] of Object.entries(
    dependentSchemas?.properties
  )) {
    if (event.target.value.length === 0) delete valToUpdate[variable_key];
    else {
      valToUpdate[variable_key] = variable_value;
    }
  }
  jsonData = setValueByRecursion(
    jsonData,
    pathToUpdateIn,
    (i) => valToUpdate,
    []
  );
  return jsonData;
};
/**
 * https://github.com/rjsf-team/react-jsonschema-form/blob/07afa978f4409426b3509129988608ec5421310e/packages/core/src/utils.js#L685
 * @param formData
 * @param schema
 *
 */
export const manageIfThenElse = (formData, schema) => {
  let schemaCopy = _.cloneDeep(schema);
  let { if: expression, then, else: otherwise } = schemaCopy;
  formData = formData ? formData : {};
  const condition = isValid(expression, formData);

  const conditionalSchema = condition ? then : otherwise;
  if (conditionalSchema) {
    schemaCopy = mergeSchemas(schemaCopy, conditionalSchema);
  }
  if (condition && otherwise) {
    schemaCopy.properties = removeProperties(
      schemaCopy.properties,
      otherwise.properties
    );
  } else if (!condition && then !== undefined) {
    schemaCopy.properties = removeProperties(
      schemaCopy.properties,
      then.properties
    );
  }
  return schemaCopy;
};

function storePropertiesInThenBlock(schema, dummyVariable) {
  // Check if the schema has a "then" block
  if (schema.hasOwnProperty("then")) {
    const thenBlock = schema.then.properties;
    // Iterate through properties in the "then" block
    for (const property in thenBlock) {
      if (thenBlock.hasOwnProperty(property)) {
        // Store property in the dummy variable
        dummyVariable[property] = thenBlock[property];
      }
    }
  }
  // Recursively check nested schemas
  for (const key in schema) {
    if (typeof schema[key] === "object" && key !== "then") {
      storePropertiesInThenBlock(schema[key], dummyVariable);
    }
  }
}

export const removeProperties = (properties, propertiesToRemove) => {
  const recursiveRemove = (obj1, obj2) => {
    const dummy = {};
    let resultingObject = _.cloneDeep(obj1);
    for (const [key] of Object.entries(obj2)) {
      if (obj1[key] === undefined) {
        continue;
      }
      if (guessType(obj2[key]) === "object") {
        resultingObject[key] = recursiveRemove(obj1[key], obj2[key]);
        if (_.isEmpty(resultingObject[key])) {
          delete resultingObject[key];
        }
      } else if (obj1[key] === obj2[key]) {
        delete resultingObject[key];
      }
    }
    storePropertiesInThenBlock(propertiesToRemove, dummy);
    const commonProperties = Object.keys(resultingObject).filter(key => dummy.hasOwnProperty(key));
    commonProperties.forEach(key => {
        if (resultingObject[key].hasOwnProperty("type")) {
            delete resultingObject[key].type;
            // Check if the property has nested properties
            const nestedProperties = Object.keys(resultingObject[key].properties || {});
            // If there are nested properties, remove the 'type' property from them
            if (nestedProperties.length > 0) {
                nestedProperties.forEach(nestedKey => {
                    if (resultingObject[key].properties[nestedKey].hasOwnProperty("type")) {
                        delete resultingObject[key].properties[nestedKey].type;
                    }
                });
            }
        }
    });
    return resultingObject;
  };

  let merged = mergeExclusiveRight(propertiesToRemove, properties);
  properties = recursiveRemove(merged, propertiesToRemove);
  return properties;
};
export const createDefaultValueArray = (object_path) => {
  let defaultValueArray = [];
  for (let i = 0; i < object_path.length; i++) {
    if (i === object_path.length - 1) {
      // the last element is either integer, or string or boolean
      defaultValueArray.push("");
    } else if (isNaN(object_path[i + 1])) {
      defaultValueArray.push({});
    } else {
      // if the next element in object_path[i+1] is integer we assume that it is an array element
      defaultValueArray.push([{}]);
    }
  }
  return defaultValueArray;
};

export const booleanToString = (boolVal) => {
  return boolVal ? "Yes" : "No";
};

export const generateObjectPath = (schemaPath) => {
  return schemaPath.filter((x) => {
    let e = ["properties", "items"];
    return !e.includes(x);
  });
};

/**
 * Gets the fields which are hidden due to changed schema (updatedSchema) and resets the state
 * correspoding to them
 *
 * @param updatedSchema
 * @param oldSchema
 * @param item
 * @param componentState
 * @param accumulator
 * @returns {*}
 */
export const getHiddenStatePaths=(updatedSchema, oldSchema,item,componentState,accumulator) =>{
  if(_.isEqual(updatedSchema,oldSchema)){
    return accumulator;
  }
  _.transform(oldSchema, (result,value, key) => {
    if (!_.isEqual(value, updatedSchema[key])) {
      if(_.isPlainObject(value) && _.isPlainObject(updatedSchema[key]) ){
        getHiddenStatePaths(updatedSchema[key], value,item,componentState,accumulator)
      }
      if(!["object","array"].includes(value.type) &&value.path && value.path!==item.path){
        accumulator.push(value.path);
      }
    }
    return result;
  },accumulator);
  return accumulator;
}
/**
 *
 * @param updatedSchema
 * @param oldSchema
 * @param stateOfArrayElem
 * @param accumulator
 */
export const getHiddenStatePathsForArray=(updatedSchema,oldSchema,stateOfArrayElem,accumulator,pathArr)=>{

  if(_.isEqual(updatedSchema,oldSchema)){
    return accumulator;
  }
  _.transform(oldSchema, (result,value, key) => {
    if(["then","if","else","allOf","anyOf","dependentRequired",
      "dependentsSchemas","view","type","title"].includes(key)){
      return;
    }
    if(_.isEqual(value,updatedSchema[key])){
      return ;
    }
    if(updatedSchema[key]===undefined){
      accumulator.push([...pathArr,key]);
    }
    else{
      if(_.isPlainObject(value) && _.isPlainObject(updatedSchema[key]) ){
          getHiddenStatePathsForArray(updatedSchema[key], value,stateOfArrayElem,accumulator,[...pathArr,key])
      }
      if(!["object","array"].includes(value.type) && key!=="properties"){
        accumulator.push([...pathArr,key]);
      }
    }
  },accumulator);
  return accumulator;
}
