import React from "react";
import "./Footer.css";
import bsmLogo from "../../assets/bsmLogo.png";
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import LocalPhoneRoundedIcon from '@mui/icons-material/LocalPhoneRounded';
import MailOutlineRoundedIcon from '@mui/icons-material/MailOutlineRounded';
import CropSquareRoundedIcon from '@mui/icons-material/CropSquareRounded';
import PublicRoundedIcon from '@mui/icons-material/PublicRounded';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const Footer = () => {
    return (
        <footer className="main1">
            <div className="c-prefooter">
                <div className="container1">
                    <div className="c-last">
                        <ul className="c-socials">
                            <li>
                                <img src={bsmLogo} alt="My Image" width="60px" height="60px" />
                            </li>
                            <li>
                                <h3 className="font-uppercase">
                                    BOND STREET MORTGAGE
                                </h3>
                                <p className="c-text">
                                    OUR WORD IS OUR BOND
                                </p>
                            </li>
                        </ul>

                    </div>
                    <div className="row">
                        <div className="col-md-3">
                            <div className="c-container c-first">
                                <ul className="c-links">
                                    <li>
                                        <a href="https://www.bondstreetloans.com/#home" target="_blank">
                                            <p className="font-link">Home</p>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.bondstreetloans.com/#about" target="_blank">
                                            <p className="font-link">About</p>
                                        </a>
                                    </li>
                                    <li><a href="https://www.bondstreetloans.com/components/resources-and-tools.html" target="_blank">
                                        <p class="font-link">Resource And Tools</p>
                                    </a></li>
                                    <li><a href="https://www.bondstreetloans.com/components/mortgage-types.html" target="_blank">
                                        <p class="font-link">Mortgage Types</p>
                                    </a></li>
                                    <li><a href="https://www.bondstreetloans.com/#reviews" target="_blank">
                                        <p class="font-link">Reviews</p>
                                    </a></li>
                                    <li><a href="https://www.bondstreetloans.com/blog/" target="_blank">
                                        <p class="font-link">Blogs</p>
                                    </a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="c-container c-first">
                                <ul class="c-links">
                                    <li><a href="https://www.bondstreetloans.com/short-app/" target="_blank">
                                        <p class="font-link">Explore Loan Options</p>
                                    </a></li>
                                    <li><a href="https://www.bondstreetloans.com/components/forms/buy-a-home.html" target="_blank">
                                        <p class="font-link">Buy-A-Home</p>
                                    </a></li>
                                    <li><a href="https://www.bondstreetmortgageq.com/?borrowerportal&siteid=2191488867"
                                        target="_blank">
                                        <p class="font-link">Loan Portal</p>
                                    </a></li>
                                    <li><a href="https://www.bondstreetloans.com/components/resources-and-tools.html#learning-center"
                                        target="_blank">
                                        <p class="font-link">Learning Center</p>
                                    </a></li>
                                    <li><a href="https://www.bondstreetloans.com/components/resources-and-tools.html#FAQs" target="_blank">
                                        <p class="font-link">FAQ's</p>
                                    </a></li>
                                    <li><a href="https://www.bondstreetloans.com/components/resources-and-tools.html"
                                        target="_blank">
                                        <p class="font-link">Calculator</p>
                                    </a></li>
                                </ul>
                            </div>
                        </div>

                        <div class="col-md-3">
                            <div class="c-container c-first">
                                <ul class="c-links">
                                    <li><a href="https://www.bondstreetloans.com/components/forms/refinance.html" target="_blank">
                                        <p class="font-link">Refinance</p>
                                    </a></li>
                                    <li><a href="https://bondstreetmortgage.com/valoanrequest/" target="_blank">
                                        <p class="font-link">Request VA Loan</p>
                                    </a></li>
                                    <li><a href="https://www.bondstreetloans.com/components/forms/buy-a-home.html" target="_blank">
                                        <p class="font-link">Request FHA Loan</p>
                                    </a></li>
                                    <li><a href="https://www.bondstreetloans.com/components/forms/rehab.html" target="_blank">
                                        <p class="font-link">Rehab Loan Form</p>
                                    </a></li>
                                    <li><a href="https://bondstreetmortgage.com/instantquotetool/" target="_blank">
                                        <p class="font-link">Get Instant Quote</p>
                                    </a></li>
                                    <li><a href="https://www.bondstreetmortgageq.com/?loanapp&siteid=2191488867&lar=admin&workFlowId=72056"
                                        target="_blank">
                                        <p class="font-link"> Apply Now </p>
                                    </a></li>


                                </ul>
                            </div>
                        </div>



                        <div class="col-md-3">
                            <div class="c-container c-last">
                                <div class="c-content-title-1">
                                    <h3 class="c-font-uppercase c-font-bold c-font-white">FIND US</h3>
                                    <div class="c-line-left hide"></div>

                                </div>
                                <ul className="c-socials">
                                    <li style={{ padding: '0px', width: '50px' }}>
                                        <a href="https://facebook.com/BondStreetMortgage/" target="_blank">
                                            <img src={require('../../assets/facebook.png')} alt="Facebook" width="70%" />
                                        </a>
                                    </li>
                                    <li style={{ padding: '0px', width: '50px' }}>
                                        <a href="https://twitter.com/BondStreetMtg" target="_blank">
                                            <img src={require('../../assets/twitter.png')} alt="Twitter" width="70%" />
                                        </a>
                                    </li>
                                    <li style={{ padding: '0px', width: '50px' }}>
                                        <a href="https://www.youtube.com/user/BondStreetMortgage" target="_blank">
                                            <img src={require('../../assets/you-tube.png')} alt="YouTube" width="70%" />
                                        </a>
                                    </li>
                                    <li style={{ padding: '0px', width: '50px' }}>
                                        <a href="https://instagram.com/bondstreetmortgage?igshid=3nlq9r6l4zo2" target="_blank">
                                            <img src={require('../../assets/instagram.png')} alt="Instagram" width="70%" />
                                        </a>
                                    </li>
                                    <li style={{ padding: '0px', width: '50px' }}>
                                        <a href="https://www.linkedin.com/company/bond-street-mortgage-llc" target="_blank">
                                            <img src={require('../../assets/linkedin.png')} alt="LinkedIn" width="70%" />
                                        </a>
                                    </li>
                                </ul>
                                <ul className="c-address">
                                    <li className="font-link">
                                        <a href="http://maps.google.com/maps?q=115+W Century+Road+Suite 115,+Paramus,+NJ+07652" target="_blank" className="font-link">
                                        <LocationOnOutlinedIcon className="c-theme-font" fontSize="small"/>&nbsp;
                                            Paramus, NJ<br />
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;115 West Century Road Suite 115 <br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Paramus, NJ 07652
                                        </a>
                        
                                    </li>
                                    <li className="font-link">
                                        <a href="tel:+18885885522" draggable="false" className="font-link">
                                            <LocalPhoneRoundedIcon className="c-theme-font" fontSize="small"/>&nbsp;&nbsp;
                                            <c2d_cc_5d8617261cdf485da5df67e34f5f63ef data-num="(888) 588-5522" id="c2d_1">(888) 588-5522</c2d_cc_5d8617261cdf485da5df67e34f5f63ef>
                                        </a>
                                    </li>
                                    <li className="font-link">
                                        <MailOutlineRoundedIcon className="c-theme-font" fontSize="small"/>&nbsp;&nbsp;
                                        <a className="font-link" href="mailto:loaninfo@bondstreetloans.com">loaninfo@bondstreetloans.com</a>
                                    </li>
                                    <li className="font-link">
                                        <CropSquareRoundedIcon className="c-theme-font" fontSize="small"/>&nbsp;&nbsp;
                                        <a className="font-link" href="https://www.nmlsconsumeraccess.org/TuringTestPage.aspx?ReturnUrl=/EntityDetails.aspx/COMPANY/191351" target="_blank">NMLS # 191351</a>
                                    </li>
                                    <li className="font-link">
                                        <PublicRoundedIcon className="c-theme-font" fontSize="small"/>&nbsp;&nbsp;
                                        <a className="font-link" href="https://www.bondstreetloans.com/components/Privacy.html" target="_blank">Privacy Policy</a>
                                    </li>
                                    <li className="font-link">
                                        <InfoOutlinedIcon className="c-theme-font" fontSize="small"/>&nbsp;&nbsp;
                                        <a className="font-link" href="https://www.bondstreetloans.com/components/License.html" target="_blank">Licensing Info</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        
                    </div>
                    <div className="row">
                        <div className="col-md-3">
                            <div className="c-first">
                                <ul className="c-img">
                                    <li>
                                        <img src={require('../../assets/el.png')} alt="EL Logo" width="60px" height="60px" />
                                    </li>
                                    <li>
                                        <img src={require('../../assets/bbb.png')} alt="BBB Logo" width="60px" height="60px" />
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
