export const ethinity = [
  "hispanicOrLatino",
  "mexican",
  "puertoRican",
  "cuban",
  "NotHispanicOrLatino",
  "otherHispanicOrLatino",
];
 export const race = [
  "AmericanIndianOrAlaskaNative",
  "Asian",
  "AsianIndian",
  "Chinese",
  "Filipino",
  "Japanese",
  "Korean",
  "Vietnamese",
  "OtherAsian",
  "blackOrAfrican",
  "NativeHawaii",
  "Samoan",
  "GuamanianOrChamarro",
  "OtherPacificIslander",
  "white",
];
 export const sex = [
    "female",
    "male",
    "informationNotProvided"
]
