import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "../pages/Login";
import LoanApplication from "../pages/loan_app";
import Dashboard from "../pages/loan_app/dashboard/dashboard";
import LoanDashboard from "../pages/LoanDashboard";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
  withMsal,
} from "@azure/msal-react";
import SAMLSSO from "../components/saml_sso";
import { BASE_PATH } from "../constants";
import { getAccessToken } from "../helpers";
import SessionTimeout from "../components/session/SessionTimeout";
import { extendSession } from "../helpers/loginHelpers";
import LoginRedirect from "../pages/Login/loginRedirect";
import Notfound from "../components/notfound";
import RefinanceHome from "../refinance";
import coapplicant from "../pages/loan_app/coapplicant/coapplicant";

const MainRoutes = (props) => {
  const { instance } = useMsal();
  let [token, setToken] = useState(getAccessToken());
  const urlParams = new URLSearchParams(window.location.search);

  useEffect(() => {
    const checkSizeAndRedirect = () => {
      const screenWidth = window.innerWidth;
 
      const thresholdWidth = 768;
      if (screenWidth < thresholdWidth) {
        const queryParams = window.location.search;
        window.location.href = `${process.env.REACT_APP_SERVER_URL}/cx-pwa/${queryParams}`;
      }
    };
    const excludedRoutes = [process.env.REACT_APP_SAML_URL];
    const currentPath = window.location.pathname;
 
    if (!excludedRoutes.includes(currentPath)) {
      checkSizeAndRedirect();
      window.addEventListener('resize', checkSizeAndRedirect);
    }
 
    return () => {
      window.removeEventListener('resize', checkSizeAndRedirect);
    };
  }, [window.location.pathname]);

  useEffect(() => {
    if(urlParams.has('lar')) {
      window.localStorage.setItem('lar', urlParams.get('lar'));
    }
  }, [urlParams]);

  function signOutClickHandler() {
    const logoutRequest = {
      account: instance.getAccountByHomeId(
        window.localStorage.getItem("home_account_id")
      ),
      postLogoutRedirectUri: BASE_PATH,
    };
    const getLarFromLocalStorage = localStorage.getItem("lar");
    window.localStorage.clear();
    if(getLarFromLocalStorage !== null) {
      window.localStorage.setItem('lar', getLarFromLocalStorage);
    }
    window.sessionStorage.clear();
    setToken(null);
    instance.logoutRedirect(logoutRequest);
  }

  return (
    <div>
      {token && (
        <SessionTimeout
          logout={() => signOutClickHandler()}
          extendSession={() =>
            extendSession({ props, setToken: (token) => setToken(token) })
          }
          setToken={(token) => setToken(token)}
        />
      )}

      <Router>
        <Routes>
          <Route
            path={process.env.REACT_APP_LOAN_APPLN_URL}
            element={
              <div>
                <AuthenticatedTemplate>
                  <LoanApplication />
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                  <LoginRedirect
                    setToken={(token) => {
                      setToken(token);
                    }}
                  />
                </UnauthenticatedTemplate>
              </div>
            }
          />
          <Route
            path={process.env.REACT_APP_DASHBOARD_URL}
            element={
              <div>
                <AuthenticatedTemplate>
                  <LoanDashboard />
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                  <LoginRedirect
                    setToken={(token) => {
                      setToken(token);
                    }}
                  />
                </UnauthenticatedTemplate>
              </div>
            }
          />

          <Route
            path={process.env.REACT_APP_SAML_URL}
            element={
              <div>
                <AuthenticatedTemplate>
                  <SAMLSSO />
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                  <LoginRedirect
                    call={(token) => {
                      setToken(token);
                    }}
                  />
                </UnauthenticatedTemplate>
              </div>
            }
          />

          <Route
            path={process.env.REACT_APP_BASE_URL}
            element={
              <div>
                <AuthenticatedTemplate>
                  <Dashboard />
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                  <LoginRedirect
                    call={(token) => {
                      setToken(token);
                    }}
                  />
                </UnauthenticatedTemplate>
              </div>
            }
          />

          <Route
            path={process.env.REACT_APP_REFINANCE_URL}
            element={<RefinanceHome />}
          />

          <Route path={`*`} element={<Notfound />} />
        </Routes>
      </Router>
    </div>
  );
};
export default withMsal(MainRoutes);
