import {
  SAVE_LOAN,
  GET_LOANS,
  COPY_STATE_TO_REDUX,
  COPY_SCHEMA,
  COPY_RAW_COMPONENT_STATE,
  UPDATE_ERRORS_DICTIONARY,
  GET_LOAN_DATA,
  GET_DOC_REQUEST,
  SUBMIT_LOAN,
  SHOW_LOADER,
  RESET_REDUX,
  SAML_RESP,
  ACTIVE_STEP,
  UPLOAD_DOCUMENT,
  STEPPER_BACK,
  SYNC_EOS,
  GET_LOAN_LOCK_STATUS,
} from "../constants";
const initialState = {
  saveLoanDataRes: {},
  getLoansDataRes: {},
  componentState: {},
  componentSchema: {},
  errorsDictionary: {},
  getLoanDataWithId: {},
  getDocRequests: {},
  submitedLoanRes: {},
  toggleLoader: false,
  apiErrResponse: {},
  uploadDocumentApiRes: {},
  activeStep: 0,
  stepperBackStatus: false,
  getLoanLockStatusRes: {},
};

export let userReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_LOAN:
      if (action.payload?.status === 200) {
        return {
          ...state,
          saveLoanDataRes: action.payload,
          toggleLoader: false,
        };
      } else {
        return {
          ...state,
          apiErrResponse: parseErrorData(action.payload),
          toggleLoader: false,
        };
      }

    case SUBMIT_LOAN:
      if (action.payload?.status === 200) {
        return {
          ...state,
          submitedLoanRes: action.payload,
          toggleLoader: false,
        };
      } else {
        return {
          ...state,
          apiErrResponse: parseErrorData(action.payload),
          toggleLoader: false,
        };
      }
    case UPLOAD_DOCUMENT:
      if (action.payload?.status === 200) {
        return {
          ...state,
          uploadDocumentApiRes: action.payload,
          toggleLoader: false,
        };
      } else {
        return {
          ...state,
          apiErrResponse: parseErrorData(action.payload),
          toggleLoader: false,
        };
      }
    case GET_LOANS:
      return {
        ...state,
        getLoansDataRes: action.payload,
        submitedLoanRes: {},
        saveLoanDataRes: {},
        toggleLoader: false,
        apiErrResponse: {},
      };
    case GET_LOAN_DATA:
      return {
        ...state,
        getLoanDataWithId: action.payload,
        submitedLoanRes: {},
        saveLoanDataRes: {},
        apiErrResponse: {},
      };
    case GET_DOC_REQUEST:
      return {
        ...state,
        getDocRequests: action.payload,
        submitedLoanRes: {},
        saveLoanDataRes: {},
        apiErrResponse: {},
      };
    case GET_LOAN_LOCK_STATUS:
      return {
        ...state,
        getLoanLockStatusRes: action.payload,
        submitedLoanRes: {},
        saveLoanDataRes: {},
        apiErrResponse: {},
      };
    case COPY_STATE_TO_REDUX:
      return {
        ...state,
        componentState: {
          ...state.componentState,
          ...action.payload,
        },
      };
    case COPY_SCHEMA:
      return {
        ...state,
        componentSchema: {
          ...state.componentSchema,
          [action["payload"]["key"]]: action.payload.state,
        },
      };

    case COPY_RAW_COMPONENT_STATE:
      return {
        ...state,
        rawComponentState: {
          ...state.rawComponentState,
          [action["payload"]["key"]]: action.payload.state,
        },
      };
    case UPDATE_ERRORS_DICTIONARY:
      return {
        ...state,
        errorsDictionary: action.payload,
      };
    case RESET_REDUX:
      return {
        ...initialState,
        toggleLoader: state.toggleLoader,
      };
    case SHOW_LOADER:
      return {
        ...state,
        toggleLoader: action.payload,
      };
    case SAML_RESP:
      return {
        ...state,
        samlResponse: action.payload,
      };
    case ACTIVE_STEP:
      return {
        ...state,
        activeStep: action.payload,
      };
    case STEPPER_BACK:
      return {
        ...state,
        stepperBackStatus: action.payload,
      };
    case SYNC_EOS:
      if (action.payload?.status === 200) {
        return {
          ...state,
          toggleLoader: false,
        };
      } else {
        return {
          ...state,
          apiErrResponse: parseErrorData(action.payload),
          toggleLoader: false,
        };
      }

    default:
      return state;
  }
};

/**
 * Errors from backend are returned in three formats
 * data.error , data.errors and data.details. Merging all three to form data.error
 * @param apiResponse
 * @returns {*}
 */
const parseErrorData = (apiResponse) => {
  let errorMessage = "";
  if (apiResponse?.data?.details !== undefined) {
    apiResponse.data["error"] = apiResponse?.data?.details;
  }
  if (apiResponse?.data?.errors !== undefined) {
    apiResponse?.data?.errors?.map(
      (error) => (errorMessage = errorMessage + <br /> + error?.summary)
    );
    apiResponse.data["error"] = errorMessage;
  }

  return apiResponse;
};
