import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import { grey } from '@mui/material/colors';

const ProgressWithLabel = (props) => {
  return (
    <Box sx={{
      display: 'flex',
      alignItems: 'center',
      width: '800px', // Adjust if you want a different width or set to the width of the container
      gap: '20px', // Adjust gap if necessary
    }}>
      <Box sx={{ width: '600px', mr: 1 }}>
        <LinearProgress variant="determinate" value={props.value} sx={{
          height: '10px', // Adjust the height to match your design
          borderRadius: '7px', // Adjust the border-radius to match your design
          '& .MuiLinearProgress-barColorPrimary': {
            backgroundColor: '#00634a', // Adjust the color to match your design
          },
          backgroundColor: '#E0EBE9', // Adjust the background color to match your design
        }} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        {/* Uncomment the next line if you want to show the progress value */}
        {/* <Typography variant="body2" color="text.secondary">{`${Math.round(props.value)}%`}</Typography> */}
      </Box>
    </Box>
  );
}

export default ProgressWithLabel;
