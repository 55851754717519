import {
  InteractionStatus,
  PublicClientApplication,
} from "@azure/msal-browser";
import { MsalProvider, useMsal } from "@azure/msal-react";
import React, { useState, useEffect } from "react";
import { loginRequest, msalConfig } from "./authConfig";
import BsmLogo from "../../assets/bsmLogo.png";
import { setLocalStorageValues } from "../../helpers/loginHelpers";
import { BASE_PATH, ENV } from "../../constants/";
import moment from "moment";
import packageJson from '../../../package.json';
import CarouselComponent from "../../components/Corousel";

const msalInstance = new PublicClientApplication(msalConfig);
function LoginRedirect(props) {
  const MSAL = useMsal();
  const accessTokenRequest = {
    ...loginRequest,
    account: MSAL.accounts,
  };
  // const [isLogin, setIsLogin] = useState(false);
  /*1) fetches the BuildDate from meta.json and packageJson
    2)Login Activities
  */
    useEffect(() => {
      if (MSAL.inProgress === InteractionStatus.None) {

        if( accessTokenRequest.account.length === 0) {
           MSAL.instance.acquireTokenRedirect(accessTokenRequest)};
          
        }
        
        if (accessTokenRequest.account.length > 0) {
          MSAL.instance
            .acquireTokenSilent(accessTokenRequest)
            .then((accessTokenResponse) => {
              setLocalStorageValues(accessTokenResponse);
              props.call(accessTokenResponse.idToken);
            });
    
        }
    }, []); 

  const fetchingLatestBuildDate = () => {
    if (ENV !== 'local') {
      fetch(BASE_PATH + "/meta.json", { cache: "no-store" }).then((r) => r.json()).then((meta) => {
        const latestVersionDate = meta.buildDate;
        const currentVersionDate = packageJson.buildDate;
        const shouldForceRefresh = buildDateGreaterThan(
          latestVersionDate,
          currentVersionDate
        );
        if (shouldForceRefresh) {
          refreshCacheAndReload();
        }
      }
      );
    }
    else {
      console.log("no need to fetch meta.json");
    }
  }

  const buildDateGreaterThan = (latestDate, currentDate) => {
    const momLatestDateTime = moment(latestDate);
    const momCurrentDateTime = moment(currentDate);
    if (momLatestDateTime.isAfter(momCurrentDateTime))
      return true;
    return false;
  };

  const refreshCacheAndReload = () => {
    if (caches) {
      // Service worker cache should be cleared with caches.delete()
      caches.keys().then((names) => {
        for (const name of names) {
          caches.delete(name);
        }
      });
    }
    // delete browser cache and hard reload
    window.location.reload(true);
  };

  // const authHandler = () => {
  //   MSAL.instance.acquireTokenRedirect(accessTokenRequest);
  // };

  return null;
}

export default LoginRedirect;
