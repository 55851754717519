import { CardContent, Divider, Tooltip, Typography, Box, Button } from "@mui/material";
import React from "react";


const CoApplicantSummary = (props) => {
    return (
        <div>
            <div className="edit">
                <h3>
                    Co Applicant Details{" "}
                    {`(${props?.coapplicants?.length})`}
                </h3>
                {
                    props?.edit &&

                    <Button
                        onClick={props?.onEditCoApplicantsFunc}
                        style={{
                            backgroundColor: "#00634a",
                            color: "white",
                            textTransform: "none",
                            paddingBottom: "3px",
                        }}
                    >
                        Edit
                    </Button>
                }

            </div>
            <div className="coapplicants" onClick={(e) => { e.preventDefault() }}>
                {props?.coapplicants?.map(
                    (item, index) => (
                        <Box
                            sx={{
                                minWidth: 290,
                                padding: 0,
                                border: "1px solid #ccc",
                                borderRadius: 2,
                                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                                margin: 2,
                            }}
                        >
                            <CardContent
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    padding: 2,
                                }}
                            >
                                <Typography
                                    variant="subtitle1"
                                    sx={{ fontWeight: "bold" }}
                                >
                                    Co-Applicant {index + 1}
                                </Typography>
                                <Typography
                                    style={{ color: "rgba(0, 0, 0, 0.5)" }}
                                >
                                    Borrower Name:{" "}
                                    {item.FirstName + " " + item.LastName}
                                </Typography>
                                <Divider sx={{ margin: "6px 0" }} />
                                <Typography>
                                    <span
                                        style={{
                                            display: "inline-block",
                                            textAlign: "left",
                                            color: "rgba(0, 0, 0, 0.5)",
                                        }}
                                    >
                                        Phone:
                                    </span>
                                    <span
                                        style={{
                                            display: "inline-block",
                                            textAlign: "right",
                                        }}
                                    >
                                        {item.Phone}
                                    </span>
                                </Typography>
                                <Typography style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center'
                                }}>
                                    <span
                                        style={{
                                            display: "inline-block",
                                            textAlign: "left",
                                            color: "rgba(0, 0, 0, 0.5)",
                                        }}
                                    >
                                        Email:
                                    </span>
                                    <Tooltip title={item.Email} arrow>
                                        <span
                                            style={{
                                                display: "inline-block",
                                                textAlign: "left",
                                                maxWidth: "290px",
                                                overflow: "hidden",
                                                whiteSpace: "nowrap",
                                                textOverflow: "ellipsis"
                                            }}
                                        >
                                            &nbsp;{item.Email}
                                        </span>
                                    </Tooltip>
                                </Typography>
                            </CardContent>
                        </Box>
                    )
                )}
            </div>
        </div>
    )
}
export default CoApplicantSummary;