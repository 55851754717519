import './Popup.css';

const UserValidationPopup = ({ setOpenPopup, handleSubmit, setSsn }) => {
    return (
        <div className="popUp">
            <div className="popUpContainer">
                <div className="titleCloseBtn">
                <h2 className='code'>Enter code</h2>
                    <button
                        onClick={() => {
                            setOpenPopup(false);
                        }}
                    >
                        X
                    </button>
                </div>
                <div className='popUpContent'>
                    <h2>Please provide your last 4 digits of ssn to verify</h2>
                    <input type="text" onChange={(e) => setSsn(e.target.value)} className='textbox' placeholder='eg:9999' />
                </div>
                <div className='footer'>
                    <button className='verify-button' onClick={handleSubmit}>Verify</button>
                </div>
            </div>
        </div>
    );
};

export default UserValidationPopup;