import { Alert, Slide, Snackbar } from "@mui/material";
import React, { useState } from "react";


export const withSnackbar = (WrappedComponent) => {
  return (props) => {
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [severity, setSeverity] = useState("success");
    const [duration,setDuration] = useState(2000)

    const showMessage = (toastMessage, toastSeverity,toastDuration) => {
      setMessage(toastMessage);
      setSeverity(toastSeverity);
      setOpen(true);
      setDuration(toastDuration);
    };
    const handleClose = () => {
      setOpen(false);
    };
    return (
      <>
        <WrappedComponent {...props} showToast={showMessage} />
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          autoHideDuration={duration}
          open={open}
          onClose={() => handleClose()}
          TransitionComponent={Slide}
        >
          <Alert variant="filled" onClose={handleClose} severity={severity}>
            {message}
          </Alert>
        </Snackbar>
      </>
    );
  };
};

