import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";

export function DeleteVerificationPopup(props) {
    const {openPopup} = props;
  return (
    <Dialog open={openPopup} maxWidth="md">
      <DialogContent>
        <span className="fileName">Are you sure, you want to delete it ?</span>
      </DialogContent>
      <DialogActions>
        <div className="save_next_buttons_div">
          <Button className="save_button" onClick={() => props.noFunc()}>
            No
          </Button>
          <Button className="save_button" onClick={() => props.yesFunc()}>
            Yes
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}
