import React, { useEffect } from "react";
import "./DocumentsManager.css";
import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import { RenderData as renderData } from "./renderData";
import { mapDispatchToProps, mapStateToProps } from "../../../helpers";
import { connect } from "react-redux";

const ViewUploadedDocuments = (props) => {
  const { file, documentsList, open, updateDocumentStatusFunc, handleOnClose } =
    props;
  let loanGuid = localStorage.getItem("loanGuid");

  const handleOnSubmit = () => {
    documentsList?.map((docObject, mainIndex) =>
      docObject?.map((docChildObject, childIndex) => {
        updateDocumentStatusFunc("PENDING", mainIndex, childIndex);
        props
          .uploadDocumentApi(loanGuid, docChildObject.id, docChildObject.data)
          .then((res) => {
            updateDocumentStatusFunc("SUCCESS", mainIndex, childIndex);
          })
          .catch((error) => {
            updateDocumentStatusFunc("ERROR", mainIndex, childIndex);
          });
      })
    );
  };
  return (
    <div>
      <Dialog
        open={open}
        maxWidth="lg"
        PaperProps={{
          style: { width: "100%" },
        }}
      >
        <DialogContent>
          {renderData({
            file: file,
            documentsList: documentsList,
            showUploadButton: false,
            showPreviewAndDltButtons: false,
            fileHnadler: () => void 0,
            openFile: () => void 0,
            deleteFile: () => void 0,
          })}
        </DialogContent>
        <DialogActions>
          <div className="save_next_buttons_div">
            <Button className="save_button" onClick={() => handleOnClose()}>
              Cancel
            </Button>
            <Button className="save_button" onClick={() => handleOnSubmit()}>
              Submit
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewUploadedDocuments);
export { ViewUploadedDocuments };
