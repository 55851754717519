import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import './Popup.css';

export default function ErrorPopup(props) {
    return (
        <div className='popUp'>
            <Stack sx={{ width: '50%' }} spacing={2}>
                <Alert severity="error" onClose={props.onErrorPopUpClose}>{props.error}</Alert>
            </Stack>
        </div>
    );
}

