import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles';

const CustomTextField = styled(TextField)(({ theme }) => ({
    '& label.Mui-focused': {
      color: '#BDBDBD',
    },
    '& .MuiOutlinedInput-input': {
      height: '25px', // Set your desired height
      color:'black',
      fontSize:'16px',
      padding:'10px 12px 10px 7px'
      
    },
    '& .MuiInputAdornment-root': {
      color: '#BDBDBD', // Adornment color
      marginRight: '0px', // Adjust this to set the spacing between the adornment and the text field
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#BDBDBD',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#BDBDBD',
        borderWidth: '1px',
      },
      '&:hover fieldset': {
        borderColor: '#BDBDBD',
        borderWidth: '1px',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#BDBDBD',
        borderWidth:'1px',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#BDBDBD',
        borderWidth: '1px',
      },
    },
    
    '&.Mui-focused fieldset legend': {
      display: 'none',
    },
    
    
    '& .MuiInputLabel-outlined': {
      position: 'absolute',
    top: -18, // Adjust the position as needed
    // Add background color same as your input's background
    backgroundColor: 'white',
    padding: '0 5px',
    // Make sure the label is always in "shrink" mode
    transform: 'translate(14px, 20px) scale(1)',
    left: 'calc(0% - 14px)',
    transformOrigin: 'top left',
    fontWeight: 'bold', // Keep label bold as per your requirement
    '&.MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.75)',
      borderColor:'#BDBDBD',
    },
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.75)', // Ensure the label stays in the same position when focused
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink, & .MuiInputLabel-outlined': {
      transform: 'translate(14px, -6px) scale(0.75)',
      left: 'calc(0% - 16px)', // Use the same left value as above
    },
    '& label': {
      fontWeight: '500', // Reset the weight if not focused
      fontSize: '20px', // Adjust font size for the unfocused state if needed
    },
    // If you want to change the color of the label text when not focused
    '& label.MuiInputLabel-root': {
      color: 'black', // default label color
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-marginDense': {
      transform: 'translate(14px, -6px) scale(0.75)',
    },
  }));

  export default CustomTextField;