import './UserCodeSubmission.css'
import { useState } from 'react';
import UserValidationPopup from '../popup/UserValidationPopup';
import RateCards from '../rateCards/RateCards';
import axios from 'axios';
import { API_URL } from '../../redux/constants';
import UserQuestionnaire from '../userQuestionnaire/UserQuestionnaire';
import ErrorPopup from '../popup/ErrorPopup';
import { useNavigate } from 'react-router-dom';
import Summary from '../summary/Summary';

const UserCodeSubmission = () => {
    const navigate = useNavigate();
    const [inputCode, setInputCode] = useState('');
    const [ssn, setSsn] = useState('');
    const [openPopUp, setOpenPopup] = useState(false);
    const [component, setComponent] = useState("Home");
    const [errorPopUp, setErrorPopUp] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [refinanceData, setRefinanceData] = useState({});

    const handleClick = async () => {
        try {
            setOpenPopup(true);
        }
        catch (error) {
            console.log(error)
        }
    }

    const handleSubmit = async () => {
        axios.post(`${API_URL}/customer-service/api/v1/refi/validate`,
            {
                "verificationCode": inputCode,
                "ssn": ssn
            },
            {
                headers: {
                    "Content-Type": "application/json"
                }
            }).then((response) => {
                if (response.status === 200) {
                    setOpenPopup(false);
                    setComponent("UserForm");
                }
            })
            .catch((error) => {
                setOpenPopup(false);
                setErrorPopUp(true);
                setErrorMessage("Please Enter Valid values for code and ssn.");
            });

    }

    const handleFormSubmit = (refinanceData) => {
        setRefinanceData(refinanceData);
        setComponent("RateCards");
    }

    const handleFormPreview = (refinanceData) => {
        setRefinanceData(refinanceData);
        setComponent("FormPreview");
    }

    const onErrorPopUpClose = () => {
        setErrorPopUp(false);
        navigate(process.env.REACT_APP_REFINANCE_URL);
    }

    const handleEdit = () => {
        setComponent("UserForm");
    }

    const getComponent = () => {
        if (component === "Home") {
            return (
                <div className="App-header">

                    <div>
                        
                        <div className='refi'>
                            <h1>Refinance Today</h1>
                            <p>Please submit the code that you have received in your email below:</p>
                        </div>
                        <div>
                            <input type="text" value={inputCode} onChange={(e) => setInputCode(e.target.value)} className='textbox' placeholder='Enter your code here' />
                            {errorMessage && <p className="error-message">{errorMessage}</p>}
                        </div>
                        <div>
                            <button className='submit-button' onClick={handleClick}>Submit</button>
                            {openPopUp && <UserValidationPopup setOpenPopup={setOpenPopup} handleSubmit={handleSubmit} setSsn={setSsn} />}
                            {errorPopUp && <ErrorPopup onErrorPopUpClose={onErrorPopUpClose} error={errorMessage} />}
                        </div>
                    </div>
                </div>

            );
        }
        else if (component === "UserForm") {
            return (
                <UserQuestionnaire handleFormPreview={handleFormPreview} userCode={inputCode} />
            );
        }
        else if (component === "FormPreview") {
            return (
                <Summary handleFormSubmit={handleFormSubmit} handleEdit={handleEdit} userCode={inputCode} refinanceData={refinanceData} />
            );
        }
        else if (component === "RateCards") {
            return (
                <RateCards handleEdit={handleEdit} refinanceData={refinanceData}></RateCards>
            );
        }
    }

    return (
        getComponent()
    );
};
export default UserCodeSubmission;
