import * as React from "react";
import Checkbox from "@mui/material/Checkbox";
import "./components.css";

export default function CheckboxComponent(props) {
  const { name, label, title, value, onChange, disabled } = props;
  return (
    <div>
      {label && <span className="titleClassName">{label}</span>}
      <Checkbox
        className=""
        style={{
          width: "fit-content",
          paddingLeft: "0px",
          paddingBottom: "10px",
          color: disabled ? "gray" : "#00634a",
        }}
        sx={{
          "& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root)": {
            fontSize: "18px",
            width: "fit-content",
          },
          "& .MuiSvgIcon-root + .MuiSvgIcon-root": {
            fontSize: "18px",
            width: "fit-content",
          },
        }}
        value={value}
        checked={value}
        onChange={(e) => onChange(e)}
        disabled={disabled}
      />
      <span
        className="checkboxLabelClassName"
        style={{ color: disabled ? "gray" : "black" }}
      >
        {name === "PrivacyPolicy" ? (
          <>
            I/We have read and accepted the terms of the{" "}
            <a
              target="_blank"
              href="https://www.bondstreetmortgageq.com/PrivacyPolicy.html"
            >
              {" "}
              Privacy Policy
            </a>
          </>
        ) : (
          title
        )}
      </span>
    </div>
  );
}
