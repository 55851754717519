import axios from "axios";
import { API_URL, SAVE_LOAN, SUBMIT_LOAN, UPLOAD_DOCUMENT, SYNC_EOS } from "../constants";
import {
  getAccessToken,
  getUserEmail,
  getTokenExpiryTime,
  mapStateToObject,
} from "../../helpers/index";
function getHeaders() {
  return {
    "Content-Type": "application/json",
    Authorization: `Bearer ${getAccessToken()}`,
    "x-useremail": getUserEmail(),
    "x-expires-in": getTokenExpiryTime(),
  };
}
export const saveLoanData = (payload) => (dispatch) => {
  axios
    .post(`${API_URL}/customer-service/api/v1/cx/save/`, payload, {
      headers: getHeaders(),
    })
    .then((response) => {
      dispatch({
        type: SAVE_LOAN,
        payload: response,
      });
    })
    .catch((error) => {
      dispatch({
        type: SAVE_LOAN,
        payload:
          error.message === "Network Error" ? error.message : error.response,
      });
    });
};
export const uploadDocumentApi = (uuid, id, data) => (dispatch) => {
  const formData =  new FormData();
  formData.append("file", data);
  // formData.append("loanOfficerEmail", )
  return new Promise ((resolve, reject) => {
  axios
    .post(
      `${API_URL}/docstore-service/api/v1/cx/loans/${uuid}/docSubmit/${id}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${getAccessToken()}`,
          "x-useremail": getUserEmail(),
          "x-expires-in": getTokenExpiryTime(),
        },
      }
    )
    .then((response) => {
      dispatch({
        type: UPLOAD_DOCUMENT,
        payload: response,
      });
      resolve(response);
    })
    .catch((error) => {
      dispatch({
        type: UPLOAD_DOCUMENT,
        payload:
          error.message === "Network Error" ? error.message : error.response,
      });
      reject(error.message === "Network Error" ? error.message : error.response)
    });
  })
};
export const submitLoan = (payload) => (dispatch) => {
  axios
    .post(`${API_URL}/customer-service/api/v1/cx/create/`, payload, {
      headers: getHeaders(),
    })
    .then((response) => {
      dispatch({
        type: SUBMIT_LOAN,
        payload: response,
      });
    })
    .catch((error) => {
      dispatch({
        type: SUBMIT_LOAN,
        payload:
          error.message === "Network Error" ? error.message : error.response,
      });
    });
};

export const coappsubmitLoan = (payload,id) => (dispatch) => {
  axios
    .patch(`${API_URL}/customer-service/api/v1/cx/update/loans/${id}`, payload, {
      headers: getHeaders(),
    })
    .then((response) => {
      dispatch({
        type: SUBMIT_LOAN,
        payload: response,
      });
    })
    .catch((error) => {
      dispatch({
        type: SUBMIT_LOAN,
        payload:
          error.message === "Network Error" ? error.message : error.response,
      });
    });
};

export const syncEncompassLoanToLocalDB = (loanGuid) => (dispatch) => {
  return new Promise ((resolve, reject) => {
    axios
    .post(`${API_URL}/customer-service/api/v1/cx/sync-eos/${loanGuid}`,'', {
      headers: getHeaders(),
    })
    .then((response) => {
      dispatch({
        type: SYNC_EOS,
        payload: response,
      });
      resolve(response)
    })
    .catch((error) => {
      dispatch({
        type: SYNC_EOS,
        payload:
          error.message === "Network Error" ? error.message : error.response,
      });
      reject(error)
    });
  })
};
