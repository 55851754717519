import React, { useEffect } from 'react';
import CustomTextField from './CustomTextField';
import './PersonalInformation.css';

const PersonalInformation = ({ answer, errors, setErrors, onChange }) => {
  const validateAnswer = (field, value) => {
    switch (field) {
      case 'firstName':
        if (!value || value.trim() === '') {
          return 'Field required';
        }
        break;
      case 'lastName':
        if (!value || value.trim() === '') {
          return 'Field required';
        }
        break;
      case 'phoneNumber':
        const phoneNumberPattern = /^\d{10}$/;
        if (!value || value.trim() === '') {
          return 'Field required';
        }
        if (!phoneNumberPattern.test(value)) {
          return 'Invalid phone number format';
        }
        break;
      case 'email':
        const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        if (!value || value.trim() === '') {
          return 'Email is required';
        }
        if (!emailPattern.test(value)) {
          return 'Invalid email format';
        }
        break;
      default:
        break;
    }
    return '';
  };

  useEffect(() => {
    const initialErrors = {};
    for (const field in answer) {
      initialErrors[field] = validateAnswer(field, answer[field]);
    }
    setErrors(initialErrors);
  }, [answer]);

  const handleInputChange = (newValue, field) => {
    const validationError = validateAnswer(field, newValue[field]);
    setErrors((prevErrors) => ({
      ...prevErrors,
      [field]: validationError,
    }));
    onChange(newValue);
  };

  const firstName = 'firstName';

  return (
    <div className="personal-information-form">
      <div className="form-body">
        <div className='name-fields'>
          <CustomTextField
            label="First Name"
            name="firstName"
            className="custom-input first-name"
            defaultValue={answer.firstName}
            helperText={errors.firstName}
            onChange={(e) => handleInputChange({ ...answer, firstName: e.target.value }, 'firstName')}
            required
          />
          <CustomTextField
            label="Middle Name"
            name="middleName"
            className="custom-input middle-name"
            defaultValue={answer.middleName}
            
            helperText={errors.middleName}
            onChange={(e) => handleInputChange({ ...answer, middleName: e.target.value }, 'middleName')}
          />
          <CustomTextField
            label="Last Name"
            name="lastName"
            className="custom-input last-name"
            defaultValue={answer.lastName}
          
            helperText={errors.lastName}
            onChange={(e) => handleInputChange({ ...answer, lastName: e.target.value }, 'lastName')}
            required
          />
        </div>
        <CustomTextField
          label="Phone Number"
          name="phoneNumber"
          className="custom-input phone-number"
          defaultValue={answer.phoneNumber}
          
          helperText={errors.phoneNumber}
          onChange={(e) => handleInputChange({ ...answer, phoneNumber: e.target.value }, 'phoneNumber')}
          required
        />
        <CustomTextField
          label="Email"
          name="email"
          className="custom-input email"
          defaultValue={answer.email}
          
          helperText={errors.email}
          onChange={(e) => handleInputChange({ ...answer, email: e.target.value }, 'email')}
          required
        />
      </div>
      </div>
  );
};

export default PersonalInformation;
