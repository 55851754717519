import FormControl from '@mui/material/FormControl';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import HomeIcon from '@mui/icons-material/Home';
import HouseIcon from '@mui/icons-material/House';
import ApartmentIcon from '@mui/icons-material/Apartment';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import CottageOutlinedIcon from '@mui/icons-material/CottageOutlined';
import VillaOutlinedIcon from '@mui/icons-material/VillaOutlined';
import TrendingUpOutlinedIcon from '@mui/icons-material/TrendingUpOutlined';


const ListItemButtonStack = (props) => {

  const handleAnswerChange = (value) =>{
    props.handleAnswerChange(value);
  }

  const getIcon = (value) => {
    switch(value){
      case "SingleFamily":
        return <HomeIcon sx={{ fontSize: 40 }}/>
      case "TownHouse":
        return <HouseIcon sx={{ fontSize: 40 }}/>
      case "Condo":
        return <HomeWorkIcon sx={{ fontSize: 40 }}/>
      case "MultiFamily":
        return <ApartmentIcon sx={{ fontSize: 40 }}/>
      case "PrimaryResidence":
        return <CottageOutlinedIcon sx={{ fontSize: 40 }}/>
      case "SecondHome":
        return <VillaOutlinedIcon sx={{ fontSize: 40 }}/>
      case "InvestmentProperty":
        return <TrendingUpOutlinedIcon sx={{ fontSize: 40 }}/>
    }
  }

  return (
    <FormControl>
        {props.options.map((option, index) => (
            <ListItemButton
                    key={option.text}
                    sx={{ minHeight: 32}}
                    onClick={() => handleAnswerChange(option.value)}
                    onMouseEnter={(e) => {
                      e.currentTarget.style.border = '3px solid #00634a'
                    }}
                    onMouseLeave={(e) => {
                      e.currentTarget.style.border = '1px solid #00634a'
                    }}
                    style={{
                        border: props.answer === option.value ? '1px solid #ffffff':'1px solid #00634a',
                        borderRadius:'10px',
                        backgroundColor: props.answer === option.value ? '#00634a' : '#ffffff',
                        color:props.answer === option.value ? "#ffffff" :'#00634a',
                        marginBottom: '10px',
                        padding:'10px',
                        justifyContent: 'center'
                    }}
                  >
                    {getIcon(option.value)}
                    <ListItemText
                      primary={option.text}
                      primaryTypographyProps={{ fontSize: 16, fontWeight: 'bold', fontFamily:'Graphic,sans-serif', marginLeft:'20px' }}
                    />
                  </ListItemButton>
            ))}
    </FormControl>
  );
}

export default ListItemButtonStack;
