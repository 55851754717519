export const API_URL = process.env.REACT_APP_SERVER_URL;
export const COPY_STATE_TO_REDUX = "COPY_STATE_TO_REDUX";
export const COPY_SCHEMA = "COPY_SCHEMA";
export const COPY_RAW_COMPONENT_STATE="COPY_RAW_COMPONENT_STATE";
export const UPDATE_ERRORS_DICTIONARY="UPDATE_ERRORS_DICTIONARY";
export const SAVE_LOAN = "SAVE_LOAN";
export const GET_LOANS="GET_LOANS";
export const GET_LOAN_DATA = "GET_LOAN_DATA";
export const GET_DOC_REQUEST = "GET_DOC_REQUEST";
export const SUBMIT_LOAN = "SUBMIT_LOAN";
export const UPLOAD_DOCUMENT = "UPLOAD_DOCUMENT";
export const SHOW_LOADER = "SHOW_LOADER";
export const RESET_REDUX = "RESET_REDUX"
export const SAML_RESP = "SAML_RESP";
export const SP_RESP ="SP_RESP";
export const ACTIVE_STEP = "ACTIVE_STEP";
export const STEPPER_BACK = "STEPPER_BACK";
export const SYNC_EOS = "SYNC_EOS";
export const GET_LOAN_LOCK_STATUS = "GET_LOAN_LOCK_STATUS";