import _ from "lodash";
import { bindActionCreators } from "redux";
import {
  copySchema,
  copyStateToRedux,
  resetRedux,
  showLoader,
  activeStep,
  stepperBackStep,
} from "../redux/actions/actions";
import {
  getLoans,
  getLoanData,
  getDocRequest,
  getLoanLockStatus,
} from "../redux/actions/getActions";

import {
  saveLoanData,
  submitLoan,
  uploadDocumentApi,
  coappsubmitLoan,
  syncEncompassLoanToLocalDB
} from "../redux/actions/postActions";
import { createDefaultValueArray } from "../json_schema_forms/helpers";
import {SOURCE} from "../constants";

export const setValueByRecursion = (
  state,
  statePath,
  modifier,
  defaultValue
) => {
  // const recur = (result, path, dval) => {
  //   if (path?.length === 0) {
  //     return modifier(_.get(state, statePath, ""));
  //   }
  //   if (Array.isArray(result)) {
  //     let arrayLen = result.length;
  //     let i = arrayLen - 1;
  //     while (i < path[0]) {
  //       result.push({});
  //       i++;
  //     }
  //   }
  //   let key = path[0];
  //   return Array.isArray(result)
  //     ? result.map((item, index) => {
  //       if (index === Number(key)) {
  //         return recur(item, path.slice(1), dval?.slice(1));
  //       } else {
  //         return item;
  //       }
  //     })
  //     : {
  //       ...result,
  //       [key]: recur(
  //         _.get(result, key, dval[0]),
  //         path.slice(1),
  //         dval.slice(1)
  //       ),
  //     };
  // };
  // const newState = recur(state, statePath, defaultValue);
  // return _.get(state, statePath) === _.get(newState, statePath)
  //   ? state
  //   : newState;
  const recur = (result, path, dval) => {
    // Base case: If the path is empty, apply the modifier function to the result
    if (!path?.length) {
      return modifier(_.get(state, statePath, ""));
    }
    // Extract the current key from the path
    let key = path[0];
    // If the current key is an integer and the result is an array
    if (!isNaN(key) && Array.isArray(result)) {
      // Convert the key to an integer index
      let index = parseInt(key, 10);
      // Create a shallow copy of the array to avoid mutating the original array
      const newArray = [...result];
      // Ensure the array is long enough to accommodate the indexed element
      while (newArray.length <= index) {
        // If the index matches the current length, insert defaultValue, otherwise insert an empty object
        newArray.push(index === newArray.length ? defaultValue : {});
      }
      // Recur on the indexed element with the rest of the path
      newArray[index] = recur(result[index], path.slice(1), dval?.slice(1));
      // Return the modified array
      return newArray;
    }
    // If the current key is not an integer or the result is not an array
    // Recur on the nested object with the rest of the path
    return {
      ...result,
      [key]: recur(
        _.get(result, key, dval[0]),
        path.slice(1),
        dval.slice(1)
      ),
    };
  };
  // Call the 'recur' function with initial parameters and store the result in 'newState'
  const newState = recur(state, statePath, defaultValue);
  // Check if the state value has changed after the operation
  return _.isEqual(_.get(state, statePath), _.get(newState, statePath))
    // If the state remains unchanged, return the original state
    ? state
    // If the state has changed, return the updated state
    : newState;
};
export const setAccessToken = (token) => {
  window.localStorage.setItem("cx_access_token", token);
};
export const setUserEmail = (userEmail) => {
  window.localStorage.setItem("user_email", userEmail);
};
export const setTokenExpiryTime = (tokenExpiryTime) => {
  window.localStorage.setItem("token_expiry", tokenExpiryTime);
};
export const setTokenDuration = (tokenDuration) => {
  window.localStorage.setItem("token_duration", tokenDuration);
};
export const getAccessToken = () => {
  return window.localStorage.getItem("cx_access_token");
};
export const getUserEmail = () => {
  return window.localStorage.getItem("user_email");
};
export const getTokenExpiryTime = () => {
  return window.localStorage.getItem("token_expiry");
};
export const getTokenDuration = () => {
  return window.localStorage.getItem("token_duration");
};
export function mapStateToProps({ userReducer }) {
  return {
    componentState: userReducer.componentState,
    componentSchema: userReducer.componentSchema,
    componentRawState: userReducer.rawComponentState,
    getLoansDataRes: userReducer.getLoansDataRes,
    saveLoanDataRes: userReducer.saveLoanDataRes,
    getLoanDataWithIdRes: userReducer.getLoanDataWithId,
    getDocRequestsRes: userReducer.getDocRequests,
    errorsDictionary: userReducer.errorsDictionary,
    submitedLoanRes: userReducer.submitedLoanRes,
    uploadDocumentApiResData: userReducer.uploadDocumentApiRes,
    toggleLoader: userReducer.toggleLoader,
    apiErrResponseData: userReducer.apiErrResponse,
    samlResponse: userReducer.samlResponse,
    reduxActiveStep: userReducer.activeStep,
    stepperBackStatus: userReducer.stepperBackStatus,
    getLoanLockStatusRes: userReducer.getLoanLockStatusRes,
  };
}

export function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      copyStateToRedux: copyStateToRedux,
      copySchema: copySchema,
      getLoans: getLoans,
      saveLoanData: saveLoanData,
      uploadDocumentApi: uploadDocumentApi,
      getLoanData: getLoanData,
      getDocRequest: getDocRequest,
      submitLoan: submitLoan,
      coappsubmitLoan: coappsubmitLoan,
      showLoader: showLoader,
      resetRedux: resetRedux,
      activeStep: activeStep,
      stepperBackStep: stepperBackStep,
      syncEncompassLoanToLocalDB: syncEncompassLoanToLocalDB,
      getLoanLockStatus: getLoanLockStatus
    },
    dispatch
  );
}

/**
 * https://www.tabnine.com/academy/javascript/how-to-set-cookies-javascript/
 * @param name
 * @param value
 * @param exdays
 * @param path
 */
export function setCookie(name, value, exdays, path) {
  let d, expires;
  exdays = exdays || 1;
  let cookieStr = "";
  d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  expires = "expires=" + d.toUTCString();
  cookieStr = name + "=" + value + "; " + expires + "; ";
  if (path) {
    cookieStr += "path=" + path + ";";
  }
  document.cookie = cookieStr;
}

export function getCookie(cName) {
  const name = cName + "=";
  const cDecoded = decodeURIComponent(document.cookie); //to be careful
  const cArr = cDecoded.split("; ");
  let res;
  cArr.forEach((val) => {
    if (val.indexOf(name) === 0) res = val.substring(name.length);
  });
  return res;
}

let enumMap = {
  //"Primary Home": "PrimaryResidence",
  "Secondary Home": "SecondHome",
  "Investment Home": "Investor",
  "Unmarried (Single, divorced, widowed)": "Unmarried",
  "No Primary Housing Expense": "NoPrimaryHousingExpense",
  "Civil Union": "CivilUnion",
  "Domestic Partnership": "DomesticPartnership",
  "U.S. Citizen": "USCitizen",
  "Permanent Resident Alien": "PermanentResidentAlien",
  "Non Permanent Resident Alien": "NonPermanentResidentAlien",
  "Registered Reciprocal Beneficiary Relationship":
    "RegisteredReciprocalBeneficiaryRelationship",
  "Pending Sale": "PendingSale",
  "Retain for Rental": "RetainForRental",
  "Retain for Primary or Secondary Residence":
    "RetainForPrimaryOrSecondaryResidence",
  "Child Care": "ChildCare",
  "Collections Judgements and Liens": "CollectionsJudgementsandLiens",
  "Installment": "Installments",
  "Lease Payment Mortgage": "LeasePaymentMortgage",
  "Open 30 Days Charge Amount": "Open30DaysChargeAmount",
  "Other Liability": "OtherLiability",
  // "Other ( i.e, Tax Lien and Child Care Expenses)": "Other",
  "Taxes Lien": "TaxesLien",
  "Mortgage": "MortgageLoan",
  "Child Support": "ChildSupport",
  "Job Related Expenses": "JobRelatedExpenses",
  "Separate Maintenance Expense": "SeparateMaintenanceExpense",
  "I have an Ownership share of less than 25%": "LessThan25Percent",
  "I have an ownership share of 25% or more": "GreaterThanOrEqualTo25Percent",
  "Accessory Unit Income": "AccessoryUnitIncome",
  "Automobile Allowance": "AutomobileAllowance",
  "Boarder Income": "BoarderIncome",
  "Capital Gains": "CapitalGains",
  "Defined Contribution Plan": "DefinedContributionPlan",
  "Dividends Interest": "DividendsInterest",
  "Employment Related Account": "EmploymentRelatedAccount",
  "Foster Care": "FosterCare",
  "Housing Allowance": "HousingAllowance",
  "Housing Choice Voucher Program": "HousingChoiceVoucherProgram",
  "Mortgage Credit Certificate": "MortgageCreditCertificate",
  "Mortgage Differential": "MortgageDifferential",
  "Non Borrower Household Income": "NonBorrowerHouseholdIncome",
  "Notes Receivable Installment": "NotesReceivableInstallment",
  "Public Assistance": "PublicAssistance",
  "Separate Maintenance": "SeparateMaintenance",
  "Social Security": "SocialSecurity",
  "Temporary Leave": "TemporaryLeave",
  "Tip Income": "TipIncome",
  "VA Benefits Non-Educational": "VABenefitsNonEducational",
  "Annuity ": "Annuity",
  "Automobile ": "Automobile",
  "Boat ": "Boat",
  "Bond": "bond",
  "Gift of Cash": "GiftOfCash",
  "Gift of Property Equity": "GiftOfPropertyEquity",
  "Borrower Primary Home ": "BorrowerPrimaryHome",
  "Bridge Loan Not Deposited": "bridgeLoanNotdeposited",
  "Bridge Loan Not Deposited (FHA/VA)": "BridgeLoanNotDeposited",
  "Cash on Hand": "CashOnHand",
  "Certificate of Deposit": "certificateOfDeposit",
  "Checking Account": "checkingAccount",
  "Earnest Money": "EarnestMoney",
  "Employer Assistance": "EmployerAssistedHousing",
  "Individual Development Account": "individualDevelopmentAccount",
  "Lease Purchase Fund": "LeasePurchaseFund",
  "Life Insurance": "lifeInsurance",
  "Money Market Fund": "moneyMarketFund",
  "Mutual Funds": "mutualFunds",
  "Net Worth of Business Owned ": "NetWorthOfBusinessOwned",
  "Pending Net Sale Proceeds From Real Estate Assets":
    "PendingNetSaleProceedsFromRealEstateAssets",
  "Proceeds From Sale of Non Real Estate Assets":
    "ProceedsFromSaleOfNonRealEstateAsset",
  "Proceeds From Secured Loan": "ProceedsFromSecuredLoan",
  "Proceeds From Unsecured Loan": "ProceedsFromUnsecuredLoan",
  "Recreational Vehicle ": "RecreationalVehicle",
  "Rent Credit": "LeasePurchaseCredit",
  "Savings Account": "savingsAccount",
  "Savings Bond ": "SavingsBond",
  "Severance Package ": "SeverancePackage",
  "Stock": "stocks",
  "Stock Options": "stockOptions",
  "Sweat Equity": "SweatEquity",
  "Trade Equity From Property Swap": "TradeEquityFromPropertySwap",
  "Trust Account": "trustAccount",
  "Lot Equity": "LotEquity",
  "Relocation Funds": "RelocationFunds",
  "I have ownership share of 25% or more": "GreaterThanOrEqualTo25Percent",
  "I have ownership share of less than 25%": "LessThan25Percent",
  "Sole Ownership": "Sole",
  "Joint With Spouse": "JointWithSpouse",
  "Joint With Other Than Spouse": "JointWithOtherThanSpouse",
  "Primary Residence": "PrimaryResidence",
  "Secondary Residence": "SecondaryResidence",
  "FHA Secondary Residence": "FHASecondaryResidence",
  // "Investment Property": "Investment",
  "Manufactured": "Manufactured",
  "Site Build": "Site Built",
  "Seperate Maintenance Expense": "SeparateMaintenanceExpense",
  "Bridge Loan": "BridgeLoan",
  "Checking / Savings": "CheckingSavings",
  "Deposit on Sales Contract": "DepositOnSalesContract",
  "Equity on Pending Sale": "EquityOnPendingSale",
  "Equity on Sold Property": "EquityOnSoldProperty",
  "Gift Funds": "GiftFunds",
  "Life Insurance Cash Value": "LifeInsuranceCashValue",
  "Other type of Down Payment": "OtherTypeOfDownPayment",
  "Rent With Option to Purchase": "RentWithOptionToPurchase",
  "Retirement Funds": "RetirementFunds",
  "Sale of Chattel": "SaleOfChattel",
  "Secured Borrowed Funds": "SecuredBorrowedFunds",
  "Stock and Bonds": "StocksAndBonds",
  "Trade Equity": "TradeEquity",
  "Trust Funds": "TrustFunds",
  "Unsecured Borrowed Funds": "UnsecuredBorrowedFunds",
  "FHA - Gift - Seller Funded": "FHAGiftSourceNonprofitSellerFunded",
  "FHA - Gift - Non-seller Funded": "FHAGiftSourceNonprofitNonSellerFunded",
  "FHA - Gift - Gov't Assistance": "FHAGiftSourceGovernmentAssistance",
  "FHA - Gift - Source Relative": "FHAGiftSourceRelative",
  "FHA - Gift - Source Employer": "FHAGiftSourceEmployer",
  "FHA - Gift - N/A": "FHAGiftSourceNA",
  "Federal Agency": "FederalAgency",
  "Local Agency": "LocalAgency",
  "Non Parent Relative": "NonParentRelative",
  "Religious Nonprofit": "ReligiousNonprofit",
  "State Agency": "StateAgency",
  "Unmarried Partner": "UnmarriedPartner",
  "Unrelated Friend": "UnrelatedFriend",
  "Community Nonprofit": "CommunityNonprofit",
  // "Single Family Residence":"Detached",
  "Single Family Residence with Home Owners Association": "PUD",
  "Townhouse": "Attached",
  // "2+ Units":"Detached",
  "Co-Operative": "Cooperative",
  "Manufactured/Mobile Home (Single Wide)": "ManufacturedHousing",
  "Manufactured/Mobile Home (Multiwide)": "ManufacturedHousing",
  "Other Liquid Asset": "OtherLiquidAsset",
  "Other Non Liquid Asset": "OtherNonLiquidAsset",
  "Single Family": "SingleFamily",
  "Townhome": "Townhouse",
  "2-4 units": "TwoToFourUnitProperty",
  "More than 4 units": "MultifamilyMoreThanFourUnits",
  "Mobile home": "ManufacturedMobileHome",
  "Commercial": "CommercialNonResidential",
  "Home and Business Combined": "HomeAndBusinessCombined",
  "Mixed Use": "MixedUseResidential"
};

let reverseEnumMap = {};
function enumValuesToStateMapping(enumToUpdate) {
  if (_.isEmpty(reverseEnumMap)) {
    for (const [key, value] of Object.entries(enumMap)) {
      reverseEnumMap[value] = key;
    }
  }
  return _.get(reverseEnumMap, enumToUpdate, enumToUpdate);
}

export const mapStateToObject = (state, loanObject) => {
  if (loanObject === undefined) loanObject = {};
  for (const [key, value] of Object.entries(stateObjectMap)) {
    // let stateObj = []
    if (typeof value === "object") {
      let stateObj = _.get(state, key);
      let backendVar = [];
      if (stateObj === undefined) stateObj = [];
      for (let i = 0; i < stateObj.length; i++) {
        backendVar[i] = {};
        for (const [key1, value1] of Object.entries(value)) {
          if (key1 === "__backendArray") continue;
          backendVar[i][value1] = _.get(enumMap, stateObj[i][key1], stateObj[i][key1]);
        }
      }

      loanObject = setValueByRecursion(
        loanObject,
        value["__backendArray"].split("."),
        () => {
          return backendVar;
        },
        createDefaultValueArray(value["__backendArray"].split("."))
      );
    } else {
      let defaultVal = [];
      defaultVal = createDefaultValueArray(stateObjectMap[key].split("."));

      if (key === "EmploymentAndIncome.Borrower.employerInformation") {
        loanObject = manageEmployment(loanObject, state, defaultVal, "set", true);
        continue;
      }
      if (key === "EmploymentAndIncome.CoBorrower.employerInformation") {
        loanObject = manageEmployment(loanObject, state, defaultVal, "set", false);
        continue;
      }
      if (key === "Address") {
        loanObject = manageAddress(loanObject, state, defaultVal, "set");
        continue;
      }
      if (key === "CoBorrowerAddress") {
        loanObject = manageCoBorrowerAddress(loanObject, state, defaultVal, "set");
        continue;
      }
      if (key === "ManageOtherExpenses.Liability") {
        loanObject = manageLiabilities(loanObject, state, defaultVal, "set");
        continue;
      }
      if (key === "JointCredit.AreYouApplyingForAJointCreditWithACoBorrower") {
        loanObject = manageJointCredit(loanObject, state, defaultVal, "set");
        continue;
      }
      if (key === "EConsentAgreement.EConsentAgreement") {
        loanObject = manageEConsent(loanObject, state, defaultVal, "set");
        continue;
      }
      if (key === "ConsumerCreditConsent.ConsumerConsent") {
        loanObject = manageConsumerConsent(loanObject, state, defaultVal, "set");
        continue;
      }
      if (key === "CoApplicant.CoApplicant") {
        let mm = manageCoApplicant(loanObject, state, defaultVal, "set");
        for (let i = 0; i < mm?.applications.length; i++) {
          loanObject = setValueByRecursion(
            loanObject,
            ["applications", `${i + 1}`, "borrower"],
            () => mm?.applications[i],
            mm?.applications
          );
        }
        continue;
      }
      loanObject = setValueByRecursion(
        loanObject,
        stateObjectMap[key]?.split("."),
        () => _.get(enumMap, _.get(state, key), _.get(state, key)),
        defaultVal
      );
    }
  }
  const urlParams = new URLSearchParams(window.location.search);
  const getLarFromLocalStorage = urlParams.has('lar') ? urlParams.get('lar') : window.localStorage.getItem("lar");
  const loanOfficer = getLarFromLocalStorage ? getLarFromLocalStorage : _.get(state, "LoanOfficerId");
  loanObject = _.set(loanObject, "loanOfficerId", loanOfficer);
  loanObject = _.set(loanObject, "loanSource", SOURCE);
  return loanObject;
};

/**This will change if we find a better approach*/
var ip = "";
(async () => {
  try {
    const response = await fetch("https://api.ipify.org?format=json");
    const data = await response.json();
    ip = data.ip;
  } catch (error) {
    console.error("Error fetching IP address:", error);
  }
})();


const manageEConsent = (obj, state, defaultVal, operation) => {
  let tempS = operation === "get" ? _.cloneDeep(obj) : _.cloneDeep(state);
  if (operation === "set") {
    let consent = _.get(tempS, "EConsentAgreement.EConsentAgreement");
    let firstName = _.get(tempS, "ApplicantDetails.BorrowerDetails.FirstName");
    let lastName = _.get(tempS, "ApplicantDetails.BorrowerDetails.LastName");
    let email = _.get(tempS, "ApplicantDetails.BorrowerDetails.Email");
    let temp = {
      type: "eConsent",
      source: SOURCE,
      borrowerId: localStorage.getItem("bidentifier"),
      status: consent == "I Agree" ? "Accepted" : "Declined",
      firstName: firstName,
      lastName: lastName,
      email: email,
      dateAccepted: new Date().toISOString(),
      ipAddress: ip
    };
    obj = setValueByRecursion(
      obj,
      ["uiData", "consentGroup", "borrowerEConsent"],
      () => temp,
      defaultVal
    );
    return obj;
  }
  else {
    let consentData = _.get(
      tempS,
      "uiData.consentGroup.borrowerEConsent",
      {}
    );
    let consent;
    if (consentData.status === "Accepted") {
      consent = "I Agree";
    } else if (consentData.status === "Declined") {
      consent = "I Decline";
    }

    state = _.set(state, "EConsentAgreement.EConsentAgreement", consent);
    return state;
  }
}
const manageConsumerConsent = (obj, state, defaultVal, operation) => {
  let tempS = operation === "get" ? _.cloneDeep(obj) : _.cloneDeep(state);
  if (operation === "set") {
    let consent = _.get(tempS, "ConsumerCreditConsent.ConsumerConsent");
    let firstName = _.get(tempS, "ApplicantDetails.BorrowerDetails.FirstName");
    let lastName = _.get(tempS, "ApplicantDetails.BorrowerDetails.LastName");
    let email = _.get(tempS, "ApplicantDetails.BorrowerDetails.Email");
    let temp = {
      type: "OrderCredit",
      inquiryType: "Soft",
      borrowerId: localStorage.getItem("bidentifier"),
      status: consent == "I Agree" ? "Accepted" : "Declined",
      firstName: firstName,
      lastName: lastName,
      email: email,
      dateAccepted: new Date().toISOString(),
      ipAddress: ip,
      source: SOURCE
    };
    obj = setValueByRecursion(
      obj,
      ["uiData", "consentGroup", "borrowerCreditConsent"],
      () => temp,
      defaultVal
    );
    return obj;
  }
  else {
    let consumerConsentData = _.get(
      tempS,
      "uiData.consentGroup.borrowerCreditConsent",
      {}
    );
    let consumerConsent;
    if (consumerConsentData.status === "Accepted") {
      consumerConsent = "I Agree";
    } else if (consumerConsentData.status === "Declined") {
      consumerConsent = "I Decline";
    }
    state = _.set(state, "ConsumerCreditConsent.ConsumerConsent", consumerConsent);
    return state;
  }
}

const manageCoBorrowerAddress = (obj, state, defaultVal, operation) => {
  let tempS = operation === "get" ? _.cloneDeep(obj) : _.cloneDeep(state);
  if (operation === "set") {
    let prevAdd = _.get(tempS, "Address.CoBorrowerAddress.PreviousAddress");
    // prevAdd = _.filter(prevAdd, e => !_.isEmpty(e)); // Temp fix, check why are we getting {} when deleting address
    prevAdd =
      prevAdd &&
      prevAdd.map((e) => {
        let temp = _.clone(e);
        temp = {
          residencyType: "Prior",
          mailingAddressIndicator: false,
          urla2020StreetAddress: temp["AddressLine1"],
          addressUnitDesignatorType: temp["unitType"],
          addressUnitIdentifier: temp["Unit#"],
          addressCity: temp["City"],
          addressState: temp["state"],
          addressPostalCode: temp["ZipCode"],
          country: temp["Country"],
          durationTermYears: temp["NumberOfYears"],
          durationTermMonths: temp["Months"],
          residencyBasisType: _.get(enumMap, temp["Housing"], temp["Housing"]),
          rent: temp["Monthly Rent"],
        };
        return temp;
      });
    let currAdd = _.get(tempS, "Address.CoBorrowerAddress.CurrentAddress");
    currAdd = currAdd && {
      residencyType: "Current",
      mailingAddressIndicator: false,
      urla2020StreetAddress: currAdd["AddressLine1"],
      addressUnitDesignatorType: currAdd["unitType"],
      addressUnitIdentifier: currAdd["Unit#"],
      addressCity: currAdd["City"],
      addressState: currAdd["state"],
      addressPostalCode: currAdd["ZipCode"],
      country: currAdd["Country"],
      durationTermYears: currAdd["NumberOfYears"],
      durationTermMonths: currAdd["Months"],
      residencyBasisType: _.get(enumMap, currAdd["Housing"], currAdd["Housing"]),
      rent: currAdd["Monthly Rent"],
    };

    let residenceAdd = [];
    currAdd && residenceAdd.push(currAdd);
    if (prevAdd && prevAdd.length > 0) residenceAdd = residenceAdd.concat(prevAdd);
    obj = setValueByRecursion(
      obj,
      ["applications", 0, "coborrower", "residences"],
      () => residenceAdd,
      defaultVal
    );


    let mailingAdd;
    if (_.get(tempS, "Address.CoBorrowerAddress.MailingAddress.MailingAddress") === "No") {
      mailingAdd = _.get(tempS, "Address.CoBorrowerAddress.MailingAddress");
      mailingAdd = mailingAdd && {
        mailingAddressIndicator: true,
        urla2020StreetAddress: mailingAdd["AddressLine1"],
        addressUnitDesignatorType: mailingAdd["unitType"],
        addressUnitIdentifier: mailingAdd["Unit#"],
        addressCity: mailingAdd["City"],
        addressState: mailingAdd["state"],
        addressPostalCode: mailingAdd["ZipCode"],
        country: mailingAdd["Country"],
        durationTermYears: mailingAdd["NumberOfYears"],
        durationTermMonths: mailingAdd["Months"],
        residencyBasisType: _.get(enumMap, mailingAdd["Housing"], mailingAdd["Housing"]),
        rent: mailingAdd["Monthly Rent"],
      };

      if (mailingAdd) {
        obj = setValueByRecursion(
          obj,
          ["applications", 0, "coborrower", "mailingAddress"],
          () => mailingAdd,
          defaultVal
        );
      }
    }
    return obj;
  } else {
    let prevAddress = _.get(
      tempS,
      "applications.0.coborrower.residences",
      []
    ).filter((item) => {
      return item.residencyType === "Prior";
    });

    let currAddr = _.get(
      tempS,
      "applications.0.coborrower.residences",
      []
    ).filter((item) => {
      return item.residencyType === "Current";
    });
    let mailingAddr = _.get(
      tempS,
      "applications.0.coborrower.mailingAddress",
      []
    );
    let mailingAddressSameAsPresentIndicator = _.get(
      tempS,
      "applications.0.coborrower.mailingAddressSameAsPresentIndicator"
    )

    state = setValueByRecursion(
      state,
      ["Address", "CoBorrowerAddress", "PreviousAddress"],
      () => reverseMapAdressObjects(prevAddress),
      defaultVal
    );
    state = setValueByRecursion(
      state,
      ["Address", "CoBorrowerAddress", "CurrentAddress"],
      () => reverseMapAdressObjects(currAddr)[0],
      defaultVal
    );
    state = setValueByRecursion(
      state,
      ["Address", "CoBorrowerAddress", "MailingAddress"],
      () => reverseMapAdressObjects([mailingAddr])[0],
      defaultVal
    );
    state = setValueByRecursion(
      state,
      ["Address", "CoBorrowerAddress", "MailingAddress", "MailingAddress"],
      () => mailingAddressSameAsPresentIndicator,
      defaultVal
    );
    return state;
  }

};
const manageAddress = (obj, state, defaultVal, operation) => {
  let tempS = operation === "get" ? _.cloneDeep(obj) : _.cloneDeep(state);
  if (operation === "set") {
    let prevAdd = _.get(tempS, "Address.BorrowerAddress.PreviousAddress");
    // prevAdd = _.filter(prevAdd, e => !_.isEmpty(e)); // Temp fix, check why are we getting {} when deleting address
    prevAdd =
      prevAdd &&
      prevAdd.map((e) => {
        let temp = _.clone(e);
        temp = {
          residencyType: "Prior",
          mailingAddressIndicator: false,
          urla2020StreetAddress: temp["AddressLine1"],
          addressUnitDesignatorType: temp["unitType"],
          addressUnitIdentifier: temp["Unit#"],
          addressCity: temp["City"],
          addressState: temp["state"],
          addressPostalCode: temp["ZipCode"],
          country: temp["Country"],
          durationTermYears: temp["NumberOfYears"],
          durationTermMonths: temp["Months"],
          residencyBasisType: _.get(enumMap, temp["Housing"], temp["Housing"]),
          rent: temp["Monthly Rent"],
        };
        return temp;
      });
    let currAdd = _.get(tempS, "Address.BorrowerAddress.BorrowerCurrentAddress");
    currAdd = currAdd && {
      residencyType: "Current",
      mailingAddressIndicator: false,
      urla2020StreetAddress: currAdd["AddressLine1"],
      addressUnitDesignatorType: currAdd["unitType"],
      addressUnitIdentifier: currAdd["Unit#"],
      addressCity: currAdd["City"],
      addressState: currAdd["state"],
      addressPostalCode: currAdd["ZipCode"],
      country: currAdd["Country"],
      durationTermYears: currAdd["NumberOfYears"],
      durationTermMonths: currAdd["Months"],
      residencyBasisType: _.get(enumMap, currAdd["Housing"], currAdd["Housing"]),
      rent: currAdd["Monthly Rent"],
    };

    let residenceAdd = [];
    currAdd && residenceAdd.push(currAdd);
    if (prevAdd && prevAdd.length > 0) residenceAdd = residenceAdd.concat(prevAdd);
    obj = setValueByRecursion(
      obj,
      ["applications", 0, "borrower", "residences"],
      () => residenceAdd,
      defaultVal
    );

    let mailingAdd;
    if (_.get(tempS, "Address.BorrowerAddress.MailingAddress.MailingAddress") === "No") {
      mailingAdd = _.get(tempS, "Address.BorrowerAddress.MailingAddress");
      mailingAdd = mailingAdd && {
        mailingAddressIndicator: true,
        urla2020StreetAddress: mailingAdd["AddressLine1"],
        addressUnitDesignatorType: mailingAdd["unitType"],
        addressUnitIdentifier: mailingAdd["Unit#"],
        addressCity: mailingAdd["City"],
        addressState: mailingAdd["state"],
        addressPostalCode: mailingAdd["ZipCode"],
        country: mailingAdd["Country"],
        durationTermYears: mailingAdd["NumberOfYears"],
        durationTermMonths: mailingAdd["Months"],
        residencyBasisType: _.get(enumMap, mailingAdd["Housing"], mailingAdd["Housing"]),
        rent: mailingAdd["Monthly Rent"],
      };

      if (mailingAdd) {
        obj = setValueByRecursion(
          obj,
          ["applications", 0, "borrower", "mailingAddress"],
          () => mailingAdd,
          defaultVal
        );
      }
    }
    return obj;
  } else {
    let prevAddress = _.get(
      tempS,
      "applications.0.borrower.residences",
      []
    ).filter((item) => {
      return item.residencyType === "Prior";
    });

    let currAddr = _.get(
      tempS,
      "applications.0.borrower.residences",
      []
    ).filter((item) => {
      return item.residencyType === "Current";
    });

    let mailingAddr = _.get(
      tempS,
      "applications.0.borrower.mailingAddress",
      []
    );

    let mailingAddressSameAsPresentIndicator = _.get(
      tempS,
      "applications.0.borrower.mailingAddressSameAsPresentIndicator"
    )

    state = setValueByRecursion(
      state,
      ["Address", "BorrowerAddress", "PreviousAddress"],
      () => reverseMapAdressObjects(prevAddress),
      defaultVal
    );
    state = setValueByRecursion(
      state,
      ["Address", "BorrowerAddress", "BorrowerCurrentAddress"],
      () => reverseMapAdressObjects(currAddr)[0],
      defaultVal
    );
    state = setValueByRecursion(
      state,
      ["Address", "BorrowerAddress", "MailingAddress"],
      () => reverseMapAdressObjects([mailingAddr])[0],
      defaultVal
    );
    state = setValueByRecursion(
      state,
      ["Address", "BorrowerAddress", "MailingAddress", "MailingAddress"],
      () => mailingAddressSameAsPresentIndicator,
      defaultVal
    );
    return state;
  }
};

const manageJointCredit = (obj, state, defaultVal, operation) => {
  let tempS = operation === "get" ? _.cloneDeep(obj) : _.cloneDeep(state);

  if (operation === "set") {
    let isJointCredit = _.get(
      tempS,
      "JointCredit.AreYouApplyingForAJointCreditWithACoBorrower"
    );
    if (isJointCredit === "Yes") isJointCredit = "Jointly";
    else if (isJointCredit === "No") isJointCredit = "NotJointly";

    return setValueByRecursion(
      obj,
      ["applications", 0, "borrower", "jointAssetLiabilityReportingIndicator"],
      () => isJointCredit,
      defaultVal
    );
  } else {
    let isJointCredit = _.get(tempS, "applications.0.borrower.jointAssetLiabilityReportingIndicator");
    if (!isJointCredit) {
      isJointCredit = _.get(tempS, "applications.0.borrower.jointAssetLiabilityReportingIndicator1");
    }

    if (isJointCredit === "Jointly") {
      isJointCredit = "Yes";
    } else if (isJointCredit === "NotJointly") {
      isJointCredit = "No";
    }

    return setValueByRecursion(
      state,
      ["JointCredit", "AreYouApplyingForAJointCreditWithACoBorrower"],
      () => isJointCredit,
      defaultVal
    );
  }
};

const getPropertyType = (obj) => {

  let propertyType = _.get(
    obj,
    "loanProductData.gsePropertyType"
  );
  let tsum = _.get(obj, "tsum.propertyType")

  if (tsum === "2-4 Units") {
    propertyType = "2+ Units"
  }
  if (tsum === "1 Unit") {
    propertyType = "Single Family Residence"
  }
  if (tsum === "Manufactured Housing Multiwide") {
    propertyType = "Manufactured/Mobile Home (Multiwide)";
  }
  if (tsum === "Manufactured Housing Single Wide") {
    propertyType = "Manufactured/Mobile Home (Single Wide)";
  }
  if (tsum === "Cooperative") {
    propertyType = "Co-Operative";
  }
  if (tsum === "Condominium") {
    propertyType = "Condominium";
  }
  if (tsum === "PUD") {
    if (propertyType === "Attached") {
      propertyType = "Townhouse";
    }
    else {
      propertyType = "Single Family Residence with Home Owners Association";
    }
  }
  return propertyType;
}

const reverseMapAdressObjects = (addressInfo) => {
  addressInfo = addressInfo.map((e) => {
    let temp = _.clone(e);
    temp = {
      AddressLine1: temp["urla2020StreetAddress"],
      unitType: temp["addressUnitDesignatorType"],
      "Unit#": temp["addressUnitIdentifier"],
      City: temp["addressCity"],
      state: temp["addressState"],
      ZipCode: temp["addressPostalCode"],
      Country: temp["country"] ? temp["country"] : "US",
      NumberOfYears: temp["durationTermYears"],
      Months: temp["durationTermMonths"],
      "Housing": enumValuesToStateMapping(temp["residencyBasisType"]),
      "Monthly Rent": temp["rent"],
    };
    return temp;
  });

  return addressInfo;
};

export const mapObjectToState = (state, loanObject) => {
  state = state === undefined ? {} : state;
  loanObject = loanObject === undefined ? {} : loanObject;
  if (_.isEmpty(reverseStateObjectMap)) {
    for (const [key, value] of Object.entries(stateObjectMap)) {
      if (typeof value === "object")
        reverseStateObjectMap[JSON.stringify(value)] = key;
      else reverseStateObjectMap[value] = key;
    }
  }

  for (const [key, value] of Object.entries(reverseStateObjectMap)) {
    let stateObj = [];
    let defaultVal = [];

    if (key.includes("__backendArray")) {
      let dict = stateObjectMap[value];

      let backendVar = _.get(loanObject, dict["__backendArray"], []);
      for (let i = 0; i < backendVar.length; i++) {
        stateObj[i] = {};
        for (let [key1, value1] of Object.entries(dict)) {
          if (key1 === "__backendArray") continue;
          stateObj[i][key1] = enumValuesToStateMapping(backendVar[i][value1]);
          if (typeof backendVar[i][value1] === "boolean" && yesOrNoList.includes(key1)) {
            stateObj[i][key1] = backendVar[i][value1] ? "Yes" : "No";
          }
          if (dict["__backendArray"] === "applications.0.otherAssets" && backendVar[i][value1] === "Other") { //mi6-2120
            stateObj[i][key1] = "Other Asset"
          }
        }
      }
      defaultVal = createDefaultValueArray(value.split("."));

      state = setValueByRecursion(
        state,
        value.split("."),
        () => stateObj,
        defaultVal
      );
      // if (key.includes("applications.0.vols")) {
      //   state = manageLiabilities(loanObject, state, defaultVal);
      // }
    }
    else {
      let defaultVal = [];
      defaultVal = createDefaultValueArray(
        reverseStateObjectMap[key].split(".")
      );
      switch (key) {
        case "applications.0.borrower.employment":
          state = manageEmployment(loanObject, state, defaultVal, "get", true);
          break;
        case "applications.0.coborrower.employment":
          state = manageEmployment(loanObject, state, defaultVal, "get", false);
          break;
        case "applications.0.borrower.residences":
          state = manageAddress(loanObject, state, defaultVal, "get");
          break;
        case "applications.0.coborrower.residences":
          state = manageCoBorrowerAddress(loanObject, state, defaultVal, "get");
          break;
        case "applications.0.otherLiabilities":
          state = manageLiabilities(loanObject, state, defaultVal, "get");
          break;
        case "applications.0.borrower.jointAssetLiabilityReportingIndicator":
          state = manageJointCredit(loanObject, state, defaultVal, "get");
          break;
        case "applications":
          state = manageCoApplicant(loanObject, state, defaultVal, "get");
          break;
        case "uiData.consentGroup.borrowerEConsent":
          state = manageEConsent(loanObject, state, defaultVal, "get");
          break;
        case "uiData.consentGroup.borrowerCreditConsent":
          state = manageConsumerConsent(loanObject, state, defaultVal, "get");
          break;
        case "loanProductData.gsePropertyType":
          let propertyType = getPropertyType(loanObject);
          if (propertyType !== undefined) {
            state = _.set(state, "propertyDetails.PropertyType.PropertyType", propertyType);
          }
          break;

        default:
          let val = enumValuesToStateMapping(_.get(loanObject, key));

          if (typeof val === "boolean" && yesOrNoList.includes(value)) {
            val = val ? "Yes" : "No";
            _.set(loanObject, key, val);
          }
          state = setValueByRecursion(
            state,
            reverseStateObjectMap[key].split("."),
            () => enumValuesToStateMapping(_.get(loanObject, key)),
            defaultVal
          );
      }
    }
  }
  let manualAssets = [..._.get(state, "giftsGrants")];
  manualAssets.push(..._.get(state, "otherAssets"), ..._.get(state, "vods"));
  state = _.set(state, "ManualAssetsEntry.Assets", manualAssets);
  let loanOfficerId = _.get(loanObject, "loanOfficerId");
  state = _.set(state, "LoanOfficerId", loanOfficerId)
  return state;
};
// export const createDefaultValueArray = (object_path) => {
//   let defaultValueArray = [];
//   for (let i = 0; i < object_path.length; i++) {
//     if (i === object_path.length - 1) {
//       // the last element is either integer, or string or boolean
//       defaultValueArray.push("");
//     } else if (isNaN(object_path[i + 1])) {
//       defaultValueArray.push({});
//     } else {
//       // if the next element in object_path[i+1] is integer we assume that it is an array element
//       defaultValueArray.push([{}]);
//     }
//   }
//   return defaultValueArray;
// };
const manageEmployment = (obj, state, defaultVal, operation, isBorrower) => {
  let tempS = operation === "get" ? _.cloneDeep(obj) : _.cloneDeep(state);
  let backendPath = isBorrower ? "applications.0.borrower.employment" : "applications.0.coborrower.employment";
  let statePath = isBorrower ? "EmploymentAndIncome.Borrower.employerInformation" : "EmploymentAndIncome.CoBorrower.employerInformation";
  if (operation === "get") {

    let employerInfo = _.get(
      tempS,
      backendPath,
      []
    ).map((e) => {
      let temp = _.clone(e);
      temp = {
        ...temp,
        employmentObj: {
          currentEmploymentIndicator: temp["currentEmploymentIndicator"],
          selfEmployedIndicator: temp["selfEmployedIndicator"],
          specialEmployerRelationshipIndicator:
            temp["specialEmployerRelationshipIndicator"],
          ownershipShare: enumValuesToStateMapping(
            temp["ownershipInterestType"]
          ),
          startDate: temp["employmentStartDate"],
          endDate: temp["endDate"],
          monthlyIncomeOrLoss: temp["employmentMonthlyIncomeAmount"],
        },
        position: temp["positionDescription"],
        basePay: temp["basePayAmount"],
        militaryPayConditional1: {
          overtime: temp["overtimeAmount"],
          bonuses: temp["bonusAmount"],
          commissions: temp["commissionsAmount"],
          militaryCombatPay: temp["militaryCombatPay"],
          militaryFlightPay: temp["militaryFlightPay"],
          militaryHazardPay: temp["militaryHazardPay"],
          militaryOverseasPay: temp["militaryOverseasPay"],
          militaryPropPay: temp["militaryPropPay"],
          clothingAllowance: temp["clothingAllowance"],
          rationsAllowance: temp["rationsAllowance"],
          variableHousingAllowance: temp["variableHousingAllowance"],
          quartersAllowance: temp["quartersAllowance"]
        },
        other: temp["otherAmount"],
        otherIncomeDescription: temp["otherAllowanceDescription"],
        totalBorrowerIncome: temp["monthlyIncomeAmount"],
        foreignIncome: temp["foreignIncome"],
        seasonalIncome: temp["seasonalIncome"],
        iReceiveMilitaryPay: temp["militaryEmployer"],
        conditional2: {
          totalMilitaryEntitlements: temp["militaryEntitlement"],
        },
        endDate: temp["endDate"],
        // startDate:temp["employmentStartDate"]
      };

      return temp;
    });
    return setValueByRecursion(
      state,
      statePath.split("."),
      () => employerInfo,
      defaultVal
    );
  }
  else {
    let employerInfo = _.get(
      tempS,
      statePath,
      []
    ).map((e) => {
      let temp = _.cloneDeep(e);
      if (temp?.employmentObj === undefined) {
        temp = { ...temp, employmentObj: {} }
      }
      temp = {
        employerName: temp.employerName,
        businessPhone: temp.businessPhone,
        currentEmploymentIndicator: temp?.employmentObj["currentEmploymentIndicator"],
        selfEmployedIndicator: temp?.employmentObj["selfEmployedIndicator"],
        specialEmployerRelationshipIndicator:
          temp.employmentObj["specialEmployerRelationshipIndicator"],
        ownershipInterestType: enumMap[temp.employmentObj["ownershipShare"]],
        employmentMonthlyIncomeAmount:
          temp.employmentObj["monthlyIncomeOrLoss"],
        positionDescription: temp["position"],
        basePayAmount: temp["basePay"],
        overtimeAmount: temp.militaryPayConditional1 && temp.militaryPayConditional1["overtime"],
        bonusAmount: temp.militaryPayConditional1 && temp.militaryPayConditional1["bonuses"],
        commissionsAmount: temp.militaryPayConditional1 && temp.militaryPayConditional1["commissions"],
        militaryCombatPay: temp.militaryPayConditional1 && temp.militaryPayConditional1["militaryCombatPay"],
        militaryFlightPay: temp.militaryPayConditional1 && temp.militaryPayConditional1["militaryFlightPay"],
        militaryHazardPay: temp.militaryPayConditional1 && temp.militaryPayConditional1["militaryHazardPay"],
        militaryOverseasPay: temp.militaryPayConditional1 && temp.militaryPayConditional1["militaryOverseasPay"],
        militaryPropPay: temp.militaryPayConditional1 && temp.militaryPayConditional1["militaryPropPay"],
        clothingAllowance: temp.militaryPayConditional1 && temp.militaryPayConditional1["clothingAllowance"],
        rationsAllowance: temp.militaryPayConditional1 && temp.militaryPayConditional1["rationsAllowance"],
        variableHousingAllowance: temp.militaryPayConditional1 && temp.militaryPayConditional1["variableHousingAllowance"],
        quartersAllowance: temp.militaryPayConditional1 && temp.militaryPayConditional1["quartersAllowance"],
        otherAmount: temp["other"],
        otherAllowanceDescription: temp["otherIncomeDescription"],
        monthlyIncomeAmount: temp["totalBorrowerIncome"],
        foreignIncome: temp["foreignIncome"],
        seasonalIncome: temp["seasonalIncome"],
        militaryEmployer: temp["iReceiveMilitaryPay"],
        militaryEntitlement: temp.conditional2 && temp.conditional2["totalMilitaryEntitlements"],
        employmentStartDate: temp.employmentObj["startDate"],
        endDate: temp.employmentObj["endDate"],
      };
      return temp;
    });
    return setValueByRecursion(
      obj,
      backendPath.split("."),
      () => employerInfo,
      defaultVal
    );
  }
};

const manageCoApplicant = (obj, state, defaultVal, operation, isBorrower) => {
  let tempS = operation === "get" ? _.cloneDeep(obj) : _.cloneDeep(state);
  //tempS=tempS?.applications.splice(1);

  let backendPath = "applications";
  let statePath = "CoApplicant.CoApplicant";
  if (operation === "get") {
    let coapplicant = _.get(
      tempS,
      backendPath,
      []
    ).slice(1).map((e) => {
      let temp = _.clone(e);
      temp = {
        ...temp,
        FirstName: temp?.borrower?.firstName,
        MiddleName: temp?.borrower?.middleName,
        LastName: temp?.borrower?.lastName,
        Email: temp?.borrower?.emailAddressText,
        Phone: temp?.borrower?.phone,
      };
      return temp;
    });

    return setValueByRecursion(
      state,
      statePath.split("."),
      () => coapplicant,
      defaultVal
    );
  }
  else {
    let employerInfo = _.get(
      tempS,
      statePath,
      []
    ).map((e) => {
      let temp = _.cloneDeep(e);
      temp = {
        firstName: temp.FirstName,
        middleName: temp.MiddleName,
        lastName: temp.LastName,
        phone: temp.Phone,
        emailAddressText: temp.Email,
      };
      return temp;
    });

    return setValueByRecursion(
      obj,
      backendPath.split("."),
      () => employerInfo,
      defaultVal
    );
  }
};

const manageLiabilities = (obj, state, defaultVal, operation) => {
  let tempS = operation === "get" ? _.cloneDeep(obj) : _.cloneDeep(state);
  let otherEnumKey = "Other ( i.e, Tax Lien and Child Care Expenses)";
  if (operation === "get") {
    let liabiltiesInfo = _.get(tempS, "applications.0.otherLiabilities", []).map((e) => {
      let temp = _.clone(e);
      temp = {
        ...temp,
        SelectTypeOfExpense: temp["liabilityOrExpenseType"] === "Other" ? otherEnumKey : enumValuesToStateMapping(temp["liabilityOrExpenseType"]),
        OtherDescription: temp["otherDescription"],
        CompanyNameOrPayableTo: temp["holderName"],
        Balance: temp["balance"],
        MonthlyAmount: temp["monthlyPayment"],
        MonthsLeft: temp["monthsLeft"],
        belongsTo: temp["borrowerType"]
      };
      return temp;
    });
    return setValueByRecursion(
      state,
      ["ManageOtherExpenses", "Liability"],
      () => liabiltiesInfo,
      defaultVal
    );
  }
  else {
    let liabilities = _.get(
      tempS,
      "ManageOtherExpenses.Liability",
      []
    ).map((e) => {
      let temp = _.cloneDeep(e);
      temp = {
        liabilityOrExpenseType: temp.SelectTypeOfExpense === otherEnumKey ? "Other" : _.get(enumMap, temp.SelectTypeOfExpense, temp.SelectTypeOfExpense),
        otherDescription: temp.OtherDescription,
        holderName: temp.CompanyNameOrPayableTo,
        balance: temp.Balance,
        monthlyPayment: temp.MonthlyAmount,
        monthsLeft: temp.MonthsLeft,
        borrowerType: temp.belongsTo
      };
      return temp;
    });
    return setValueByRecursion(obj, ["applications", "0", "otherLiabilities"], () => liabilities, defaultVal);
  }
};

export function appendQueryParamToURL(url) {
  const queryString = new URLSearchParams(window.location.search).toString();
  return url.includes('?') ? `${url}&${queryString}` : `${url}?${queryString}`;
}

let stateObjectMap = {
  // Purpose Of Loan
  "purposeOfLoan.WhoReferredYou?": "referralSource",
  "purposeOfLoan.LoanPurpose": "property.loanPurposeType",
  "purposeOfLoan.EstimatedPurchasePrice": "purchasePriceAmount",
  "purposeOfLoan.EstimatedPropertyValue": "propertyEstimatedValueAmount",
  "purposeOfLoan.DownPaymentAmount": "downPayment.amount",
  "purposeOfLoan.DownPaymentPercentage": "downPaymentPercent",
  "purposeOfLoan.YourLoanAmount": "uiData.yourLoanAmount",
  "purposeOfLoan.RequestedLoanAmount": "borrowerRequestedLoanAmount",
  //"purposeOfLoan.EstimatedValue": "propertyEstimatedValueAmount",
  "purposeOfLoan.CurrentLoanBalance":
    "property.refinancePropertyExistingLienAmount",
  "purposeOfLoan.SourceOfDownPayment.SourceOfDownPayment":
    "downPayment.downPaymentType",
  "purposeOfLoan.SourceOfDownPayment.GiftAmount": "fhaVaLoan.giftFundsAmount",
  "purposeOfLoan.SourceOfDownPayment.ExplanationOfGift":
    "downPayment.sourceDescription",
  "purposeOfLoan.DoYouHaveASecondMortgage?": "uiData.secondMortgageIndicator",
  "purposeOfLoan.DoYouWantToPayOffThisLoanWithTheNewLoan?":
    "uiData.payOffLoanIndicator",
  "purposeOfLoan.SecondMortgageAmount": "secondSubordinateAmount",
  /* property details (option purchase) */
  "propertyDetails.loanPurposeConditional.HaveYouIdentifiedAHomeToPurchase?.HaveYouIdentifiedAHomeToPurchase?":
    "uiData.identifiedHomeToPurchaseIndicator",
  "propertyDetails.loanPurposeConditional.HaveYouIdentifiedAHomeToPurchase?.HaveYouSignedAContract?.HaveYouSignedAContract?":
    "uiData.signedContractIndicator",
  "propertyDetails.loanPurposeConditional.HaveYouIdentifiedAHomeToPurchase?.HaveYouSignedAContract?.WhatIsTheContractClosingDate?":
    "uiData.contractEndDate",
  "propertyDetails.PropertyType.PropertyType":
    "loanProductData.gsePropertyType",
  "propertyDetails.PropertyType.Construction Method Type.ConstructionMethodDescription?":
    "uldd.ginnieOtherConstructionMethodType",
  "propertyDetails.PropertyType.Construction Method Type.ConstructionMethodType":
    "uldd.ginnieConstructionMethodType",
  "propertyDetails.PropertyUse.PropertyUse": "applications.0.propertyUsageType",
  "propertyDetails.PropertyUse.WillYouSetAsideSpace":
    "property.propertyMixedUsageIndicator",
  "propertyDetails.YearBuilt": "property.structureBuiltYear",
  "propertyDetails.NumberOfUnits": "property.financedNumberOfUnits",
  /* property details (option Refinance) */
  "propertyDetails.loanPurposeConditional.AreYouRefinancingYourPrimaryHome?.AreYouRefinancingYourPrimaryHome?":
    "uiData.refinancingPrimaryHomeIndicator",
  "propertyDetails.loanPurposeConditional.AreYouRefinancingYourPrimaryHome?.AreYouRefinancingFromACurrentFHALoanProgramToNewFHALoanProgram?.AreYouRefinancingFromACurrentFHALoanProgramToNewFHALoanProgram?":
    "uiData.refinancingFhaLoanIndicator",
  "propertyDetails.propertyAddress.unitType":
    "property.addressUnitDesignatorType",
  "propertyDetails.propertyAddress.Unit#": "property.addressUnitIdentifier",
  "propertyDetails.propertyAddress.state": "property.state",
  "propertyDetails.loanPurposeConditional.AreYouRefinancingYourPrimaryHome?.AreYouRefinancingFromACurrentFHALoanProgramToNewFHALoanProgram?.ApproximatelyWhenDidYouCloseThisLoan?":
    "fhaVaLoan.previousPurchaseDate",
  "propertyDetails.loanPurposeConditional.WhenDidYouAcquireThisProperty?":
    "property.refinancePropertyAcquiredYear",
  "propertyDetails.loanPurposeConditional.WhatWasTheOriginalPriceOfTheHome?":
    "property.refinancePropertyOriginalCostAmount",

  "JointCredit.AreYouApplyingForAJointCreditWithACoBorrower":
    "applications.0.borrower.jointAssetLiabilityReportingIndicator",
  "JointCredit.Income/assets of a person other than the Borrower (including the borrower's spouse) will be used for loan qualification":
    "applications.0.incomeOtherThanBorrowerUsedIndicator",
  "ApplicantDetails.BorrowerDetails.FirstName": "applications.0.borrower.firstName",
  "ApplicantDetails.BorrowerDetails.LastName": "applications.0.borrower.lastName",
  "ApplicantDetails.BorrowerDetails.Email": "applications.0.borrower.emailAddressText",
  "ApplicantDetails.BorrowerDetails.DateOfBirth": "applications.0.borrower.birthDate",
  "ApplicantDetails.BorrowerDetails.MiddleName": "applications.0.borrower.middleName",
  "ApplicantDetails.BorrowerDetails.Suffix": "applications.0.borrower.suffixToName",
  "ApplicantDetails.BorrowerDetails.SocialSecurityNumber":
    "applications.0.borrower.taxIdentificationIdentifier",
  "ApplicantDetails.BorrowerDetails.Citizenship":
    "applications.0.borrower.urla2020CitizenshipResidencyType",
  "ApplicantDetails.BorrowerDetails.MaritalStatus.MaritalStatus":
    "applications.0.borrower.maritalStatusType",

  "ApplicantDetails.BorrowerDetails.MaritalStatus.IsThereAPersonWhoIsNotYourLegal.IsThereAPersonWhoIsNotYourLegal":
    "applications.0.borrower.legalOtherThanSpouse",
  "ApplicantDetails.BorrowerDetails.MaritalStatus.IsThereAPersonWhoIsNotYourLegal.DomesticRelationshipType.DomesticRelationshipType":
    "applications.0.borrower.domesticRelationshipType",
  "ApplicantDetails.BorrowerDetails.MaritalStatus.IsThereAPersonWhoIsNotYourLegal.DomesticRelationshipType.OtherDomesticRelationshipType":
    "applications.0.borrower.otherRelationshipTypeDescription",
  "ApplicantDetails.BorrowerDetails.MaritalStatus.IsThereAPersonWhoIsNotYourLegal.StateOfFormedRelationship":
    "applications.0.borrower.state",
  "ApplicantDetails.BorrowerDetails.NumberOfDependents.NumberOfDependents":
    "applications.0.borrower.dependentCount",
  "ApplicantDetails.BorrowerDetails.NumberOfDependents.AgesOfDependents":
    "applications.0.borrower.dependentsAgesDescription",
  "ApplicantDetails.BorrowerDetails.CellPhone": "applications.0.borrower.mobilePhone",
  "ApplicantDetails.BorrowerDetails.HomePhone": "applications.0.borrower.homePhoneNumber",
  "ApplicantDetails.BorrowerDetails.WorkPhone": "applications.0.borrower.workPhoneNumber",
  "ApplicantDetails.BorrowerDetails.DidYou(or your deceased spouse)EverServe,OrAreYouCurrentlyServing,InTheUnitedStatesArmedForces?.DidYou(or your deceased spouse)EverServe,OrAreYouCurrentlyServing,InTheUnitedStatesArmedForces?":
    "applications.0.borrower.selfDeclaredMilitaryServiceIndicator",
  "ApplicantDetails.BorrowerDetails.DidYou(or your deceased spouse)EverServe,OrAreYouCurrentlyServing,InTheUnitedStatesArmedForces?.CurrentlyServingOnActiveDuty":
    "applications.0.borrower.activeDuty",
  "ApplicantDetails.BorrowerDetails.DidYou(or your deceased spouse)EverServe,OrAreYouCurrentlyServing,InTheUnitedStatesArmedForces?.ExpirationDateOfService/Tour":
    "applications.0.borrower.militaryServiceExpectedCompletionDate",
  "ApplicantDetails.BorrowerDetails.DidYou(or your deceased spouse)EverServe,OrAreYouCurrentlyServing,InTheUnitedStatesArmedForces?.CurrentlyRetired, discharged, or separated from service":
    "applications.0.borrower.veteran",
  "ApplicantDetails.BorrowerDetails.DidYou(or your deceased spouse)EverServe,OrAreYouCurrentlyServing,InTheUnitedStatesArmedForces?.OnlyPeriodOfServiceWasAsNon-ActivatedMemberOfTheReserveOrNationalGuard":
    "applications.0.borrower.reserveNationalGuardReserveActivated",
  "ApplicantDetails.BorrowerDetails.DidYou(or your deceased spouse)EverServe,OrAreYouCurrentlyServing,InTheUnitedStatesArmedForces?.SurvivingSpouse":
    "applications.0.borrower.spousalVaBenefitsEligibilityIndicator",
  "ApplicantDetails.BorrowerDetails.PrivacyPolicy": "uiData.readTermsOfPolicyIndicator",
  //coborrower
  "ApplicantDetails.CoBorrowerDetails.FirstName": "applications.0.coborrower.firstName",
  "ApplicantDetails.CoBorrowerDetails.LastName": "applications.0.coborrower.lastName",
  "ApplicantDetails.CoBorrowerDetails.Email": "applications.0.coborrower.emailAddressText",
  "ApplicantDetails.CoBorrowerDetails.DateOfBirth": "applications.0.coborrower.birthDate",
  "ApplicantDetails.CoBorrowerDetails.MiddleName": "applications.0.coborrower.middleName",
  "ApplicantDetails.CoBorrowerDetails.Suffix": "applications.0.coborrower.suffixToName",
  "ApplicantDetails.CoBorrowerDetails.SocialSecurityNumber": "applications.0.coborrower.taxIdentificationIdentifier",
  "ApplicantDetails.CoBorrowerDetails.Citizenship": "applications.0.coborrower.urla2020CitizenshipResidencyType",
  "ApplicantDetails.CoBorrowerDetails.MaritalStatus.MaritalStatus": "applications.0.coborrower.maritalStatusType",
  "ApplicantDetails.CoBorrowerDetails.MaritalStatus.IsThereAPersonWhoIsNotYourLegal.IsThereAPersonWhoIsNotYourLegal": "applications.0.coborrower.legalOtherThanSpouse",
  "ApplicantDetails.CoBorrowerDetails.MaritalStatus.IsThereAPersonWhoIsNotYourLegal.DomesticRelationshipType.DomesticRelationshipType": "applications.0.coborrower.domesticRelationshipType",
  "ApplicantDetails.CoBorrowerDetails.MaritalStatus.IsThereAPersonWhoIsNotYourLegal.DomesticRelationshipType.OtherDomesticRelationshipType": "applications.0.coborrower.otherRelationshipTypeDescription",
  "ApplicantDetails.CoBorrowerDetails.MaritalStatus.IsThereAPersonWhoIsNotYourLegal.StateOfFormedRelationship": "applications.0.coborrower.state",
  "ApplicantDetails.CoBorrowerDetails.NumberOfDependents.NumberOfDependents": "applications.0.coborrower.dependentCount",
  "ApplicantDetails.CoBorrowerDetails.NumberOfDependents.AgesOfDependents": "applications.0.coborrower.dependentsAgesDescription",
  "ApplicantDetails.CoBorrowerDetails.CellPhone": "applications.0.coborrower.mobilePhone",
  "ApplicantDetails.CoBorrowerDetails.HomePhone": "applications.0.coborrower.homePhoneNumber",
  "ApplicantDetails.CoBorrowerDetails.WorkPhone": "applications.0.coborrower.workPhoneNumber",
  "ApplicantDetails.CoBorrowerDetails.DidYou(or your deceased spouse)EverServe,OrAreYouCurrentlyServing,InTheUnitedStatesArmedForces?.DidYou(or your deceased spouse)EverServe,OrAreYouCurrentlyServing,InTheUnitedStatesArmedForces?":
    "applications.0.coborrower.selfDeclaredMilitaryServiceIndicator",
  "ApplicantDetails.CoBorrowerDetails.DidYou(or your deceased spouse)EverServe,OrAreYouCurrentlyServing,InTheUnitedStatesArmedForces?.CurrentlyServingOnActiveDuty":
    "applications.0.coborrower.activeDuty",
  "ApplicantDetails.CoBorrowerDetails.DidYou(or your deceased spouse)EverServe,OrAreYouCurrentlyServing,InTheUnitedStatesArmedForces?.ExpirationDateOfService/Tour":
    "applications.0.coborrower.militaryServiceExpectedCompletionDate",
  "ApplicantDetails.CoBorrowerDetails.DidYou(or your deceased spouse)EverServe,OrAreYouCurrentlyServing,InTheUnitedStatesArmedForces?.CurrentlyRetired, discharged, or separated from service":
    "applications.0.coborrower.veteran",
  "ApplicantDetails.CoBorrowerDetails.DidYou(or your deceased spouse)EverServe,OrAreYouCurrentlyServing,InTheUnitedStatesArmedForces?.OnlyPeriodOfServiceWasAsNon-ActivatedMemberOfTheReserveOrNationalGuard":
    "applications.0.coborrower.reserveNationalGuardReserveActivated",
  "ApplicantDetails.CoBorrowerDetails.DidYou(or your deceased spouse)EverServe,OrAreYouCurrentlyServing,InTheUnitedStatesArmedForces?.SurvivingSpouse":
    "applications.0.coborrower.spousalVaBenefitsEligibilityIndicator",
  "Address.CoBorrowerAddress.MailingAddress.MailingAddress":
    "applications.0.coborrower.mailingAddressSameAsPresentIndicator",
  "Address.BorrowerAddress.MailingAddress.MailingAddress":
    "applications.0.borrower.mailingAddressSameAsPresentIndicator",
  Address: "applications.0.borrower.residences",
  CoBorrowerAddress: "applications.0.coborrower.residences",
  "EConsentAgreement.EConsentAgreement": "uiData.consentGroup.borrowerEConsent",
  "ConsumerCreditConsent.ConsumerConsent": "uiData.consentGroup.borrowerCreditConsent",
  "Address.BorrowerAddress.BorrowerCurrentAddress.FirstMortgage(P&I)":
    "applications.0.firstMortgagePrincipalAndInterestAmount",
  "Address.BorrowerAddress.BorrowerCurrentAddress.SecondMortgage(P&I)":
    "applications.0.otherMortgagePrincipalAndInterestAmount",
  "Address.BorrowerAddress.BorrowerCurrentAddress.HazardInsurance": "applications.0.hazardInsuranceAmount",
  "Address.BorrowerAddress.BorrowerCurrentAddress.SupplementalInsurance":
    "applications.0.presentSupplementalPropertyInsuranceAmount",
  "Address.BorrowerAddress.BorrowerCurrentAddress.RealEstateTaxes": "applications.0.realEstateTaxAmount",
  "Address.BorrowerAddress.BorrowerCurrentAddress.MortgageInsurance": "applications.0.mortgageInsuranceAmount",
  "Address.BorrowerAddress.BorrowerCurrentAddress.Total": "applications.0.borrower.totalPresentHousingExpenseAmount",

  "ManageYourLiabilities.Liability": {
    __backendArray: "applications.0.vols",
    SelectTypeOfLiability: "liabilityType",
    OtherDescription: "otherDescription",
    NameOfCreditor: "holderName",
    Balance: "unpaidBalanceAmount",
    MonthlyPayment: "monthlyPaymentAmount",
    MonthsLeft: "remainingTermMonths",
    PayOff: "payoffStatusIndicator",
    SecuredBySubjectProperty: "subjectPropertyIndicator",
    belongsTo: "owner"
  },

  // "ManageOtherExpenses.Liability": {
  //   __backendArray: "applications.0.otherLiabilities",
  //   SelectTypeOfExpense: "liabilityOrExpenseType",
  //   OtherDescription: "otherDescription",
  //   CompanyNameOrPayableTo: "holderName",
  //   Balance: "balance",
  //   MonthlyAmount: "monthlyPayment",
  //   MonthsLeft: "monthsLeft",
  //   belongsTo: "borrowerType"
  // },


  "EmploymentAndIncome.Borrower.employerInformation":
    "applications.0.borrower.employment",
  "EmploymentAndIncome.CoBorrower.employerInformation":
    "applications.0.coborrower.employment",
  "ManageOtherExpenses.Liability": "applications.0.otherLiabilities",


  "EmploymentAndIncome.additionalIncome": {
    __backendArray: "applications.0.otherIncomeSources",
    incomeSource: "description",
    belongsTo: "owner",
    amount: "monthlyAmount",
  },

  "EmploymentAndIncome.totalAdditionalIncome": "uiData.totalAdditionalIncome",


  // "ManualAssetsEntry.Assets": "applications.0.manualAssets",
  giftsGrants: {
    __backendArray: "applications.0.giftsGrants",
    selectTypeOfAsset: "assetType",
    source: "source",
    otherSourceDescription: "otherSourceDescription",
    amount: "amount",
    deposited: "depositedIndicator",
    City: "holderAddressCity",
    ZipCode: "holderAddressPostalCode",
    state: "holderAddressState",
    address: "holderAddressStreetLine1",
    address2: "holderAddressStreetLine2",
    // holderAddressStreetLine1:"address2",
    nameOfFinancialInstitution: "holderName",
    belongsTo: "owner"
  },

  otherAssets: {
    __backendArray: "applications.0.otherAssets",
    selectTypeOfAsset: "assetType",
    cashOrMarketValue: "cashOrMarketValue",
    City: "holderAddressCity",
    ZipCode: "holderAddressPostalCode",
    state: "holderAddressState",
    address: "holderAddressStreetLine1",
    address2: "holderAddressStreetLine2",
    nameOfFinancialInstitution: "holderName",
    description: "otherDescription",
    belongsTo: "borrowerType"
  },

  vods: {
    __backendArray: "applications.0.vods",
    selectTypeOfAsset: "type",
    accountNumber: "accountIdentifier",
    cashOrMarketValue: "cashOrMarketValue",
    City: "holderAddressCity",
    ZipCode: "holderAddressPostalCode",
    state: "holderAddressState",
    address: "holderAddressStreetLine1",
    address2: "holderAddressStreetLine2",
    // holderAddressStreetLine1:"address2",
    nameOfFinancialInstitution: "holderName",
    belongsTo: "owner"
  },

  "ManageYourProperty.RealEstateOwned": {
    __backendArray: "applications.0.reoProperties",
    City: "city",
    DateAcquired: "acquiredDate",
    "Unit#": "unitNumber",
    ZipCode: "postalCode",
    address: "urla2020StreetAddress",
    grossRentalIncome: "rentalIncomeGrossAmount",
    monthlyMortgagePayment: "lienInstallmentAmount",
    outstandingMortgageBalance: "lienUpbAmount",
    presentMarketValue: "marketValueAmount",
    propertyCurrentlyUsedAs: "propertyUsageType",
    propertyStatus: "dispositionStatusType",
    pendingSaleDate: "pendingSaleDate",
    purchasePrice: "purchasePrice",
    state: "state",
    "taxes,InsuranceAndOther": "maintenanceExpenseAmount",
    typeOfProperty: "gsePropertyType",
    unitType: "unitType",
    propertyWillBeUsedAs: "futurePropertyUsageType",
    propertyUsageDescription: "futureUsageTypeOtherDesc",
  },

  "Declarations.ApplicantDeclarations.BorrowerDeclarations.a.a": "applications.0.borrower.intentToOccupyIndicator",
  "Declarations.ApplicantDeclarations.BorrowerDeclarations.a.hadAnownershipIntrest.hadAnownershipIntrest":
    "applications.0.borrower.homeownerPastThreeYearsIndicator",
  "Declarations.ApplicantDeclarations.BorrowerDeclarations.a.hadAnownershipIntrest.typeOfProperty":
    "applications.0.borrower.priorPropertyUsageType",
  "Declarations.ApplicantDeclarations.BorrowerDeclarations.a.hadAnownershipIntrest.holdTitleOfProperty":
    "applications.0.borrower.priorPropertyTitleType",
  "Declarations.ApplicantDeclarations.BorrowerDeclarations.a.comments":
    "applications.0.borrower.sectionAExplanation",
  "Declarations.ApplicantDeclarations.BorrowerDeclarations.b.b":
    "applications.0.borrower.specialBorrowerSellerRelationshipIndicator",
  "Declarations.ApplicantDeclarations.BorrowerDeclarations.b.comments":
    "applications.0.borrower.sectionBExplanation",
  "Declarations.ApplicantDeclarations.BorrowerDeclarations.c.c":
    "applications.0.borrower.undisclosedBorrowedFundsIndicator",
  "Declarations.ApplicantDeclarations.BorrowerDeclarations.c.comments":
    "applications.0.borrower.sectionCExplanation",
  "Declarations.ApplicantDeclarations.BorrowerDeclarations.c.WhatIsTheAmountOfThisMoney":
    "applications.0.borrower.undisclosedBorrowedFundsAmount",
  "Declarations.ApplicantDeclarations.BorrowerDeclarations.d1.d1":
    "applications.0.borrower.undisclosedMortgageApplicationIndicator",
  "Declarations.ApplicantDeclarations.BorrowerDeclarations.d1.comments":
    "applications.0.borrower.sectionDExplanation",
  "Declarations.ApplicantDeclarations.BorrowerDeclarations.d2.d2":
    "applications.0.borrower.undisclosedCreditApplicationIndicator",
  "Declarations.ApplicantDeclarations.BorrowerDeclarations.d2.comments":
    "applications.0.borrower.sectionD2Explanation",
  "Declarations.ApplicantDeclarations.BorrowerDeclarations.E.E":
    "applications.0.borrower.propertyProposedCleanEnergyLienIndicator",
  "Declarations.ApplicantDeclarations.BorrowerDeclarations.E.comments":
    "applications.0.borrower.sectionEExplanation",
  "Declarations.ApplicantDeclarations.BorrowerDeclarations.F.F":
    "applications.0.borrower.undisclosedComakerOfNoteIndicator",
  "Declarations.ApplicantDeclarations.BorrowerDeclarations.F.comments":
    "applications.0.borrower.sectionFExplanation",
  "Declarations.ApplicantDeclarations.BorrowerDeclarations.G.G":
    "applications.0.borrower.outstandingJudgementsIndicator",
  "Declarations.ApplicantDeclarations.BorrowerDeclarations.G.comments":
    "applications.0.borrower.sectionGExplanation",
  "Declarations.ApplicantDeclarations.BorrowerDeclarations.H.H":
    "applications.0.borrower.presentlyDelinquentIndicatorUrla",
  "Declarations.ApplicantDeclarations.BorrowerDeclarations.H.comments":
    "applications.0.borrower.sectionHExplanation",
  "Declarations.ApplicantDeclarations.BorrowerDeclarations.I.I":
    "applications.0.borrower.partyToLawsuitIndicatorUrla",
  "Declarations.ApplicantDeclarations.BorrowerDeclarations.I.comments":
    "applications.0.borrower.sectionIExplanation",
  "Declarations.ApplicantDeclarations.BorrowerDeclarations.J.J":
    "applications.0.borrower.priorPropertyDeedInLieuConveyedIndicator",
  "Declarations.ApplicantDeclarations.BorrowerDeclarations.J.comments":
    "applications.0.borrower.sectionJExplanation",
  "Declarations.ApplicantDeclarations.BorrowerDeclarations.K.K":
    "applications.0.borrower.priorPropertyShortSaleCompletedIndicator",
  "Declarations.ApplicantDeclarations.BorrowerDeclarations.K.comments":
    "applications.0.borrower.sectionKExplanation",
  "Declarations.ApplicantDeclarations.BorrowerDeclarations.L.L":
    "applications.0.borrower.priorPropertyForeclosureCompletedIndicator",
  "Declarations.ApplicantDeclarations.BorrowerDeclarations.L.comments":
    "applications.0.borrower.sectionLExplanation",
  "Declarations.ApplicantDeclarations.BorrowerDeclarations.M.M": "applications.0.borrower.bankruptcyIndicator",
  "Declarations.ApplicantDeclarations.BorrowerDeclarations.M.comments":
    "applications.0.borrower.sectionMExplanation",
  "Declarations.ApplicantDeclarations.BorrowerDeclarations.M.Chapter7":
    "applications.0.borrower.bankruptcyIndicatorChapterSeven",
  "Declarations.ApplicantDeclarations.BorrowerDeclarations.M.Chapter11":
    "applications.0.borrower.bankruptcyIndicatorChapterEleven",
  "Declarations.ApplicantDeclarations.BorrowerDeclarations.M.Chapter12":
    "applications.0.borrower.bankruptcyIndicatorChapterTwelve",
  "Declarations.ApplicantDeclarations.BorrowerDeclarations.M.Chapter13":
    "applications.0.borrower.bankruptcyIndicatorChapterThirteen",

  "Declarations.ApplicantDeclarations.CoBorrowerDeclarations.a.a": "applications.0.coborrower.intentToOccupyIndicator",
  "Declarations.ApplicantDeclarations.CoBorrowerDeclarations.a.hadAnownershipIntrest.hadAnownershipIntrest":
    "applications.0.coborrower.homeownerPastThreeYearsIndicator",
  "Declarations.ApplicantDeclarations.CoBorrowerDeclarations.a.hadAnownershipIntrest.typeOfProperty":
    "applications.0.coborrower.priorPropertyUsageType",
  "Declarations.ApplicantDeclarations.CoBorrowerDeclarations.a.hadAnownershipIntrest.holdTitleOfProperty":
    "applications.0.coborrower.priorPropertyTitleType",
  "Declarations.ApplicantDeclarations.CoBorrowerDeclarations.a.comments":
    "applications.0.coborrower.sectionAExplanation",
  "Declarations.ApplicantDeclarations.CoBorrowerDeclarations.b.b":
    "applications.0.coborrower.specialBorrowerSellerRelationshipIndicator",
  "Declarations.ApplicantDeclarations.CoBorrowerDeclarations.b.comments":
    "applications.0.coborrower.sectionBExplanation",
  "Declarations.ApplicantDeclarations.CoBorrowerDeclarations.c.c":
    "applications.0.coborrower.undisclosedBorrowedFundsIndicator",
  "Declarations.ApplicantDeclarations.CoBorrowerDeclarations.c.comments":
    "applications.0.coborrower.sectionCExplanation",
  "Declarations.ApplicantDeclarations.CoBorrowerDeclarations.c.WhatIsTheAmountOfThisMoney":
    "applications.0.coborrower.undisclosedBorrowedFundsAmount",
  "Declarations.ApplicantDeclarations.CoBorrowerDeclarations.d1.d1":
    "applications.0.coborrower.undisclosedMortgageApplicationIndicator",
  "Declarations.ApplicantDeclarations.CoBorrowerDeclarations.d1.comments":
    "applications.0.coborrower.sectionDExplanation",
  "Declarations.ApplicantDeclarations.CoBorrowerDeclarations.d2.d2":
    "applications.0.coborrower.undisclosedCreditApplicationIndicator",
  "Declarations.ApplicantDeclarations.CoBorrowerDeclarations.d2.comments":
    "applications.0.coborrower.sectionD2Explanation",
  "Declarations.ApplicantDeclarations.CoBorrowerDeclarations.E.E":
    "applications.0.coborrower.propertyProposedCleanEnergyLienIndicator",
  "Declarations.ApplicantDeclarations.CoBorrowerDeclarations.E.comments":
    "applications.0.coborrower.sectionEExplanation",
  "Declarations.ApplicantDeclarations.CoBorrowerDeclarations.F.F":
    "applications.0.coborrower.undisclosedComakerOfNoteIndicator",
  "Declarations.ApplicantDeclarations.CoBorrowerDeclarations.F.comments":
    "applications.0.coborrower.sectionFExplanation",
  "Declarations.ApplicantDeclarations.CoBorrowerDeclarations.G.G":
    "applications.0.coborrower.outstandingJudgementsIndicator",
  "Declarations.ApplicantDeclarations.CoBorrowerDeclarations.G.comments":
    "applications.0.coborrower.sectionGExplanation",
  "Declarations.ApplicantDeclarations.CoBorrowerDeclarations.H.H":
    "applications.0.coborrower.presentlyDelinquentIndicatorUrla",
  "Declarations.ApplicantDeclarations.CoBorrowerDeclarations.H.comments":
    "applications.0.coborrower.sectionHExplanation",
  "Declarations.ApplicantDeclarations.CoBorrowerDeclarations.I.I":
    "applications.0.coborrower.partyToLawsuitIndicatorUrla",
  "Declarations.ApplicantDeclarations.CoBorrowerDeclarations.I.comments":
    "applications.0.coborrower.sectionIExplanation",
  "Declarations.ApplicantDeclarations.CoBorrowerDeclarations.J.J":
    "applications.0.coborrower.priorPropertyDeedInLieuConveyedIndicator",
  "Declarations.ApplicantDeclarations.CoBorrowerDeclarations.J.comments":
    "applications.0.coborrower.sectionJExplanation",
  "Declarations.ApplicantDeclarations.CoBorrowerDeclarations.K.K":
    "applications.0.coborrower.priorPropertyShortSaleCompletedIndicator",
  "Declarations.ApplicantDeclarations.CoBorrowerDeclarations.K.comments":
    "applications.0.coborrower.sectionKExplanation",
  "Declarations.ApplicantDeclarations.CoBorrowerDeclarations.L.L":
    "applications.0.coborrower.priorPropertyForeclosureCompletedIndicator",
  "Declarations.ApplicantDeclarations.CoBorrowerDeclarations.L.comments":
    "applications.0.coborrower.sectionLExplanation",
  "Declarations.ApplicantDeclarations.CoBorrowerDeclarations.M.M": "applications.0.coborrower.bankruptcyIndicator",
  "Declarations.ApplicantDeclarations.CoBorrowerDeclarations.M.comments":
    "applications.0.coborrower.sectionMExplanation",
  "Declarations.ApplicantDeclarations.CoBorrowerDeclarations.M.Chapter7":
    "applications.0.coborrower.bankruptcyIndicatorChapterSeven",
  "Declarations.ApplicantDeclarations.CoBorrowerDeclarations.M.Chapter11":
    "applications.0.coborrower.bankruptcyIndicatorChapterEleven",
  "Declarations.ApplicantDeclarations.CoBorrowerDeclarations.M.Chapter12":
    "applications.0.coborrower.bankruptcyIndicatorChapterTwelve",
  "Declarations.ApplicantDeclarations.CoBorrowerDeclarations.M.Chapter13":
    "applications.0.coborrower.bankruptcyIndicatorChapterThirteen",


  "Declarations.ApplicantDemographicInformation.BorrowerDemographicInformation.hispanicOrLatino":
    "applications.0.borrower.hmdaEthnicityHispanicLatinoIndicator",
  "Declarations.ApplicantDemographicInformation.BorrowerDemographicInformation.mexican":
    "applications.0.borrower.hmdaMexicanIndicator",
  "Declarations.ApplicantDemographicInformation.BorrowerDemographicInformation.puertoRican":
    "applications.0.borrower.hmdaPuertoRicanIndicator",
  "Declarations.ApplicantDemographicInformation.BorrowerDemographicInformation.cuban":
    "applications.0.borrower.hmdaCubanIndicator",
  "Declarations.ApplicantDemographicInformation.BorrowerDemographicInformation.otherHispanicOrLatino.otherHispanicOrLatino":
    "applications.0.borrower.hmdaHispanicLatinoOtherOriginIndicator",
  "Declarations.ApplicantDemographicInformation.BorrowerDemographicInformation.otherHispanicOrLatino.Other":
    "applications.0.borrower.hmdaOtherHispanicLatinoOrigin",
  "Declarations.ApplicantDemographicInformation.BorrowerDemographicInformation.NotHispanicOrLatino":
    "applications.0.borrower.hmdaEthnicityNotHispanicLatinoIndicator",
  "Declarations.ApplicantDemographicInformation.BorrowerDemographicInformation.IDoNotWishToProvideThisInformation(Ethnicity)":
    "applications.0.borrower.hmdaEthnicityDoNotWishIndicator",
  "Declarations.ApplicantDemographicInformation.BorrowerDemographicInformation.AmericanIndianOrAlaskaNative.AmericanIndianOrAlaskaNative":
    "applications.0.borrower.hmdaAmericanIndianIndicator",
  "Declarations.ApplicantDemographicInformation.BorrowerDemographicInformation.AmericanIndianOrAlaskaNative.nameOfEnrolled":
    "applications.0.borrower.hmdaAmericanIndianTribe",
  "Declarations.ApplicantDemographicInformation.BorrowerDemographicInformation.Asian":
    "applications.0.borrower.hmdaAsianIndicator",
  "Declarations.ApplicantDemographicInformation.BorrowerDemographicInformation.AsianIndian":
    "applications.0.borrower.hmdaAsianIndianIndicator",
  "Declarations.ApplicantDemographicInformation.BorrowerDemographicInformation.Chinese":
    "applications.0.borrower.hmdaChineseIndicator",
  "Declarations.ApplicantDemographicInformation.BorrowerDemographicInformation.Filipino":
    "applications.0.borrower.hmdaFilipinoIndicator",
  "Declarations.ApplicantDemographicInformation.BorrowerDemographicInformation.Japanese":
    "applications.0.borrower.hmdaJapaneseIndicator",
  "Declarations.ApplicantDemographicInformation.BorrowerDemographicInformation.Korean":
    "applications.0.borrower.hmdaKoreanIndicator",
  "Declarations.ApplicantDemographicInformation.BorrowerDemographicInformation.Vietnamese":
    "applications.0.borrower.hmdaVietnameseIndicator",
  "Declarations.ApplicantDemographicInformation.BorrowerDemographicInformation.OtherAsian.OtherAsian":
    "applications.0.borrower.hmdaAsianOtherRaceIndicator",
  "Declarations.ApplicantDemographicInformation.BorrowerDemographicInformation.OtherAsian.Other":
    "applications.0.borrower.hmdaOtherAsianRace",
  "Declarations.ApplicantDemographicInformation.BorrowerDemographicInformation.blackOrAfrican":
    "applications.0.borrower.hmdaAfricanAmericanIndicator",
  "Declarations.ApplicantDemographicInformation.BorrowerDemographicInformation.NativeHawaii":
    "applications.0.borrower.hmdaNativeHawaiianIndicator",
  "Declarations.ApplicantDemographicInformation.BorrowerDemographicInformation.Samoan":
    "applications.0.borrower.hmdaSamoanIndicator",
  "Declarations.ApplicantDemographicInformation.BorrowerDemographicInformation.OtherPacificIslander.OtherPacificIslander":
    "applications.0.borrower.hmdaPacificIslanderOtherIndicator",
  "Declarations.ApplicantDemographicInformation.BorrowerDemographicInformation.OtherPacificIslander.Other":
    "applications.0.borrower.hmdaOtherPacificIslanderRace",
  "Declarations.ApplicantDemographicInformation.BorrowerDemographicInformation.GuamanianOrChamarro":
    "applications.0.borrower.hmdaGuamanianOrChamorroIndicator",
  "Declarations.ApplicantDemographicInformation.BorrowerDemographicInformation.white":
    "applications.0.borrower.hmdaWhiteIndicator",
  "Declarations.ApplicantDemographicInformation.BorrowerDemographicInformation.IDoNotWishToProvideThisInformation(Race)":
    "applications.0.borrower.hmdaRaceDoNotWishProvideIndicator",
  "Declarations.ApplicantDemographicInformation.BorrowerDemographicInformation.female":
    "applications.0.borrower.hmdaGendertypeFemaleIndicator",
  "Declarations.ApplicantDemographicInformation.BorrowerDemographicInformation.male":
    "applications.0.borrower.hmdaGendertypeMaleIndicator",
  // "BorrowerDemographicInformation.informationNotProvided":'applications.0.borrower.',
  "Declarations.ApplicantDemographicInformation.BorrowerDemographicInformation.IDoNotWishToProvideThisInformation(Sex)":
    "applications.0.borrower.hmdaGendertypeDoNotWishIndicator",
  "tsum": "tsum",

  "Declarations.ApplicantDemographicInformation.CoBorrowerDemographicInformation.hispanicOrLatino":
    "applications.0.coborrower.hmdaEthnicityHispanicLatinoIndicator",
  "Declarations.ApplicantDemographicInformation.CoBorrowerDemographicInformation.mexican":
    "applications.0.coborrower.hmdaMexicanIndicator",
  "Declarations.ApplicantDemographicInformation.CoBorrowerDemographicInformation.puertoRican":
    "applications.0.coborrower.hmdaPuertoRicanIndicator",
  "Declarations.ApplicantDemographicInformation.CoBorrowerDemographicInformation.cuban":
    "applications.0.coborrower.hmdaCubanIndicator",
  "Declarations.ApplicantDemographicInformation.CoBorrowerDemographicInformation.otherHispanicOrLatino.otherHispanicOrLatino":
    "applications.0.coborrower.hmdaHispanicLatinoOtherOriginIndicator",
  "Declarations.ApplicantDemographicInformation.CoBorrowerDemographicInformation.otherHispanicOrLatino.Other":
    "applications.0.coborrower.hmdaOtherHispanicLatinoOrigin",
  "Declarations.ApplicantDemographicInformation.CoBorrowerDemographicInformation.NotHispanicOrLatino":
    "applications.0.coborrower.hmdaEthnicityNotHispanicLatinoIndicator",
  "Declarations.ApplicantDemographicInformation.CoBorrowerDemographicInformation.IDoNotWishToProvideThisInformation(Ethnicity)":
    "applications.0.coborrower.hmdaEthnicityDoNotWishIndicator",
  "Declarations.ApplicantDemographicInformation.CoBorrowerDemographicInformation.AmericanIndianOrAlaskaNative.AmericanIndianOrAlaskaNative":
    "applications.0.coborrower.hmdaAmericanIndianIndicator",
  "Declarations.ApplicantDemographicInformation.CoBorrowerDemographicInformation.AmericanIndianOrAlaskaNative.nameOfEnrolled":
    "applications.0.coborrower.hmdaAmericanIndianTribe",
  "Declarations.ApplicantDemographicInformation.CoBorrowerDemographicInformation.Asian":
    "applications.0.coborrower.hmdaAsianIndicator",
  "Declarations.ApplicantDemographicInformation.CoBorrowerDemographicInformation.AsianIndian":
    "applications.0.coborrower.hmdaAsianIndianIndicator",
  "Declarations.ApplicantDemographicInformation.CoBorrowerDemographicInformation.Chinese":
    "applications.0.coborrower.hmdaChineseIndicator",
  "Declarations.ApplicantDemographicInformation.CoBorrowerDemographicInformation.Filipino":
    "applications.0.coborrower.hmdaFilipinoIndicator",
  "Declarations.ApplicantDemographicInformation.CoBorrowerDemographicInformation.Japanese":
    "applications.0.coborrower.hmdaJapaneseIndicator",
  "Declarations.ApplicantDemographicInformation.CoBorrowerDemographicInformation.Korean":
    "applications.0.coborrower.hmdaKoreanIndicator",
  "Declarations.ApplicantDemographicInformation.CoBorrowerDemographicInformation.Vietnamese":
    "applications.0.coborrower.hmdaVietnameseIndicator",
  "Declarations.ApplicantDemographicInformation.CoBorrowerDemographicInformation.OtherAsian.OtherAsian":
    "applications.0.coborrower.hmdaAsianOtherRaceIndicator",
  "Declarations.ApplicantDemographicInformation.CoBorrowerDemographicInformation.OtherAsian.Other":
    "applications.0.coborrower.hmdaOtherAsianRace",
  "Declarations.ApplicantDemographicInformation.CoBorrowerDemographicInformation.blackOrAfrican":
    "applications.0.coborrower.hmdaAfricanAmericanIndicator",
  "Declarations.ApplicantDemographicInformation.CoBorrowerDemographicInformation.NativeHawaii":
    "applications.0.coborrower.hmdaNativeHawaiianIndicator",
  "Declarations.ApplicantDemographicInformation.CoBorrowerDemographicInformation.Samoan":
    "applications.0.coborrower.hmdaSamoanIndicator",
  "Declarations.ApplicantDemographicInformation.CoBorrowerDemographicInformation.OtherPacificIslander.OtherPacificIslander":
    "applications.0.coborrower.hmdaPacificIslanderOtherIndicator",
  "Declarations.ApplicantDemographicInformation.CoBorrowerDemographicInformation.OtherPacificIslander.Other":
    "applications.0.coborrower.hmdaOtherPacificIslanderRace",
  "Declarations.ApplicantDemographicInformation.CoBorrowerDemographicInformation.GuamanianOrChamarro":
    "applications.0.coborrower.hmdaGuamanianOrChamorroIndicator",
  "Declarations.ApplicantDemographicInformation.CoBorrowerDemographicInformation.white":
    "applications.0.coborrower.hmdaWhiteIndicator",
  "Declarations.ApplicantDemographicInformation.CoBorrowerDemographicInformation.IDoNotWishToProvideThisInformation(Race)":
    "applications.0.coborrower.hmdaRaceDoNotWishProvideIndicator",
  "Declarations.ApplicantDemographicInformation.CoBorrowerDemographicInformation.female":
    "applications.0.coborrower.hmdaGendertypeFemaleIndicator",
  "Declarations.ApplicantDemographicInformation.CoBorrowerDemographicInformation.male":
    "applications.0.coborrower.hmdaGendertypeMaleIndicator",
  // "BorrowerDemographicInformation.informationNotProvided":'applications.0.borrower.',
  "Declarations.ApplicantDemographicInformation.CoBorrowerDemographicInformation.IDoNotWishToProvideThisInformation(Sex)":
    "applications.0.coborrower.hmdaGendertypeDoNotWishIndicator",
  "CoApplicant.CoApplicant": "applications",

};

let reverseStateObjectMap = {};
let yesOrNoList = [
  "purposeOfLoan.DoYouHaveASecondMortgage?",
  "purposeOfLoan.DoYouWantToPayOffThisLoanWithTheNewLoan?",
  "propertyDetails.PropertyUse.WillYouSetAsideSpace",
  "propertyDetails.loanPurposeConditional.HaveYouIdentifiedAHomeToPurchase?.HaveYouIdentifiedAHomeToPurchase?",
  "propertyDetails.loanPurposeConditional.HaveYouIdentifiedAHomeToPurchase?.HaveYouSignedAContract?.HaveYouSignedAContract?",
  "propertyDetails.HaveYouIdentifiedAHomeToPurchase?.HaveYouIdentifiedAHomeToPurchase?",
  "propertyDetails.loanPurposeConditional.AreYouRefinancingYourPrimaryHome?.AreYouRefinancingYourPrimaryHome?",
  "propertyDetails.loanPurposeConditional.AreYouRefinancingYourPrimaryHome?.AreYouRefinancingFromACurrentFHALoanProgramToNewFHALoanProgram?.AreYouRefinancingFromACurrentFHALoanProgramToNewFHALoanProgram?",
  "JointCredit.Income/assets of a person other than the Borrower (including the borrower's spouse) will be used for loan qualification",
  "propertyDetails.AreYouRefinancingYourPrimaryHome?.WillYouSetAsideSpaceWithinThePropertyToOperateYourOwnBusiness?",
  "propertyDetails.PropertyUse.WillYouSetAsideSpace?",
  "Income/assets of a person other than the Borrower (including the borrower's spouse) will be used for loan qualification.",
  "ApplicantDetails.BorrowerDetails.MaritalStatus.IsThereAPersonWhoIsNotYourLegal.IsThereAPersonWhoIsNotYourLegal",
  "ApplicantDetails.CoBorrowerDetails.MaritalStatus.IsThereAPersonWhoIsNotYourLegal.IsThereAPersonWhoIsNotYourLegal",
  "ApplicantDetails.BorrowerDetails.DidYou(or your deceased spouse)EverServe,OrAreYouCurrentlyServing,InTheUnitedStatesArmedForces?.DidYou(or your deceased spouse)EverServe,OrAreYouCurrentlyServing,InTheUnitedStatesArmedForces?",
  "ApplicantDetails.CoBorrowerDetails.DidYou(or your deceased spouse)EverServe,OrAreYouCurrentlyServing,InTheUnitedStatesArmedForces?.DidYou(or your deceased spouse)EverServe,OrAreYouCurrentlyServing,InTheUnitedStatesArmedForces?",
  "Address.BorrowerAddress.MailingAddress.MailingAddress",
  "Address.CoBorrowerAddress.MailingAddress.MailingAddress",
  "ManageYourLiabilities.Liability.0.PayOff",
  "ManageYourLiabilities.Liability.0.SecuredBySubjectProperty",
  "Declarations.ApplicantDeclarations.BorrowerDeclarations.M.M",
  "Declarations.ApplicantDeclarations.BorrowerDeclarations.a.a",
  "Declarations.ApplicantDeclarations.BorrowerDeclarations.b.b",
  "Declarations.ApplicantDeclarations.BorrowerDeclarations.c.c",
  "Declarations.ApplicantDeclarations.BorrowerDeclarations.d1.d1",
  "Declarations.ApplicantDeclarations.BorrowerDeclarations.d2.d2",
  "Declarations.ApplicantDeclarations.BorrowerDeclarations.E.E",
  "Declarations.ApplicantDeclarations.BorrowerDeclarations.F.F",
  "Declarations.ApplicantDeclarations.BorrowerDeclarations.G.G",
  "Declarations.ApplicantDeclarations.BorrowerDeclarations.H.H",
  "Declarations.ApplicantDeclarations.BorrowerDeclarations.I.I",
  "Declarations.ApplicantDeclarations.BorrowerDeclarations.J.J",
  "Declarations.ApplicantDeclarations.BorrowerDeclarations.K.K",
  "PayOff",
  "subjectPropertyIndicator",
  "BorrowerAddress.BorrowerCurrentAddress.MailingAddress.MailingAddress",
  "Declarations.ApplicantDeclarations.BorrowerDeclarations.L.L",
  "Declarations.ApplicantDeclarations.BorrowerDeclarations.a.hadAnownershipIntrest.hadAnownershipIntrest",
  "deposited",

  "Declarations.ApplicantDeclarations.CoBorrowerDeclarations.M.M",
  "Declarations.ApplicantDeclarations.CoBorrowerDeclarations.a.a",
  "Declarations.ApplicantDeclarations.CoBorrowerDeclarations.b.b",
  "Declarations.ApplicantDeclarations.CoBorrowerDeclarations.c.c",
  "Declarations.ApplicantDeclarations.CoBorrowerDeclarations.d1.d1",
  "Declarations.ApplicantDeclarations.CoBorrowerDeclarations.d2.d2",
  "Declarations.ApplicantDeclarations.CoBorrowerDeclarations.E.E",
  "Declarations.ApplicantDeclarations.CoBorrowerDeclarations.F.F",
  "Declarations.ApplicantDeclarations.CoBorrowerDeclarations.G.G",
  "Declarations.ApplicantDeclarations.CoBorrowerDeclarations.H.H",
  "Declarations.ApplicantDeclarations.CoBorrowerDeclarations.I.I",
  "Declarations.ApplicantDeclarations.CoBorrowerDeclarations.J.J",
  "Declarations.ApplicantDeclarations.CoBorrowerDeclarations.K.K",
  "Declarations.ApplicantDeclarations.CoBorrowerDeclarations.L.L",
  "Declarations.ApplicantDeclarations.CoBorrowerDeclarations.a.hadAnownershipIntrest.hadAnownershipIntrest",

];
