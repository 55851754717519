const EConsent = (props) => {
  const dateFormatOptions = { weekday: 'long', month: 'long', day: 'numeric', year: 'numeric' };
  const currentDate = new Date().toLocaleDateString('en-US', dateFormatOptions);

  return (
    <div>
      <h4>e-consent Agreement</h4>
      <h5>{props?.coBorrowerFullName}</h5>
      <h5>Your Agreement</h5>
      <p>
        <b>EFFECTIVE DATE</b> : This E-Consent Agreement is Effective as of {currentDate}.
      </p>
      <p>
        <b>GENERAL</b>
        <br />
        You have indicated that you wish to receive and sign the documents
        relating to your mortgage loan application, closing disclosures and
        other mortgage-related communications electronically using the online
        electronic record delivery software ("eDelivery Platform") and the
        integrated electronic signature software ("eSign Platform") that we make
        available to you (collectively, the "Platform"). We are required by law
        to give you certain information "in writing" - which means that you are
        entitled to receive it on paper. However, with your consent, we may
        instead provide this information to you electronically. This Electronic
        Consent Agreement (this "E-Consent") sets forth important information
        about conducting business electronically, electronic delivery of
        information, and signing documents electronically. By providing your
        consent below, you are agreeing that you have reviewed this E-Consent
        and you agree to transact business with us using electronic
        communications. Your consent also permits the general use of electronic
        records and electronic signatures in lieu of paper documents. You are
        not required to receive or sign documents electronically and you
        acknowledge and agree that electronic signatures are equivalent and
        equally binding as traditional signatures. If you do not consent to the
        use of electronic records and signatures, you will be unable to proceed
        electronically. You will need to contact the person who sent you the
        invitation to review and/or sign electronically and arrange to complete
        the transaction using paper documents, or you may not be able to proceed
        with the transaction at all. If permitted to complete the transaction on
        paper, you may be required to pay a reasonable fee. If you are a notary
        using the eSign Platform to notarize Communications signed by others,
        this fee shall not apply to you. In this E-Consent, the words "we,"
        "us," and "our" refer to the entity whose representative invited you to
        electronically receive, review and/or sign documents using the Platform,
        and may consist of the applicable mortgage broker(s), loan processor(s)
        or mortgage banker(s) with whom you are transacting business for one or
        more loans. The words "you" and "your" means the person giving consent.
        We recommend you read this E-Consent in its entirety to ensure you are
        fully informed. After you have read this E-Consent in its entirety, if
        you agree to the general use of electronic records and electronic
        signatures in connection with use of the Platform, please click on the
        "Agree" button below. SCOPE OF CONSENT By clicking on "Agree" below, you
        are agreeing to receive via electronic means all disclosures, notices,
        agreements, undertakings, records, documents and other information that
        we provide to you or that you sign, submit, or agree to at our request
        on the Platform (each a "Communication"). You agree that the
        Communications may be in electronic form. We may also use electronic
        signatures and initials and obtain them from you on any Communication,
        as well as use other functionality to obtain information from you for
        any Communication. You agree that all actions taken and information
        provided by you with respect to Communications displayed on the Platform
        will constitute your agreement, acceptance or acknowledgement of the
        applicable terms of the Communication to which such actions are applied.
        Actions taken by you on the Platform may consist of, for example, the
        application of your electronic signature or initials, or selections made
        or information provided by you through use of electronic functionality
        (such as a checkbox, dropdown menu, textbox or radio button). All such
        actions have the same legal effect as if you had taken such actions and
        provided such information using pen and ink on paper. All Communications
        that we deliver to you in electronic form will be delivered either (1)
        via email; (2) via the eDelivery Platform; or (3) by your accessing a
        Platform website link that we will designate in an email, text message,
        or other electronic notice we send to you at the time the information is
        available. We will establish security procedures that you will have to
        follow to access the Platform and the Platform website. We may always,
        in our sole discretion, provide you with any Communications on paper,
        even if you have authorized electronic delivery. Sometimes the law, or
        our agreement with you, requires you to give us a written notice. You
        must still provide these notices to us on paper, unless we tell you how
        to deliver the notice to us electronically.
      </p>
      <p>
        <b>TERMINATION/CHANGES TO E-CONSENT</b>
        <br />
        We reserve the right, in our sole discretion, to discontinue the
        provision of electronic Communications, or to terminate or change the
        terms and conditions on which we provide electronic Communications. We
        will provide you with notice of any such termination or change as
        required by law.
      </p>
      <p>
        <b>REQUESTING PAPER COPIES</b>
        If a Communication required for your loan transaction is not available
        in electronic form, a paper copy will be provided to you free of charge.
        You will also have the opportunity to download, print out and retain a
        copy of all electronic Communications you receive, review and/or sign
        using the Platform. You may access the eDelivery Platform to print
        and/or download a copy of the Communications you received, reviewed
        and/or signed. Also, when new Communications are available on the
        eDelivery Platform, and when you review and/or sign electronic
        Communications using the eSign Platform, we will send to you, at the
        email address you previously provided to us, a secure link that will
        enable you to access a copy of the Communications. Except as described
        above, we will not send you a paper copy of electronic Communications
        unless you request it. You can obtain a paper copy of any Communication
        we provide to you electronically by downloading it yourself from the
        eDelivery Platform or by requesting that we mail you a paper copy.
        Request for paper copies must be made within a reasonable time after we
        first provided the electronic Communication to you.
        <br />
        &nbsp;
        <br /> To request a paper copy, contact us at:
        <br />
        <br /> Phone: 201-490-4232
        <br />
        <br />
        Mailing Address: 115 West Century Road Suite 115, Paramus, NJ 07652
        <br />
        You will not be required to pay a fee for receiving paper copies of the
        Loan Documents. If you are a notary using the eSign Platform to notarize
        Communications signed by others, this section ("Requesting Paper
        Copies") shall not apply to you.
      </p>
      <p>
        <b>RETAINING COPIES</b>
        <br />
        We encourage you to print or download for your records a copy of all
        electronic Communications, as well as this E-Consent disclosure and any
        other document that is important to you.
      </p>
      <p>
        <b>HOW TO WITHDRAW CONSENT</b>
        <br />
        You may withdraw your consent to receive Communications electronically
        at any time.
        <br />
        In order to withdraw your consent while you are on the eDelivery
        Platform, you must click the Tasks tab, then click "Change Electronic
        Consent" and click "Don't Agree."
        <br /> <br /> Alternatively, you may notify us that you wish to withdraw
        your consent by: <br /> <br />
        Phone: 201-490-4232 <br /> <br />
        Mailing Address: 115 West Century Road Suite 115, Paramus, NJ 07652{" "}
        <br /> <br />
        Withdrawing your consent may delay your transactions with us. If you
        withdraw your consent before all Communications are reviewed and/or
        signed, you will be unable to proceed electronically using the Platform.
        You may be required to review and/or execute Communications via paper,
        or you may be unable to complete the transaction at all. You may also be
        required to pay a reasonable fee to complete your transaction on paper.
        If you are a notary using the eSign Platform to notarize Communications
        signed by others, this fee shall not apply to you. All actions taken by
        you before withdrawing your consent, including the signing of
        Communications via electronic means, will remain valid and fully
        enforceable. Your withdrawal of consent with respect to the electronic
        Communications does not affect any other consent you have given us at
        any other time to use electronic records and signatures. A withdrawal of
        your consent to receive electronic Communications will be effective only
        after we have had a reasonable period of time to process your
        withdrawal.
      </p>
      <p>
        <b>UPDATING YOUR CONTACT INFORMATION</b>
        <br />
        <br />
        It is your responsibility to provide us with an accurate and complete
        email address and other contact information, and to maintain and update
        promptly any changes in this information.
        <br />
        <br /> You can update your contact information at any time by contacting
        our representative that invited you to review Communications on the
        Platform or by contacting us at:
        <br />
        <br />
        Phone: 201-490-4232
        <br />
        <br />
        Mailing Address: 115 West Century Road Suite 115, Paramus, NJ 07652
        <br />
        <br />
        We are not liable for your failure to receive notification of the
        availability of electronic Communications if your email address on file
        is invalid; your email or Internet service provider filters the
        notification as "spam" or "junk mail;" there is a malfunction in your
        computer, browser, Internet service and/or software; or for other
        reasons beyond our control.
      </p>
      <p>
        <b>REQUIRED HARDWARE/SOFTWARE</b>
        <br />
        To create and receive electronic Communications using the Platform, you
        must have access to:
        <br />
        <br />
        <ul>
          <li>
            A Current Version (defined below) of an Internet browser we support;
          </li>
          <li>A connection to the Internet;</li>
          <li>
            A Current Version of a program that accurately reads and displays
            PDF files;
          </li>
          <li>A Current Version of an email management program;</li>
          <li>
            A device (e.g., a computer, tablet, laptop, etc.) and an operating
            system capable of supporting all of the above; and
          </li>
          <li>
            If you are participating in a remote notarization session, you must
            have, and your device and operating system must support, a web
            camera, microphone and speakers.
          </li>
        </ul>
        <br />
        <br />
        You will also need a printer if you wish to print out and retain records
        on paper, and electronic storage if you wish to retain records in
        electronic form. You must also have an active email address and an
        alternate means of second factor authentication. By "Current Version,"
        we mean a version of the software that is stable and is currently
        supported by its publisher. From time to time, we may offer services or
        features that require your internet browser be configured in a
        particular way, such as permitting the use of JavaScript or cookies. If
        we detect that your internet browser is not properly configured, we may
        provide you with a notice and advice on how to update your
        configuration. We reserve the right to discontinue support of a Current
        Version of software if, in our sole opinion, it suffers from a security
        flaw or other flaw that makes it unsuitable for use with the Platform.
        If the hardware or software requirements for the Platform change, and
        that change would create a material risk that you would not be able to
        access or retain electronic Communications, the changes will be
        described in an updated version of this E-Consent which will be made
        available to you when you next access the Platform. You may then elect
        to consent to such updated E-Consent and the revised requirements
        specified therein before receiving, viewing and/or executing any other
        Communications using the Platform. If you choose to withdraw your
        consent upon notification of the change, you will be able to do so
        without penalty and paper copies of such Communications will be mailed
        to you.
      </p>
      <p>
        <b> AGREEMENT</b>
        <br />
        <br />
        By clicking "Agree" you are agreeing to this E-Consent and consenting to
        the use of electronic records and signatures in connection with the
        Communications and your use of the Platform, and you are also confirming
        that:
        <br />
        <br />
        <ul>
          <li>
            You can access and read this E-Consent and you understand this
            E-Consent.
          </li>
          <li>You have the hardware and software described above.</li>
          <li>You are able to receive and review electronic records.</li>
          <li>
            You have an active email account and a cellphone number for SMS
            messaging, each of which you have disclosed to us.
          </li>
          <li>You have the ability to access and view PDF files.</li>
          <li>
            You acknowledge that electronic signatures and records will be used
            in place of written documents and handwritten signatures with
            respect to the electronic Communications and your use of the
            Platform.
          </li>
        </ul>
      </p>
      <br />
      <br />
      <p>
        Please click the button at the bottom of the page to consent to do
        business electronically and to view Loan Documents electronically.
      </p>
    </div>
  );
};

export default EConsent;
