import TextfieldComponent from "./TextfieldComponent";
import NumberComponent from "./NumberComponent";
import CheckboxComponent from "./CheckboxComponent";
import DatePickerComponent from "./DatePickerComponent";
import RadioButtonsGroup from "./RadioButtonsGroup";
import AccordionComponent from "./AccordionComponent";
import MaskComponent from "./MaskComponent";
import StackButtons from "./stackButtons";
import FormStepper from "./FormStepper";
import DropdownComponent from "./DropdownComponent";
import ButtonComponent from "./ButtonComponent";
import ScrollingTextWidget from './widget';
import ConsentButtons from './EConsentStackButtons'
export const Controls = {
  TextfieldComponent,
  NumberComponent,
  CheckboxComponent,
  DatePickerComponent,
  RadioButtonsGroup,
  AccordionComponent,
  MaskComponent,
  StackButtons,
  FormStepper,
  DropdownComponent,
  ButtonComponent,
  ScrollingTextWidget,
  ConsentButtons
};
