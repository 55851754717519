import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { mapDispatchToProps, mapStateToProps } from "../../helpers";
import "./save_grid.css";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { DialogTitle, Divider } from "@mui/material";
import { useSelector } from "react-redux";
import CoApplicant from "./coapplicant/coapplicant";
import _ from "lodash";

export const CustomDialog = ({ open, onClose, onSubmit, content }) => (
  <Dialog
    fullWidth
    maxWidth="xs"
    open={open}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">
      Alert
      <Divider />
    </DialogTitle>
    <DialogContent>
      <DialogContentText className="contentClassName">
        {content}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button className="inActiveNoButton" onClick={onClose}>
        No
      </Button>
      <Button className="activeYesButton" onClick={onSubmit}>
        Yes
      </Button>
    </DialogActions>
  </Dialog>
);

const SaveGrid = (props) => {
  const [state, setState] = React.useState({
    coapplicant: false,
    loanSubmit: false,
    showCoApplicant: false,
  });
  const errorDictionary = useSelector(state => state.userReducer.errorsDictionary);
  const errorsPresent = errorDictionary && Object.keys(errorDictionary).length > 0;

  const handleClickOpen = () => {
    setState({ ...state, coapplicant: true });
  };

  const handleCloseFirstDialog = () => {
    setState({ showFirstDialog: false, loanSubmit: false });
    props.CoApplicantDialogueOpen();
  };

  const handleClose = () => {
    setState({ ...state, loanSubmit: true, coapplicant: false });
  };

  const loanSubmitHandleClose = () => {
    setState({ ...state, loanSubmit: false });
  };

  let stepperLength = localStorage.getItem("stepperLength");
  useEffect(() => {
    if (props.stepperBackStatus && stepperLength) {
      props.activeStep(parseInt(stepperLength) - 1);
    }
  }, [stepperLength]);

  const returnButtonClass = (data) => {
    if (data || errorsPresent) return "disable_button";
    else return "next_button";
  };

  const nextHandler = () => {
    let activeStep = props.reduxActiveStep;
    props.stepperBackStep(false);
    if (activeStep < parseInt(stepperLength) - 1 && stepperLength !== -1) {
      if (!errorsPresent) {
          // Move to the next step only if there are no errors
          props.activeStep(activeStep + 1);
          if (props.pageIndex === 2) {
              // Additional checks based on which specific step is reached
              if (activeStep + 1 === 1 || activeStep + 1 === 3 || activeStep + 1 === 4) {
                  props.isNextEnable(activeStep + 1);
              }
          }
      }
  } else {
      // Handling the case where the steps are completed
      props.activeStep(0);
      localStorage.setItem("stepperLength", "-1");
      if (!errorsPresent) {
        props.setPageIndex(props.pageIndex + 1);
        props.saveApiCall();
      }
  }
  };
  const backHandler = () => {
    let activeStep = props.reduxActiveStep;
    props.stepperBackStep(true);
    if (!errorsPresent) {
      if (activeStep > 0) {
          props.activeStep(activeStep - 1);
          // Adjust next button enabling based on new step
          if (props.pageIndex === 2) {
              if (activeStep - 1 === 1 || activeStep - 1 === 3 || activeStep - 1 === 4) {
                  props.isNextEnable(activeStep - 1);
              }
          }
      } else {
          // Handle case where the active step is already 0
          props.setPageIndex(props.pageIndex - 1);
      }
  } 
  };
  
  const loanSubmit = () => {
    setState({ ...state, loanSubmit: true, coapplicant: false });
  };

  return (
    <div>
      <div
        className={
          props.pageIndex === 2 && props.reduxActiveStep === 0
            ? "save_grid_main"
            : "save_grid_main_div"
        }
      >
        {props.pageIndex === 2 && props.reduxActiveStep === 0 ? null : (
          <Button className="back_button" onClick={() => backHandler()}>
            Back
          </Button>
        )}

        <div className="save_next_buttons_div">
          {/* <Button className={returnButtonClass(props.disable)} onClick={() => props.saveApiCall()}
            disabled={props.disable}>
            Save
          </Button> */}
          {props.pageIndex != 8 ? (
            <Button
              className={returnButtonClass(props.disable)}
              onClick={() => nextHandler()}
              disabled={props.disable}
            >
              Next
            </Button>
          ) : (
            <Button
              className={returnButtonClass(props.disable)}
              onClick={props.finishCheck ? loanSubmit : handleClickOpen}
              disabled={props.disable}
            >
              Submit
            </Button>
          )}
        </div>
      </div>
      <CustomDialog
        open={state.coapplicant}
        onClose={handleClose}
        content="Do you want to add new co-applicants details?"
        onSubmit={handleCloseFirstDialog}
      />
      <CustomDialog
        open={state.loanSubmit}
        onClose={() => {
          loanSubmitHandleClose();
        }}
        onSubmit={() => {
          loanSubmitHandleClose();
          props.submitLoanApiCallFunc();
        }}
        content="Do you want to submit the loan?"
      />
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SaveGrid);
