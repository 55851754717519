import React, {useEffect, useState} from "react";
import JsonSchemaForms from "../../../json_schema_forms";
import expenses from "./expenses.json";
import {connect} from "react-redux";
import _ from "lodash";
import {mapDispatchToProps, mapStateToProps} from "../../../helpers";
import list_Of_Liabilities from "../liabilities/liabilities.json";

function Expenses(props) {
  const [state, setState] = useState({ Expenses: {} });
  const [jsonSchema, setJsonSchema] = React.useState(_.cloneDeep(expenses));

  useEffect(()=>{
    return ()=>{
      setJsonSchema(_.cloneDeep(_.cloneDeep(expenses)))}
  },[])
  useEffect(() => {
    if (!_.isEmpty(props.componentState)) {
      setState(props.componentState);
    }
  }, [props.componentState]);

  useEffect(() => {
    if (props.componentSchema?.Expenses) {
      setJsonSchema(props.componentSchema["Expenses"]);
    }
  }, [props.componentSchema]);

  const onLoadFunc = (updatedSchema) => {
    props.copySchema(updatedSchema, "Expenses")
  }

  const changeHandler = (updatedState, updatedSchema) => {
    let firstMrtgage = parseFloat(updatedState?.Expenses["FirstMortgage(P&I)"]);
    let hazrdInsurance = parseFloat(updatedState?.Expenses?.HazardInsurance);
    let mrtgageInsu = parseFloat(updatedState?.Expenses?.MortgageInsurance);
    let realEstTax = parseFloat(updatedState?.Expenses?.RealEstateTaxes);
    let secondMrtgage = parseFloat(updatedState?.Expenses["SecondMortgage(P&I)"]);
    let supplMenInsu = parseFloat(updatedState?.Expenses?.SupplementalInsurance);
    updatedState["Expenses"]["Total"] =  _.sum([
      firstMrtgage ? firstMrtgage : 0.00,
      hazrdInsurance ? hazrdInsurance : 0.00,
      mrtgageInsu ? mrtgageInsu : 0.00,
      realEstTax ? realEstTax : 0.00,
      secondMrtgage ? secondMrtgage : 0.00,
      supplMenInsu ? supplMenInsu : 0.00
    ]);
    props.copyStateToRedux(updatedState);
    props.copySchema(updatedSchema, "Expenses");
    setState(updatedState);
  };

  return (
    <JsonSchemaForms
      state={state}
      changeHandler={changeHandler}
      jsonData={jsonSchema}
      pageKey={"Expenses"}
      onLoadFunc={onLoadFunc} />
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Expenses);
