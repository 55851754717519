import * as React from "react";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LongMenu from "../../components/LongMenu";
import { getUserEmail, mapDispatchToProps, mapStateToProps } from "../../helpers";
import { connect } from "react-redux";
function ProfileCard(props) {
  let username;
  React.useEffect(() => {
    username = localStorage.getItem("user_email")
  }, [props.getLoansDataRes])
  return (
    <div className="flexAlignRowClassName">
      <div className="mainHeaderDiv" style={{width:'100%'}}>
        <div className="iconDiv">
          <span>
            <AccountCircleIcon style={{ fontSize: "38px", marginTop: "4px" }} /> 
          </span>
          <span>
            <div style={{  color: "#000",textTransform: "capitalize"}}>{localStorage.getItem("username")}</div>
            <div style={{  color: "#000",fontSize: "12px" }}>
              {getUserEmail()}
            </div>
          </span>
          <span style={{ marginLeft: getUserEmail() ? "0px": "170px"}}>
          <LongMenu logout={() => props.signOutClickHandler()}/>
          </span>
        </div>
      </div>
      {/* &nbsp;&nbsp; */}
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileCard);
export {ProfileCard};
