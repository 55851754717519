import React from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete"; // Import DeleteIcon
import { Accordion, Grid, AccordionDetails } from "@mui/material";
import { ArrowRightOutlined } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import "./components.css";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordionSummary from "@mui/material/AccordionSummary";

import "./components.css";

const AccordionComponent = (props) => {
  const AccordionSummary = styled((prop) => (
    <MuiAccordionSummary
      expandIcon={
        <ArrowRightOutlined
          style={{ fontSize: "2rem", color: "#00634A", cursor: "pointer" }}
        />
      }
      {...prop}
    />
  ))(({ theme }) => ({
    flexDirection: "row-reverse",
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
      transform: "rotate(90deg)",
    },
    "& .MuiAccordionSummary-content": {
      marginLeft: theme.spacing(1),
    },
  }));

  const triggerAccordionDelete = (e) => {
    e.stopPropagation(); // Prevent event from bubbling up to the Accordion
    props.manageArray(); // Implement delete functionality here
  };

  let defaultExpand = props.name.startsWith("Borrower")

  return (
    <Grid item md={6} style={{ width: "100%", marginBottom: "8px" }}>
      <Accordion
        key={props.name}
        defaultExpanded={defaultExpand}
        disableGutters
        className="summaryAccordionRootDiv"
        style={{
          margin: 0,
        }}
        TransitionProps={{ unmountOnExit: false }}
      >
        <AccordionSummary
          className={
            props.name === " E Consent Agreement"
              ? "accordionSummaryMainRootTag2"
              : "accordionSummaryMainRootTag"
          }
          key={props.name}
        >
          {props.name === " E Consent Agreement" ? (
            <span
              className={
                props.name === " E Consent Agreement"
                  ? "accordionSummaryMainRootTag2"
                  : "accordionSummaryMainRootTag"
              }
            >
              eConsent Agreement
            </span>
          ) : (
            <span
              className={
                props.name === " E Consent Agreement"
                  ? "accordionSummaryMainRootTag2"
                  : "accordionSummaryMainRootTag"
              }
            >
              {props.name}
            </span>
          )}
          {props.buttonName !== undefined && !props.summary && (
            <div className="deleteIconDiv">
              <DeleteIcon
                className="deleteIconClassName"
                style={{ color: "red" }}
                onClick={triggerAccordionDelete} // delete functionality
              />
            </div>
          )}

          {props.edit === true ? (
            <div className="summaryEditButtonDiv">
              <EditIcon
                className="logoutIcon editIconClassName"
                onClick={(e) => props.onClick(e)}
              />
            </div>
          ) : null}
        </AccordionSummary>
        <AccordionDetails className="formElementsAccordionDetailsDiv">
          {props.data}
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};
export default AccordionComponent;
