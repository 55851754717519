import React from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import HelpIcon from '@mui/icons-material/Help';
import LogoutIcon from '@mui/icons-material/PowerSettingsNew';
import { ListItemIcon } from "@mui/material";

export default function LongMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (option) => {
    setAnchorEl(null);
    if (option === 'Help') {
      window.open('https://bondstreetmortgage.atlassian.net/wiki/external/MzJkMGJhYzQ2ODNjNGY0NWFkNTQ3NGQyZGVmZjkyOGY', '_blank');
    } else if (option === 'Logout') {
      props.logout();
    }
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        onClick={handleClick}
        aria-controls={open ? "account-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
      >
        <ArrowDropDownIcon data-testid="arrowdropdown" style={{ color: "#00634a" }} />
      </IconButton>
      <Menu
        id="long-menu"
        data-testid="menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        className="menu"
        anchorEl={anchorEl}
        open={open}
        onClose={() => handleClose(null)}
        PaperProps={{
          elevation: 0,
          style: {
            marginTop: 15,
            width: "15ch",
          },
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={() => handleClose('Help')}>
          <HelpIcon fontSize="small" /> Get help
        </MenuItem>
        <MenuItem onClick={() => handleClose('Logout')}>
          <ListItemIcon>
            <LogoutIcon fontSize="small" />Logout
          </ListItemIcon>
        </MenuItem>
      </Menu>
    </div>
  );
}
