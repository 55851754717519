import { useMsal } from "@azure/msal-react";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
// import { tour } from "../tour";
import SessionTimeoutDialog from "./SessionTimeoutDialog";


/**
 * Starts a session Timer , alerts the user five minutes before the token_expiry . If user chooses to
 * extend session extendSession call is made
 * @param logout
 * @param extendSession
 * @returns {JSX.Element}
 * @constructor
 */
const SessionTimeout = ({ logout, extendSession }) => {
  const [timeoutModalOpen, setTimeoutModalOpen] = useState(false);
  const [checkSessionTimer, setCheckSessionTimer] = useState(0);//to handle page refresh scenarios
  const [timerActive, setTimerActive] = useState(true);
  const tokenExpiryDate = localStorage.getItem("token_expiry");
  let interval;
  useEffect(() => {

    interval = null;
    let currentDate=(new Date().getTime() / 1000);
    const token_expiry=localStorage.getItem("token_expiry");
    if (token_expiry!==null && (token_expiry- currentDate)<300) {
      setTimeoutModalOpen(true);
      setTimerActive(false);
    }
    if (tokenExpiryDate < currentDate) {
      setTimeoutModalOpen(false);
      // tour.cancel();
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Oops...",
        text: "Session expired, login again",
      }).then((result)=>{
            if(result.isConfirmed){
              logout();
            }
      }
      )
    }
    if (timerActive) {
      interval = setTimeout(() => {
        setCheckSessionTimer(checkSessionTimer+5);
      }, 5000);
    }

    return () => {
      clearTimeout(interval);
    };
  }, [checkSessionTimer]);


  const handleLogout = async () => {
    try {
      setTimeoutModalOpen(false);
      logout();
    } catch (err) {
      console.error(err);
    }
  };
  const handleContinue = () => {
    extendSession();
    setTimeoutModalOpen(false);
    setTimerActive(true);
    setCheckSessionTimer(0);
  };

  return (
    <SessionTimeoutDialog
      onContinue={handleContinue}
      onLogout={() => handleLogout()}
      open={timeoutModalOpen}
    />
  );
};
export default SessionTimeout;
