import React, { useEffect } from "react";
import JsonSchemaForms from "../../../json_schema_forms";
import loan_details from "./loanDetails.json";
import { connect } from "react-redux";
import {
  mapDispatchToProps,
  mapStateToProps,
  setValueByRecursion,
} from "../../../helpers";
import _ from "lodash";

function LoanDetails(props) {
  const [state, setState] = React.useState({ purposeOfLoan: {} });
  const [jsonSchema, setJsonSchema] = React.useState(_.cloneDeep(loan_details));

  useEffect(() => {
    return () => {
      setJsonSchema(_.cloneDeep(loan_details));
    };
  }, []);
  useEffect(() => {
    if (!_.isEmpty(props.componentState)) {
      setState(props.componentState);
    }
  }, [props.componentState]);

  useEffect(() => {
    if (props.componentSchema?.loanDetails) {
      setJsonSchema(props.componentSchema["loanDetails"]);
    }
  }, [props.componentSchema]);

  useEffect(() => {
    let propertyType =
      props.componentState?.propertyDetails?.PropertyType?.PropertyType;
    let tempState = props.componentState;
    let tsum = {
      propertyType: "",
    };
    let loanProductData = {
      gsePropertyType: "",
    };
    switch (propertyType) {
      case "Single Family Residence":
        tsum.propertyType = "1 Unit";
        loanProductData.gsePropertyType = "Detached";
        break;
      case "2+ Units":
        tsum.propertyType = "2-4 Units";
        loanProductData.gsePropertyType = "Detached";
        break;
      case "Condominium":
        tsum.propertyType = "Condominium";
        loanProductData.gsePropertyType = "Condominium";
        break;
      case "Co-Operative":
        tsum.propertyType = "Cooperative";
        loanProductData.gsePropertyType = "Cooperative";
        break;
      case "Manufactured/Mobile Home (Single Wide)":
        tsum.propertyType = "Manufactured Housing Single Wide";
        loanProductData.gsePropertyType = "ManufacturedHousing";
        break;
      case "Manufactured/Mobile Home (Multiwide)":
        tsum.propertyType = "Manufactured Housing Multiwide";
        loanProductData.gsePropertyType = "ManufacturedHousing";
        break;
      case "Townhouse":
        tsum.propertyType = "PUD";
        loanProductData.gsePropertyType = "Attached";
        break;
      case "Single Family Residence with Home Owners Association":
        tsum.propertyType = "PUD";
        loanProductData.gsePropertyType = "PUD";
        break;
      default:
        tsum.propertyType = "";
        break;
    }
    tempState = setValueByRecursion(tempState, ["tsum"], (i) => tsum, [{}]);
    tempState = setValueByRecursion(tempState, ["loanProductData"], (i) => loanProductData, [{}]);
    props.copyStateToRedux(tempState);
  }, [props.componentState?.propertyDetails?.PropertyType?.PropertyType]);


  useEffect(() => {
    if (state?.propertyDetails?.loanPurposeConditional?.["HaveYouIdentifiedAHomeToPurchase?"]?.["HaveYouIdentifiedAHomeToPurchase?"] === "No") {
      delete state?.propertyDetails?.loanPurposeConditional?.["HaveYouIdentifiedAHomeToPurchase?"]?.["HaveYouSignedAContract?"];
      setState(state);
    }
    if (state?.purposeOfLoan?.["DoYouHaveASecondMortgage?"] !== "Yes") {
      delete state?.purposeOfLoan?.SecondMortgageAmount
      setState(state)
    }
  }, [state]);


  const calcDownPaymentAmountOrDownPaymentPercentage = (stateToUpdate) => {
    let estimatedPurchasePrice = _.get(
      stateToUpdate,
      ["purposeOfLoan", "EstimatedPurchasePrice"],
      "0"
    );
    estimatedPurchasePrice =
      estimatedPurchasePrice === "" ? 0 : parseInt(estimatedPurchasePrice);

    if (
      _.get(stateToUpdate, ["purposeOfLoan", "DownPaymentAmount"]) ===
      _.get(state, ["purposeOfLoan", "DownPaymentAmount"])
    ) {
      let dpPercentage = parseFloat(
        _.get(stateToUpdate, ["purposeOfLoan", "DownPaymentPercentage"], "0")
      );

      let downPaymentAmountCalcVal = (
        (dpPercentage * estimatedPurchasePrice) /
        100
      ).toFixed(2);

      stateToUpdate = setValueByRecursion(
        stateToUpdate,
        ["purposeOfLoan", "DownPaymentAmount"],
        (i) => downPaymentAmountCalcVal,
        [0]
      );
    }
    if (
      _.get(stateToUpdate, ["purposeOfLoan", "DownPaymentPercentage"]) ===
      _.get(state, ["purposeOfLoan", "DownPaymentPercentage"])
    ) {
      let downpaymentAmount = parseFloat(
        _.get(stateToUpdate, ["purposeOfLoan", "DownPaymentAmount"], "0")
      );
      if (estimatedPurchasePrice > 0) {
        let downPaymentPercentageCalcVal = (
          (downpaymentAmount / estimatedPurchasePrice) *
          100.0
        ).toFixed(2);

        stateToUpdate = setValueByRecursion(
          stateToUpdate,
          ["purposeOfLoan", "DownPaymentPercentage"],
          (i) => downPaymentPercentageCalcVal,
          [0]
        );
      }
    }
    return stateToUpdate;
  };

  const onLoadFunc = (updatedSchema) => {
    props.copySchema(updatedSchema, "loanDetails");
  };

  const changeHandler = (updatedState, updatedSchema) => {
    let AreYouRefinancingYourPrimaryHome =
      updatedState?.propertyDetails?.loanPurposeConditional?.[
      "AreYouRefinancingYourPrimaryHome?"
      ]?.["AreYouRefinancingYourPrimaryHome?"];
    let LoanPurpose = _.get(updatedState, ["purposeOfLoan", "LoanPurpose"]);
    let PropertyUse_disability = false;
    const constructionMethodType = _.get(updatedState, ["propertyDetails", "PropertyType", "Construction Method Type"]);

    if (
      updatedState?.purposeOfLoan?.EstimatedPurchasePrice !==
      state.purposeOfLoan?.EstimatedPurchasePrice
    ) {
      updatedState.purposeOfLoan["EstimatedPropertyValue"] =
        updatedState.purposeOfLoan["EstimatedPurchasePrice"];
    }
    updatedState = calcDownPaymentAmountOrDownPaymentPercentage(updatedState);

    const loanAmount =
      _.get(updatedState, ["purposeOfLoan", "EstimatedPurchasePrice"]) -
      _.get(updatedState, ["purposeOfLoan", "DownPaymentAmount"], 0);

    if (loanAmount > 0) {
      updatedState = setValueByRecursion(
        updatedState,
        ["purposeOfLoan", "YourLoanAmount"],
        (i) => loanAmount,
        [0]
      );
    } else {
      delete _.get(updatedState, ["purposeOfLoan"])["YourLoanAmount"];
    }

    if (
      AreYouRefinancingYourPrimaryHome === "Yes" &&
      LoanPurpose !== "Purchase"
    ) {
      updatedState = setValueByRecursion(
        updatedState,
        ["propertyDetails", "PropertyUse", "PropertyUse"],
        (i) => "Primary Residence",
        []
      );
      PropertyUse_disability = true;
    } else {
      PropertyUse_disability = false;
    }

    if (!constructionMethodType?.hasOwnProperty("ConstructionMethodType")) {
      if (constructionMethodType) {
        delete constructionMethodType["ConstructionMethodDescription?"];
      }

      updatedState = setValueByRecursion(
        updatedState,
        ["propertyDetails", "PropertyType", "Construction Method Type"],
        (i) => constructionMethodType,
        []
      );
    }

    if (LoanPurpose === "Purchase") {
      delete _.get(updatedState, ["purposeOfLoan"])["DoYouWantToPayOffThisLoanWithTheNewLoan?"];
      delete _.get(updatedState, ["purposeOfLoan"])["SecondMortgageAmount"];
    }


    const SourceOfDownPayment = _.get(updatedState, [
      "purposeOfLoan",
      "SourceOfDownPayment",
      "SourceOfDownPayment",
    ]);
    const fhaGiftsRelatedValues = [
      "FHA - Gift - Seller Funded",
      "FHA - Gift - Non-seller Funded",
      "FHA - Gift - Gov't Assistance",
      "FHA - Gift - Source Relative",
      "FHA - Gift - Source Employer",
      "FHA - Gift - N/A",
      "Gift Funds",
    ];
    // Check if the LoanPurpose is "Purchase" and SourceOfDownPayment is fhaGiftsRelatedValues
    if (
      fhaGiftsRelatedValues.includes(SourceOfDownPayment)
    ) {
      // Do nothing, allow the Gift Amount to remain as-is
    } else {
      // Clear the Gift Amount field and ExplanationOfGift
      updatedState = setValueByRecursion(
        updatedState,
        ["purposeOfLoan", "SourceOfDownPayment", "GiftAmount"],
        (i) => "",
        [0]
      );

      updatedState = setValueByRecursion(
        updatedState,
        ["purposeOfLoan", "SourceOfDownPayment", "ExplanationOfGift"],
        (i) => "",
        [0]
      );
    }
    props.copyStateToRedux(updatedState);
    setState(updatedState);
    updatedSchema = setValueByRecursion(
      updatedSchema,
      [
        "properties",
        "propertyDetails",
        "properties",
        "PropertyUse",
        "properties",
        "PropertyUse",
        "disable",
      ],
      (i) => PropertyUse_disability,
      []
    );
    props.copyStateToRedux(updatedState);
    setState(updatedState);
    if (updatedState?.purposeOfLoan?.EstimatedPropertyValue != '') {
      delete props.errorsDictionary["EstimatedPropertyValue"];
      updatedSchema = setValueByRecursion(
        updatedSchema,
        [
          "properties",
          "purposeOfLoan",
          "properties",
          "EstimatedPropertyValue",
          "error",
        ],
        (i) => false,
        []
      );
    }
    if (updatedState?.purposeOfLoan?.EstimatedPurchasePrice > 0) {
      updatedSchema = setValueByRecursion(
        updatedSchema,
        [
          "properties",
          "purposeOfLoan",
          "properties",
          "DownPaymentAmount",
          "maximum",
        ],
        (i) => parseInt(updatedState?.purposeOfLoan?.EstimatedPurchasePrice),
        []
      );
    }
    props.copySchema(updatedSchema, "loanDetails");
  };
  return (
    <JsonSchemaForms
      state={state}
      jsonData={jsonSchema}
      changeHandler={changeHandler}
      pageKey={"LoanDetails"}
      onLoadFunc={onLoadFunc}
    />
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(LoanDetails);
