
import { Button } from "@mui/material";

const ButtonComponent = (props) => {
  const { name,color } = props;
  return (
    <div style={{width: '100%', marginTop: 10, marginBottom: 10}}>
      <Button
        style={{
          backgroundColor: color,
          contrastText: "#fff",
          textTransform: "none"
        }}
        variant="contained"
        onClick={(e) => props.manageArray(e)}
      >
        {name}
      </Button>
    </div>
  );
};

export default ButtonComponent;
