import _, { isEmpty } from "lodash";
import {getCookie, mapStateToObject} from "../../helpers";
import Swal from "sweetalert2";

export const currentPageIndex = () => {
  let appId = localStorage.getItem("appId");
  let currentPageCookie = appId
    ? getCookie(appId + "_pageIndex")
    : getCookie("pageIndex");
  if (appId === undefined && currentPageCookie === undefined)
    return  2;
  else return currentPageCookie;
};
export const submitCall = (props,loanObject) => {
  loanObject = mapStateToObject(props.componentState)
  let uniqueId = localStorage.getItem("appId")
    ? localStorage.getItem("appId")
    : null;
  const { showLoader, submitLoan, showToast } = props;
  const data = {
    org: {
      orgId: "ORG2",
      instanceId: "TEBE123456",
      orgName: "A",
    },
    loan: {
      loanData: {
        rawData:JSON.stringify(loanObject),
         uniqueId: uniqueId,
      },
    },
  };
  if (_.isEmpty(props.componentState)) {
    showToast("Please Enter Details", "error");
    return;
  }
  if (isEmpty(props.errorsDictionary)) {
    showLoader(true);
    submitLoan(data);
  }
  else {
    let errorString = "Please rectify the errors:\n";
    Object.entries(props.errorsDictionary).forEach(([key, value]) => {
      errorString = (
        <div style={{ whiteSpace: "pre" }}>
          <span>{errorString}</span>
          <span>
            {key} : {value}
          </span>
        </div>
      );
    });
    showToast(errorString, "error", 8000);
    showLoader(false);
  }
};

export const coappsubmitCall = (props,loanObject) => {
  loanObject = mapStateToObject(props.componentState);
  let loanGuid = localStorage.getItem("loanGuid");

  let uniqueId = localStorage.getItem("appId")
    ? localStorage.getItem("appId")
    : null;
  const { showLoader, coappsubmitLoan, showToast } = props;
  const data = {
    org: {
      orgId: "ORG2",
      instanceId: "TEBE123456",
      orgName: "A",
    },
    loan: {
      loanData: {
        rawData:JSON.stringify(loanObject),
         uniqueId: uniqueId,
      },
    },
  };
  if (_.isEmpty(props.componentState)) {
    showToast("Please Enter Details", "error");
    return;
  }
  if (isEmpty(props.errorsDictionary)) {
    showLoader(true);
    coappsubmitLoan(data,loanGuid);
  }
  else {
    let errorString = "Please rectify the errors:\n";
    Object.entries(props.errorsDictionary).forEach(([key, value]) => {
      errorString = (
        <div style={{ whiteSpace: "pre" }}>
          <span>{errorString}</span>
          <span>
            {key} : {value}
          </span>
        </div>
      );
    });
    showToast(errorString, "error", 8000);
    showLoader(false);
  }
};
export const saveLoanCall = (props, loanObject) => {
  loanObject = mapStateToObject(props.componentState)
  let uniqueId = localStorage.getItem("appId")
    ? localStorage.getItem("appId")
    : null;
  const {
    showLoader,
    saveLoanData,
    showToast,
    componentState,
    errorsDictionary
  } = props;
  const data = {
    org: {
      orgId: "ORG2",
      instanceId: "TEBE123456",
      orgName: "A",
    },
    loan: {
      loanData: {
        rawData: JSON.stringify(loanObject),
        uniqueId: uniqueId,
      },
    },
  };
  if (_.isEmpty(errorsDictionary)) {
    showLoader(true);
    saveLoanData(data);
  } else {
    let errorString = "Please rectify the errors:\n";
    Object.entries(errorsDictionary).forEach(([key, value]) => {
      errorString = (
        <div style={{ whiteSpace: "pre" }}>
          <span>{errorString}</span>
          <span>
            {key} : {value}
          </span>
        </div>
      );
    });
    showToast(errorString, "error", 8000);
    showLoader(false);
  }
};


