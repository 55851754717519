import React from 'react';
import MuiAppBar from "@mui/material/AppBar";
import { BSMLogo } from "../../assets/svgIconsList";
import Toolbar from "@mui/material/Toolbar";
import { styled } from "@mui/material/styles";

const Navbar = () => {

  const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
  })(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: "white",
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      marginLeft: theme.spacing(7),
      width: `calc(100% - ${theme.spacing(7)})`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  }));

  return (
    <AppBar position="fixed">
      <Toolbar style={{
        display: 'flex',        // Use flex layout
        justifyContent: 'center' // Center content horizontally
      }}>
        <div data-testid="logo" className="logo">
          <BSMLogo />
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
