import _ from "lodash";
import "./renderer.css";
import { booleanToString } from "./index";
import { Controls } from "../../components/controls";
import TextField from "@mui/material/TextField";
import React from "react";

export function traverseJSONSchema(json, props) {
  let title = json?.title ? json.title : "";
  if (title) {
    const result = data?.title?.replace(/([A-Z])/g, " $1");
    title = result?.charAt(0).toUpperCase() + result?.slice(1);
  }
  var data;
  let view = json?.view ? json.view : "tile";
  if (json?.type === "object") {
    data = renderObject(view, title, json.properties, props, json.required, [
      "properties",
    ]);
  }
  return data;
}

function renderObject(
  view,
  title,
  data,
  props,
  required,
  path,
  buttonName,
  summary,
  manageArrayFunc
) {
  let html = [];
  let elem_ids = [];
  let temp_path = _.clone(path);
  let edit = false;
  if (data === undefined) {
    return [];
  }
  for (const [key, value] of Object.entries(data)) {
    let childView = value.view ? value.view : "tile";
    elem_ids.push(key);
    temp_path.push(key);
    if (value.type === "object") {
      temp_path.push("properties");
      html.push(
        renderObject(
          childView,
          title ? title : value?.title,
          value.properties,
          props,
          value.required,
          temp_path,
          buttonName,
          summary,
          manageArrayFunc
        )
      );
    } else if (value.type === "array") {
      value["path"] = temp_path;
      html.push(renderArray(key, value, props, required?.includes(key)));
    } else {
      value["path"] = temp_path;
      html.push(renderFlat(key, value, props, required?.includes(key)));
    }
    temp_path = _.clone(path);
  }
  if (props.summary) {
    let path = temp_path.length >= 4 ? temp_path[3] : temp_path[1];
    let obj = getSummary(props, path, title, view, edit);
    //let obj = getSummary(props, temp_path[1], title, view, edit);
    title = obj.title;
    view = obj.view;
    edit = obj.edit;
  }
  return renderView(
    view,
    title,
    html,
    elem_ids,
    edit,
    props,
    buttonName,
    summary,
    manageArrayFunc
  );
}
function getSummary(props, path, title, view, edit) {
  let mainAccordionKey = props?.pageKey?.replace(/([A-Z])/g, " $1");
  let childAccordionKey = path?.replace(/([A-Z])/g, " $1");
  if (view === "stepper" || view === "mainTile") {
    if (view === "stepper") {
      title = mainAccordionKey;
      edit = !!props.editButton;
    } else if (view === "mainTile") {
      title = path ? childAccordionKey : mainAccordionKey;
      edit = !(path || !props.editButton);
    }
    view = "accordion";
  }
  return { title, edit, view };
}

function numberWithCommas(value) {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function formatDate(value) {
  let [year, month, day] = value.split("-");
  if (month === undefined && day === undefined) {
    return `${year}`;
  } else {
    return `${month}-${day}-${year}`;
  }
}

const convertToPlain = (html) => {
  var tempDivElement = document.createElement("div");
  tempDivElement.innerHTML = html;
  return tempDivElement.textContent || tempDivElement.innerText || "";
};

function getSummaryFieldValue(fieldValue, propertyType) {
  if (fieldValue) {
    if (typeof fieldValue === "boolean") {
      return booleanToString(fieldValue);
    } else if (propertyType === "date") {
      return formatDate(fieldValue);
    } else if (
      typeof fieldValue === "string" &&
      propertyType !== "number" &&
      propertyType !== "labelCal"
    ) {
      return fieldValue.charAt(0).toUpperCase() + fieldValue.slice(1);
    } else if (propertyType === "number" || propertyType === "labelCal") {
      return numberWithCommas(fieldValue);
    } else {
      return fieldValue.toLocaleString();
    }
  } else if (propertyType === "widget") {
    return "";
  } else {
    return "_____";
  }
}
function renderFlat(key, data, props, required) {
  const widgetStyle = {
    backgroundColor: "#fff", // Set your desired background color
    border: "1px solid #ccc", // Set your desired border style
    padding: "10px", // Adjust padding as needed
    maxWidth: "300px", // Set your desired maximum width
    margin: "20px", // Adjust margin as needed
  };
  let subArray = [];
  let propertyType = data.type;
  let title = _.get(data, "title", undefined);
  let label = getLabel(key, data);
  if (props.summary && propertyType !== undefined) {
    subArray.push(renderSummary(props, data, label, propertyType));
  } else {
    switch (propertyType) {
      case "string":
        subArray.push(
          <div
            className={title && "textFieldComponentMainDiv"}
            style={{ width: title || data?.multiline ? "100%" : "300px" }}
          >
            {getTitle(title)}
            <Controls.TextfieldComponent
              label={label}
              name={key}
              type={data.type}
              margin="normal"
              required={required}
              tooltip={data.tooltip}
              value={props.getFieldValue(data.path)}
              maxLength={data.maxLength}
              onChange={(e, v) => props.onChange(e, v, data)}
              pattern={data.pattern}
              multiline={data.multiline}
              rows={data.rows}
              helperText={returnErrorMessage(data)}
              error={data.error}
              prefix={data.prefix}
              onLoad={(e) => props.onInitialLoad(e, data)}
              testId={data.testId}
              disabled={data.disabled}
            />
          </div>
        );
        break;
      case "number":
        subArray.push(
          <Controls.NumberComponent
            label={label}
            name={key}
            range={data.range}
            tooltip={data.tooltip}
            minimum={data.minimum}
            maximum={data.maximum}
            prefix={data.prefix}
            required={required}
            value={props.getFieldValue(data.path)}
            onChange={(e) => props.onChange(e, null, data)}
            pattern={data.pattern}
            helperText={returnErrorMessage(data)}
            error={data.error}
            onLoad={(e) => props.onInitialLoad(e, data)}
          />
        );
        break;
      case "dropdown":
        subArray.push(
          <div className="dropdownClassName">
            {getTitle(data.title)}
            <Controls.DropdownComponent
              name={key}
              label={label}
              enumValues={data.enum}
              required={required}
              disabled={data.disable}
              value={props.getFieldValue(data.path)}
              onChange={(e) => props.onChange(e, null, data)}
              onLoad={(e) => props.onInitialLoad(e, data)}
            />
          </div>
        );
        break;
      case "radiogroup":
        subArray.push(
          <div style={{ width: "100%" }}>
            {getTitle(data.title)}
            <Controls.RadioButtonsGroup
              label={data.label}
              options={data.options}
              enumValues={data.enum}
              required={required}
              value={props.getFieldValue(data.path)}
              onChange={(e) => props.onChange(e, null, data)}
              onLoad={(e) => props.onInitialLoad(e, data)}
            />
          </div>
        );
        break;
      case "mask":
        subArray.push(
          <Controls.MaskComponent
            name={key}
            label={label}
            mask={data?.mask}
            required={required}
            value={props.getFieldValue(data.path)}
            onChange={(e) => props.onChange(e, null, data)}
            inputProps={data.pattern}
            helperText={returnErrorMessage(data)}
            error={data.error}
            onLoad={(e) => props.onInitialLoad(e, data)}
          />
        );
        break;
      case "stackButtons":
        subArray.push(
          <div
            className="stackButtonsDiv"
            style={{
              opacity: data.disabled ? 0 : 1,
            }}
          >
            <span>{data.title}</span>
            <Controls.StackButtons
              name={key}
              value={props.getFieldValue(data.path)}
              onClick={(e, v) => props.onChange(e, v, data)}
              onLoad={(e) => props.onInitialLoad(e, data)}
              disabled={data.disabled}
            />
          </div>
        );
        break;
      case "labelCal":
        subArray.push(
          <div className="labelCalcDiv">
            <span className="labelCalcTitle">{data.title}</span>
            <span className="labelCalAmountValue">
              $ {getLabelCalValue(props.getFieldValue(data.path))}
            </span>
          </div>
        );
        break;
      case "checkbox":
        subArray.push(
          <div style={{ width: "100%" }}>
            <Controls.CheckboxComponent
              name={key}
              label={data.description}
              title={data.title}
              value={props.getFieldValue(data.path)}
              onChange={(e) => props.onChange(e, null, data)}
              disabled={data.disable}
              onLoad={(e) => props.onInitialLoad(e, data)}
            />
          </div>
        );
        break;
      case "date":
        subArray.push(
          <Controls.DatePickerComponent
            label={label}
            name={key}
            view={data.view}
            margin="normal"
            required={required}
            value={props.getFieldValue(data.path)}
            onChange={(e, v) => props.onChange(e, v, data)}
            onLoad={(e) => props.onInitialLoad(e, data)}
          />
        );
        break;
      case "text":
        subArray.push(
          <div style={{ width: "100%" }}>
            <span className="titleClassName">{data.title}</span>
            <span className="checkboxLabelClassName">{data.text}</span>
          </div>
        );
        break;
      case "widget":
        subArray.push(
          <div style={{ width: "100%" }}>
            <div>
              <Controls.ScrollingTextWidget
                text={data.text}
                title={data.title}
                value={props.getFieldValue(data.path)}
                onClick={(e, v) => props.onChange(e, v, data)}
                onLoad={(e) => props.onInitialLoad(e, data)}
                disabled={data.disabled}
              />
            </div>
          </div>
        );
        break;
      case "ConsentButtons":
        subArray.push(
          <div
            className="stackButtonsDiv"
            style={{
              opacity: data.disabled ? 0 : 1,
            }}
          >
            <span>{data.title}</span>
            <Controls.ConsentButtons
              name={key}
              value={props.getFieldValue(data.path)}
              onClick={(e, v) => props.onChange(e, v, data)}
              onLoad={(e) => props.onInitialLoad(e, data)}
              disabled={data.disabled}
            />
          </div>
        );
        break;
      default:
    }
  }
  return subArray;
}
function getLabelCalValue(data) {
  if (!isNaN(data)) {
    return Math.round(data).toLocaleString("en");
  }
}
function getTitle(title) {
  if (title) {
    return <span className="titleClassName">{title}</span>;
  }
}
function getLabel(key, data) {
  if (data.label) return data.label;
  else if (data.title) return data.title;
  else {
    let result = key.replace(/([A-Z])/g, " $1");
    result = result.charAt(0).toUpperCase() + result.slice(1);
    return result;
  }
}
function returnErrorMessage(data) {
  if (data.error) return data.errorMsg;
}

/**Function to render eConsent Agreement and other based on condition*/
const rendereConsentAgreement = (propertyType, label) => {
  if (propertyType === "widget") {
    if (label === " E Consent Agreement") {
      return (
        <span
          className="titleClassName"
          style={{
            marginBottom: "0px",
            color: "black",
            fontSize: "14px",
          }}
        >
          eConsent Agreement&nbsp;:&nbsp;
        </span>
      );
    } else if (label === " Consumer Consent") {
      <span
        className="titleClassName"
        style={{
          marginBottom: "0px",
          color: "black",
          fontSize: "14px",
        }}
      >
        Consumer Credit Consent&nbsp;:&nbsp;
      </span>;
    } else {
      return <span className="titleClassName">{label}&nbsp;</span>;
    }
  } else {
    return label === " E Consent Agreement" ? (
      <span
        className="titleClassName"
        style={{
          marginBottom: "0px",
          color: "black",
          fontSize: "14px",
        }}
      >
        eConsent Agreement&nbsp;:&nbsp;
      </span>
    ) : label === " Consumer Consent" ? (
      <span
        className="titleClassName"
        style={{
          marginBottom: "0px",
          color: "black",
          fontSize: "14px",
        }}
      >
        Consumer Credit Consent&nbsp;:&nbsp;
      </span>
    ) : (
      <span
        className="titleClassName"
        style={{
          marginBottom: "0px",
          color: "black",
          fontSize: "14px",
        }}
      >
        {label}&nbsp;:&nbsp;
      </span>
    );
  }
};

function renderSummary(props, data, label, propertyType) {
  if (propertyType === "text") {
    if (
      label === "Manual Asset Entry" ||
      label === "Manage Your Property" ||
      label === "Manual Liability Entry" ||
      label === "Other Expense Entry" ||
      label === "Current Monthly Housing Expenses" ||
      label === "Borrower Employment Information" ||
      label === "Co-Borrower Employment Information" ||
      label === "Borrower Gross Monthly Income" ||
      label === "Additional Gross Monthly Income" ||
      label === "Borrower Previous Address" ||
      label === "Borrower Current Address" ||
      label === "Borrower Mailing Address" ||
      label === "Borrower Details" ||
      label === "Co-Borrower Details" ||
      label === "Co-Borrower Current Address" ||
      label === "Co-Borrower Previous Address" ||
      label === "Co-Borrower Mailing Address" ||
      label === "Borrower Declarations" ||
      label === "Co-Borrower Declarations" ||
      label === "Borrower Demographic Information" ||
      label === "Co-Borrower Demographic Information"
    ) {
      return <span className="titleClassName">{label}</span>;
    }
  } else if (data.title === "IsJoint") {
    return;
  } else {
    let fieldValue = getSummaryFieldValue(
      props.getFieldValue(data.path),
      propertyType
    );
    return (
      <div className="summaryDataDisplayDiv" style={{ marginBottom: "0px" }}>
        {rendereConsentAgreement(propertyType, label)}
        <TextField
          variant="standard"
          className="summary"
          InputProps={{
            disableUnderline: true,
            readOnly: true,
            classes: { root: "summary" },
          }}
          onAnimationStart={(e) => {
            props.onInitialLoad(e, data);
          }}
          value={fieldValue}
        />
      </div>
    );
  }
}
const renderArray = (title, data, props, required) => {
  title = title.replace(/([A-Z])/g, " $1");
  title = title.charAt(0).toUpperCase() + title.slice(1);
  let minItems = _.get(data, ["minItems"], 0);
  let maxItems = _.get(data, ["maxItems"], 999);
  let currSize = _.get(data, "length", minItems);
  if (currSize === 0) {
    //used when fetching already saved array items
    let array = props.getFieldValue(data.path);
    if (
      array != undefined &&
      array.hasOwnProperty(0) &&
      _.isEmpty(_.get(array, 0))
    ) {
      array.length = 0; //MI6-2352 https://bondstreetmortgage.atlassian.net/browse/MI6-2352
    }
    currSize = array && array.length ? array.length : 0;
  }
  currSize = minItems > currSize ? minItems : currSize;
  data["length"] = currSize;
  let subArray = renderArrayItem(data, props, currSize, required, title);
  if (currSize < maxItems && !props.summary) {
    subArray.push(
      <Controls.ButtonComponent
        name={data.buttonName}
        color={"#00634A"}
        manageArray={() => props.manageArray(data, 1, null)}
      />
    );
  }
  if (currSize > 0) {
    subArray.push(
      <div
        className={"textFieldComponentMainDiv"}
        style={{ width: "100%" }}
      ></div>
    );
  }

  return subArray;
};

const renderArrayItem = (data, props, size, required, title) => {
  let subArray = [];
  let arrayElemType = _.get(data, ["items", "type"], "string");
  if (size > 0) {
    subArray.push(
      <div className={"textFieldComponentMainDiv"} style={{ width: "100%" }}>
        {/*<span className="titleClassName">{title}</span>*/}
      </div>
    );
  }
  for (let j = 0; j < size; j++) {
    let buttonName = data.buttonName;
    let summary = props.summary;
    let elemPath = _.cloneDeep(data.path);
    elemPath.push(j);
    if (arrayElemType === "object") {
      let schema = props.getSchemaForArray(elemPath);
      let properties =
        schema.properties === undefined
          ? data.items.properties
          : schema.properties;
      const manageArrayFunc = () => {
        props.manageArray(data, 0, j);
      };
      subArray.push(
        renderObject(
          data.items.view,
          title + " # " + (j + 1),
          properties,
          props,
          data.items.required,
          elemPath,
          buttonName,
          summary,
          //  props.manageArray(data, 0, j)
          manageArrayFunc
        )
      );
    } else {
      subArray.push(
        renderFlat(
          "",
          { type: arrayElemType, path: elemPath, title: title },
          props,
          required
        )
      );
    }
  }
  return subArray;
};
const renderView = (
  type,
  title,
  data,
  keys,
  edit,
  props,
  buttonName,
  summary,
  manageArrayFunc
) => {
  let view;

  if (type === "accordion") {
    view = (
      <Controls.AccordionComponent
        data={data}
        name={title}
        edit={edit}
        onClick={props.clickEditHandler}
        buttonName={buttonName}
        summary={summary}
        manageArray={manageArrayFunc}
      />
    );
  } else if (type === "tile" || type === "mainTile") {
    localStorage.removeItem("stepperLength");
    view = (
      <div className="fullWidthClassName">
        {/* <span className="titleDisplayName">{title}</span> */}
        <div className="displayFormElementsDiv">{data}</div>
      </div>
    );
  } else {
    view = <Controls.FormStepper steps={data} elem_ids={keys} />;
  }
  return view;
};
