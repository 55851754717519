import React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import { StepLabel } from "@mui/material";
import "./components.css";
import { mapDispatchToProps, mapStateToProps } from "../helpers";
import { connect } from "react-redux";

const FormStepper = (props) => {
  let html = props.steps;
  let elem_ids = props.elem_ids;
  localStorage.setItem("stepperLength", elem_ids.length);
  const handleNext = (index) => {
    props.stepperBackStep(false);
    props.activeStep(index);
  };
  return (
    <div>
      <Box
        className={
          elem_ids.length > 4 ? "stepperClassFullWidth" : "stepperClassName"
        }
      >
        <Stepper activeStep={props.reduxActiveStep}>
          {elem_ids &&
            elem_ids.map((label, index) => {
              const result =
                label === "EConsentAgreement"
                  ? "eConsent Agreement"
                  : label.replace(/([A-Z])/g, " $1");
              label =
                result === "eConsent Agreement"
                  ? "eConsent Agreement"
                  : result.charAt(0).toUpperCase() + result.slice(1);

              return (
                <Step
                  key={label}
                  classes={{
                    root: "rootStepClassName",
                  }}
                >
                  <StepLabel
                    // onClick={() => handleNext(index)}
                    className="robotoRegularFont labelClassName"
                    StepIconProps={{
                      classes: {
                        completed: "completedStep",
                        active: "completedStep",
                      },
                    }}
                    // style={{ cursor: 'pointer' }}
                  >
                    {label}
                  </StepLabel>
                </Step>
              );
            })}
        </Stepper>
      </Box>
      <div>{html[props.reduxActiveStep]}</div>
    </div>
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(FormStepper);
