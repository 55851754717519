import React, { useEffect } from "react";
import {
  Loandetailsicon,
  Summaryicon,
  Expensesicon,
} from "../../assets/svgIconsList";
import DrawerComponent from "../../components/DrawerComponent";
import HeaderComponent from "../../components/HeaderComponent";
import CssBaseline from "@mui/material/CssBaseline";
import { Box } from "@mui/system";
import Econsent from "./EConsent/Econsent";
import MyLoanApplication from "./MyLoanApplication/MyLoanApplication";
import DocumentsManager from "./DocumnetManager/DocumentsManager";
import {useLocation, useNavigate} from "react-router-dom";
import HouseIcon from "@mui/icons-material/HomeOutlined";
import { styled } from "@mui/material/styles";
import {
  appendQueryParamToURL,
  mapDispatchToProps,
  mapObjectToState,
  mapStateToProps,
} from "../../helpers";
import { connect } from "react-redux";
import { withSnackbar } from "../../components/Snackbar";
import Loader from "../../components/Loader";
import { useState } from "react";


const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));
const LoanDashboard = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  let appId = localStorage.getItem("appId");
  const [open, setOpen] = React.useState(false);
  const [isHovered, setIsHovered] = useState(false);
  let localStoragePageIndex = localStorage.getItem("loan-app-dashboard");
  const [pageIndex, setPageIndex] = React.useState(
    localStoragePageIndex ? localStoragePageIndex : 2
  );
  let [loading, setLoading] = React.useState(!(appId === null));
  const menuButtons = [
    { buttonName: "Home", icon: HouseIcon },
    { buttonName: "My Tasks", icon: Loandetailsicon },
    { buttonName: "Loan Summary", icon: Summaryicon },
    { buttonName: "Doc Depot", icon: Expensesicon },
  ];
  useEffect(() => {
    if (!appId) {
      const urlWithParams = appendQueryParamToURL(
        process.env.REACT_APP_BASE_URL
      );
      navigate(urlWithParams);
    }
  }, []);

  useEffect(() => {
    return () => {
      props.resetRedux();
    };
  }, []);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get("token");
    const siteid = queryParams.get("siteid");
    const eccBpName = queryParams.get("ecc-bpname");

    if (token && siteid && eccBpName) {
      setPageIndex(2);
    } else {
      setPageIndex(4);
    }
  }, [location.search]);

  useEffect(() => {
    if (appId !== undefined && appId !== null) {
      props.getLoanData(appId);
    }
  }, [appId]);
  useEffect(() => {
    if (localStorage.getItem("appId") !== null) {
      let convertedState = mapObjectToState(
        props.componentState,
        props.getLoanDataWithIdRes.data
      );
      props.copyStateToRedux(convertedState);
      setTimeout(() => {
        setLoading(false);
      }, 1500);
    }
  }, [props?.getLoanDataWithIdRes]);
  const handleDrawerOpen = (index) => {
    setOpen(true);
    setPageIndexFunc(index);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handleMouseEnter = () => {
    if (!open) setIsHovered(true);
  };
  const handleMouseLeave = () => {
    if (!open) setIsHovered(false);
  };
  const setPageIndexFunc = (index) => {
    setPageIndex(index);
    localStorage.setItem("loan-app-dashboard", index);
  };
  return (
    <div>
      {loading && <Loader />}
      {!loading && (
        <div>
          <Box sx={{ display: "flex" }}>
            <CssBaseline />
            <HeaderComponent
              setOpen={() => setOpen(!open)}
              pageIndex={pageIndex}
            />
            <DrawerComponent
              open={open}
              isSubmitted={true}
              isHovered={isHovered}
              setOpen={() => setOpen(!open)}
              pageIndex={pageIndex}
              sideMenuButtons={menuButtons}
              handleDrawerClose={handleDrawerClose}
              handleDrawerOpen={handleDrawerOpen}
              handleMouseEnter={handleMouseEnter}
              handleMouseLeave={handleMouseLeave}
              navigatePage={navigate}
            />
            <Box
              component="main"
              sx={{
                flexGrow: 1,
                p: 3,
                marginLeft: open ? "10px" : "0px",
                marginBottom: "100px",
              }}
            >
              <DrawerHeader />
              {parseInt(pageIndex) === 2 && <Econsent />}
              {parseInt(pageIndex) === 3 && <MyLoanApplication />}
              {parseInt(pageIndex) === 4 && <DocumentsManager />}
            </Box>
          </Box>
        </div>
      )}
    </div>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(LoanDashboard));
