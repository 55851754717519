import React, { useState, useMemo } from "react";
import { getCookie } from "../helpers/index";
import {
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  ListSubheader,
  TextField,
  InputAdornment,
  Tooltip,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import "./components.css";

const containsText = (text, searchText) =>
  text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;

export default function DropdownComponent(props) {
  const { label, value, onChange, required, enumValues, name, tooltip,disabled } = props;
  const [searchText, setSearchText] = useState("");
  const pageIndex = getCookie("pageIndex");
  const displayedOptions = useMemo(
    () => enumValues?.filter((option) => containsText(option, searchText)),
    [searchText, enumValues]
  );
  const handleOnChange = (e) => {
    let v = e.target.value === "--Select--" ? "" : e.target.value;
    let val = {
      ...e,
      target: {
        ...e.target,
        value: v,
      },
    };
    onChange(val);
  };
  const tooltipMsg = () => {
    if (tooltip) {
      return tooltip;
    }
  };
  
  return (
    <div
      className={
        "flexAlignColumnClassName"
      }
      onClick={(e) => { e.preventDefault() }}
    >
      <InputLabel
        id="search-select-label"
        required={required}
        className="labelClassName alignRowClassName flex-start"
      >
        {label}
        {tooltipMsg() && (
          <Tooltip title={tooltipMsg()} data-testid="tooltip" arrow>
            <span className="tooltipInfoIcon">i</span>
          </Tooltip>
        )}
      </InputLabel>
      <FormControl style={{ width: pageIndex === "8" ? "200px" : "300px" }}>
        <Select
          name={name}
          data-testid="my-wrapper"
          disabled={disabled}
          MenuProps={{ autoFocus: false, classes: { paper: "paperClassName" } }}
          className="textFieldInputComponent"
          labelId="search-select-label"
          id="search-select"
          value={value ? value : "--Select--"}
          defaultValue=""
          displayEmpty
          onAnimationStart={(e) => props.onLoad(e)}
          onChange={(e) => handleOnChange(e)}
          onClose={() => setSearchText("")}
          renderValue={(data) => data}
          classes={{
            root: "outlinedInputRoot",
            notchedOutline: "outlinedInput",
          }}
          required={required}
        >
          <ListSubheader>
            <TextField
              size="small"
              data-testid="textfield"
              autoFocus
              placeholder="Type to search..."
              fullWidth
              value={searchText}
              className="textFieldInputComponent"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
                classes: {
                  root: "outlinedInputRoot",
                  focused: "outlinedInputFocused",
                  notchedOutline: "outlinedInput",
                },
              }}
              onChange={(e) => setSearchText(e.target.value)}
              onKeyDown={(e) => {
                if (e.key !== "Escape") {
                  e.stopPropagation();
                }
              }}
            />
          </ListSubheader>
          <MenuItem disabled={true} value="--Select--" className="menuItemOptionTag">
            -- Select --
          </MenuItem>
          {displayedOptions?.map((option, i) => (
            <MenuItem key={i} value={option} className="menuItemOptionTag">
              {option}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
