import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import { connect } from "react-redux";
import {
  Loandetailsicon,
  Personalinformationicon,
  Expensesicon,
  Assetsicon,
  Employmentandincomeicon,
  Liabilitiesicon,
  Declarationsicon,
  Summaryicon,
} from "../../assets/svgIconsList";
import LoanDetails from "./loan_details";
import SaveGrid from "./save_grid";
import PersonalInformation from "./personal_information/index";
import ManageLiabilities from "./liabilities/index";
import Loader from "../../components/Loader";
import { withSnackbar } from "../../components/Snackbar";
import { isEmpty } from "lodash";
import Expenses from "./expenses";
import EmploymentAndIncome from "./employment_and_income";
import _ from "lodash";
import ListOfAssets from "./listOfAssets";
import Declarations from "./declarations";
import Summary from "./summary/summary";
import HeaderComponent from "../../components/HeaderComponent";
import DrawerComponent from "../../components/DrawerComponent";
import {
  getCookie,
  mapDispatchToProps,
  mapStateToProps,
  mapObjectToState,
  setCookie,
  appendQueryParamToURL,
} from "../../helpers";
import HouseIcon from "@mui/icons-material/HomeOutlined";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { currentPageIndex, submitCall, saveLoanCall, coappsubmitCall } from "./constants";
// import { tour } from "../../components/tour";
import { cxSteps } from "../../constants/cxSteps";
// import { renderTour } from "../../components/render_tour";
import { BorderRight, OpenInBrowserOutlined } from "@mui/icons-material";
import Coapplicant from "./coapplicant/coapplicant";
import { useSelector } from "react-redux";

import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';

const modalStyle = {
  outline: "none",
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "40%",
  backgroundColor: "#ffffff",
  boxShadow: 24,
  padding: "1%",
  borderRadius: ".5rem"
};

// Functional component for the modal dialog
function SimpleModal({ handleClose }) {

  return (
    <Modal
      open={true}
      onClose={() => handleClose()}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div style={modalStyle}>
        <h3 style={{ lineHeight: "1", marginBottom: ".25rem" }} id="simple-modal-title">Are you sure you want to leave this page ?</h3>
        <hr />
        <p>Changes that you have made may not be saved</p>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button variant="contained" style={{ color: "red", backgroundColor: "transparent", boxShadow: "none", textTransform: "capitalize" }} onClick={() => window.location.href = process.env.REACT_APP_BASE_URL}>Leave</Button>
          <Button variant="contained" style={{ color: "#ffffff", backgroundColor: "#00634A", marginLeft: "2%", textTransform: "capitalize" }} onClick={() => handleClose()}>Stay</Button>
        </div>
      </div>
    </Modal>
  );
}

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));
const menuButtons = [
  { buttonName: "Home", icon: HouseIcon },
  {
    buttonName: "Personal Information",
    icon: Personalinformationicon,
  },
  { buttonName: "Loan Details", icon: Loandetailsicon },
  {
    buttonName: "Employment and Income",
    icon: Employmentandincomeicon,
  },
  { buttonName: "Assets", icon: Assetsicon },
  { buttonName: "Liabilities", icon: Liabilitiesicon },
  { buttonName: "Declarations", icon: Declarationsicon },
  { buttonName: "Summary", icon: Summaryicon, className: "summaryTour" },
];
function LoanApplication(props) {
  const navigate = useNavigate();
  let appId = localStorage.getItem("appId");
  let currentPageCookie = currentPageIndex();
  let [loading, setLoading] = useState(!(appId === null));
  const [open, setOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [disable, setDisable] = useState(false);
  const [menuDisable, setMenuDisable] = useState(true);
  const [pageIndex, setPageIndex] = useState(
    currentPageCookie ? currentPageCookie : 2
  );
  const [showCoApplicant, setShowCoApplicant] = useState(false);
  const [editCoApplicant, setEditCoApplicant] = useState(false);
  const [finishCheck, setFinishCheck] = useState(false);
  const errorDictionary = useSelector(state => state.userReducer.errorsDictionary);
  const errorsPresent = errorDictionary && Object.keys(errorDictionary).length > 0;
  const [showModel, setShowModal] = useState(false)
  // React.useLayoutEffect(() => {
  // if (
  //   getCookie(localStorage.getItem("user_email") + "NewApplication_tour") ===
  //   undefined
  // ) {
  //   tour.steps = [];
  //   renderTour(cxSteps);
  // }
  // return () => {
  //   props?.resetRedux();
  // tour.complete();
  // };
  // }, []);

  useEffect(() => {
    return () => {
      props?.resetRedux();
    };
  }, []);

  useEffect(() => {
    if (appId !== undefined && appId !== null) {
      props.getLoanData(appId);
    }
  }, [appId]);
  useEffect(() => {
    setCookie("pageIndex", pageIndex, 15, "/");
    if (appId !== null) {
      setCookie(appId + "_pageIndex", pageIndex, 15, "/");
    }
    return () => {
      setCookie("pageIndex", 1, -1, "/");
    };
  }, [pageIndex]);

  useEffect(() => {
    let apiErrData = props?.apiErrResponseData;
    if (!isEmpty(apiErrData) && apiErrData?.status !== 200) {
      Swal.fire({
        icon: "error",
        title: "Something went wrong!",
        text: "Please try again later ",
      });
    }
  }, [props?.apiErrResponseData]);

  useEffect(() => {
    let successResData = props?.submitedLoanRes;
    if (successResData?.status !== undefined) {
      props.showLoader(false);
    }
    if (!isEmpty(successResData) && successResData?.status === 200) {
      Swal.fire({
        position: "center",
        icon: "success",
        title: "Thank you for Submitting",
        text: "Please wait while we create your Form",
        showConfirmButton: false,
        timer: 2000,
      }).then(() => {
        localStorage.removeItem("appId");
        localStorage.removeItem("loan-app-dashboard");
        const urlWithParams = appendQueryParamToURL(
          process.env.REACT_APP_BASE_URL
        );
        navigate(urlWithParams);
      });
    }
  }, [props?.submitedLoanRes]);
  useEffect(() => {
    let resData = props.saveLoanDataRes;
    if (resData?.status !== undefined) {
      props.showLoader(false);
    }
    if (
      !isEmpty(props.saveLoanDataRes) &&
      props.saveLoanDataRes?.status === 200
    ) {
      localStorage.setItem("appId", resData?.data?.uniqueId);
      setCookie(resData?.data?.uniqueId + "_pageIndex", pageIndex, 15, "/");
      props.showToast("Successfully Saved", "success", 3000);
    } else if (
      !isEmpty(props.saveLoanDataRes) &&
      props.saveLoanDataRes?.status !== 200
    ) {
      props.showToast(props.saveLoanDataRes?.data?.error, "error", 3000);
    }
  }, [props.saveLoanDataRes]);
  useEffect(() => {
    if (localStorage.getItem("appId") !== null) {
      let convertedState = mapObjectToState(
        props.componentState,
        props.getLoanDataWithIdRes.data
      );
      props.copyStateToRedux(convertedState);
      setTimeout(() => {
        setLoading(false);
      }, 1500);
    }
  }, [props?.getLoanDataWithIdRes]);

  useEffect(() => {
    let activeStep = props.reduxActiveStep;
    let disabledVal = isMenuDisabled();
    isNextEnable(activeStep);
    setMenuDisable(disabledVal);
  }, [props?.componentState]);

  const isNextEnable = (currentStepperIndex) => {
    let isDisable = false;
    let val = ["FirstName", "LastName", "Email"];
    if (currentStepperIndex === 1) {
      let borrowerDetails = _.get(
        props.componentState,
        "ApplicantDetails.BorrowerDetails",
        {}
      );
      let coborrowerDetails = _.get(
        props.componentState,
        "ApplicantDetails.CoBorrowerDetails",
        {}
      );
      let jointCreditIndicator = _.get(
        props.componentState,
        "JointCredit.AreYouApplyingForAJointCreditWithACoBorrower",
        ""
      ).includes("Yes");
      for (let i of val) {
        if (props?.errorsDictionary?.hasOwnProperty(i)) {
          //check for validity
          isDisable = true;
          break;
        }
        if (!borrowerDetails.hasOwnProperty(i)) {
          //check for existence
          isDisable = true;
          break;
        }
        if (jointCreditIndicator && !coborrowerDetails.hasOwnProperty(i)) {
          //check for existence
          isDisable = true;
          break;
        }
      }
    }
    if (currentStepperIndex === 3) {
      let eConsent = _.get(
        props.componentState,
        "EConsentAgreement.EConsentAgreement",
        ""
      );

      if (!eConsent) {
        isDisable = true;
      }
    }

    if (currentStepperIndex === 4) {
      let consumerConsent = _.get(
        props.componentState,
        "ConsumerCreditConsent.ConsumerConsent",
        ""
      );
      if (!consumerConsent) {
        isDisable = true;
      }
    }
    setDisable(isDisable);
  };

  const isMenuDisabled = () => {
    let val = ["FirstName", "LastName", "Email"];
    let disabledVal = false;
    let borrowerDetails = _.get(
      props.componentState,
      "ApplicantDetails.BorrowerDetails",
      {}
    );
    let coborrowerDetails = _.get(
      props.componentState,
      "ApplicantDetails.CoBorrowerDetails",
      {}
    );
    let eConsent = _.get(
      props.componentState,
      "EConsentAgreement.EConsentAgreement",
      ""
    );
    let consumerConsent = _.get(
      props.componentState,
      "ConsumerCreditConsent.ConsumerConsent",
      ""
    );
    let jointCreditIndicator = _.get(
      props.componentState,
      "JointCredit.AreYouApplyingForAJointCreditWithACoBorrower",
      ""
    ).includes("Yes");
    for (let i of val) {
      if (props?.errorsDictionary?.hasOwnProperty(i)) {
        //check for validity
        disabledVal = true;
        break;
      }
      if (!borrowerDetails.hasOwnProperty(i)) {
        //check for existence
        disabledVal = true;
        break;
      }
      if (jointCreditIndicator && !coborrowerDetails.hasOwnProperty(i)) {
        //check for existence
        disabledVal = true;
        break;
      }
      if (!eConsent) {
        disabledVal = true;
      }
      if (!consumerConsent) {
        disabledVal = true;
      }
    }
    if (errorsPresent) {
      disabledVal = true;
    }
    return disabledVal;
  };

  const CoApplicantDialogue = () => {
    setShowCoApplicant(true);
  };

  const handleCloseCoApplicant = () => {
    setShowCoApplicant(false);
    setEditCoApplicant(false);
  };

  const onEditCoApplicantsFunc = () => {
    setEditCoApplicant(true);
  };

  const handleDrawerOpen = (index) => {
    setOpen(true);
    setPageIndexFunc(index);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handleMouseEnter = () => {
    if (!open) setIsHovered(true);
  };
  const handleMouseLeave = () => {
    if (!open) setIsHovered(false);
  };
  const setPageIndexFunc = (index) => {
    setPageIndex(index);
  };
  const saveApiCall = () => {
    props.showLoader(true);
    saveLoanCall(props, props.encompassLoanObject);
  };
  const submitApiCall = () => {
    props.showLoader(true);
    submitCall(props, props.encompassLoanObject);
  };

  const coappsubmitApiCall = () => {
    props.showLoader(true);
    coappsubmitCall(props, props.encompassLoanObject);
  };

  const handleFinish = () => {
    setFinishCheck(true);
  };

  let coApplicantDetails = _.get(
    props.componentState,
    "CoApplicant.CoApplicant",
    []
  );


  useEffect(() => {
    const disableBackButton = () => {
      window.history.pushState(null, null, window.location.href);
      window.onpopstate = () => {
        window.history.go(1);
      };
    };

    disableBackButton();

    return () => {
      window.onpopstate = null; // Cleanup function
    };
  }, []);



  const handleEvent = () => {
    setShowModal(true)
  }

  window.addEventListener("popstate", handleEvent)



  return (
    <div>
      {loading && <Loader />}
      {!loading && (
        <>
          {showModel && <SimpleModal handleClose={() => {
            setShowModal(false)
          }} />}
          <div>
            <Box sx={{ display: "flex" }}>
              <CssBaseline />
              <HeaderComponent
                setOpen={() => setOpen(!open)}
                pageIndex={pageIndex}
              />
              <DrawerComponent
                open={open}
                isSubmitted={false}
                isHovered={isHovered}
                setOpen={() => setOpen(!open)}
                pageIndex={pageIndex}
                sideMenuButtons={menuButtons}
                handleDrawerClose={handleDrawerClose}
                handleDrawerOpen={handleDrawerOpen}
                handleMouseEnter={handleMouseEnter}
                handleMouseLeave={handleMouseLeave}
                navigatePage={navigate}
                disable={menuDisable}
                saveApiCall={() => saveApiCall()}
              />
              <Box
                component="main"
                sx={{
                  flexGrow: 1,
                  p: 3,
                  marginLeft: open ? "10px" : "0px",
                  marginBottom: "100px",
                }}
              >
                <DrawerHeader />
                {parseInt(pageIndex) === 2 && <PersonalInformation />}
                {parseInt(pageIndex) === 3 && <LoanDetails />}
                {parseInt(pageIndex) === 4 && <EmploymentAndIncome />}
                {parseInt(pageIndex) === 5 && <ListOfAssets />}
                {parseInt(pageIndex) === 6 && <ManageLiabilities />}
                {parseInt(pageIndex) === 7 && <Declarations />}
                <Summary
                  changePageIndex={setPageIndexFunc}
                  display={parseInt(pageIndex) === 8}
                  finishCheck={finishCheck}
                  onEditCoApplicantsFunc={onEditCoApplicantsFunc}
                />
                <SaveGrid
                  CoApplicantDialogueOpen={() => CoApplicantDialogue()}
                  open={open}
                  finishCheck={
                    props?.getLoanDataWithIdRes?.data?.loanNumber !== undefined
                      ? true
                      : coApplicantDetails.length > 0
                  }
                  pageIndex={parseInt(pageIndex)}
                  saveApiCall={() => saveApiCall()}
                  submitLoanApiCallFunc={props?.getLoanDataWithIdRes?.data?.loanNumber !== undefined ? () => coappsubmitApiCall() : () => submitApiCall()}
                  setPageIndex={(index) => setPageIndexFunc(index)}
                  disable={disable}
                  setDisable={setDisable}
                  isNextEnable={isNextEnable}
                />
              </Box>
            </Box>
            {(showCoApplicant || editCoApplicant) && (
              <Coapplicant
                open={showCoApplicant || editCoApplicant}
                onFinish={handleFinish}
                onClose={handleCloseCoApplicant}
              />
            )}
          </div>
        </>
      )}
      {props.toggleLoader && <Loader />}
    </div>
  );
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(LoanApplication));
export { LoanApplication };
