import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Home from "./dashboardHome/home";
import { Box } from "@mui/system";
// import { dashboardSteps } from "../../../constants/dashboardSteps";
import { useNavigate } from "react-router-dom";
// import { renderTour } from "../../../components/render_tour";
// import { tour } from "../../../components/tour";
import {getAccessToken, getCookie} from "../../../helpers";
import { withMsal } from "@azure/msal-react";
import {useState} from "react";
import homebg from '../../../assets/homebgcx.png';
import Loader from "../../../components/Loader";
function Dashboard(props) {
  const navigation = useNavigate();
  let [show,setShow]=useState(false);
  React.useEffect(()=>{
      setTimeout(()=>{
          if(getAccessToken()) {
              setShow(true);
          }
      },2000);
  },[props.msalContext])
  React.useLayoutEffect(() => {
    localStorage.removeItem("userName");
    localStorage.removeItem("appId");
    // if (
    //   getCookie(localStorage.getItem("user_email") + "dashboard_tour") ===
    //   undefined
    // ) {
    //   tour.steps = [];
    //   renderTour(dashboardSteps, navigation);
    // }
    // return () => tour.complete();
  });

  return (
    show ?<div style={{ backgroundImage:`url(${homebg})`,
    backgroundRepeat: 'no-repeat',backgroundSize: 'cover',
     backgroundAttachment: 'fixed', position: 'fixed', height: '100%', overflow: 'auto', width: '100%' }}>
      <Box>
        <CssBaseline />
        
          <Home />
       
      </Box>
    </div>:<Loader/>
  );
}
export default withMsal(Dashboard);
export {Dashboard};