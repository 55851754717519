import "./RateCards.css";
import { Grid, Typography, Box } from "@mui/material";
import { useState, useEffect } from "react";
import staticPayload from './payload.json';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { API_URL } from "../../redux/constants";

const RateCards = ({ refinanceData, handleEdit }) => {
  const navigate = useNavigate();
  const [selectedRateCard, setSelectedRateCard] = useState(null);
  const [ratesData, setRatesData] = useState({ "products": [] });
  const [loading, setLoading] = useState(true);

  const LoanTermEnum = {
    ThirtyYear: "30 Year",
    TwentyFiveYear: "25 Year",
    TwentyYear: "20 Year",
    FifteenYear: "15 Year",
    TenYear: "10 Year"
};

const LoanType = {
  Conventional: "Conventional",
  Conforming: "Conventional",
  NonConforming: "Conventional",
  FHA: "FHA",
  VA: "VA"
}

  const handleSelection = () => {
    navigate(process.env.REACT_APP_BASE_URL);
  }

  const handleHome = () => {
    window.location.reload();

  }

  const getProductName = (product) => {
    return LoanTermEnum[product.loanTerm]+" "+LoanType[product.loanType];
  }

  const handleRateCardClick = (rateCard) => {
    setSelectedRateCard(rateCard + 1); 
  };

  

  const getRateCards = (data, price1, price2, price3) => {
    let rates = [];
    let rate1 = findRateObject(data, price1);
    let rate2 = findRateObject(data, price2);
    let rate3 = findRateObject(data, price3);

    const calculateLenderCredit = (rateObj) => {
      let loanAmount = refinanceData.cashOutAmount + refinanceData.firstMortgageAmount + refinanceData.secondMortgageAmount + refinanceData.revolvingTradesBalance;
      if (rateObj.price === 100.5) {
        rateObj.lenderCredit = parseFloat((loanAmount * 0.005).toFixed(2));

      }
      else if (rateObj.price === 100.75) {
        rateObj.lenderCredit = parseFloat((loanAmount * 0.0075).toFixed(2));
    }
    else {
      rateObj.lenderCredit = 0;
    }
  };
  
    rates = [rate1, rate2, rate3].filter(obj => obj !== undefined);
    rates.forEach(calculateLenderCredit);
    return rates;
  }

  const findRateObject = (data, price) => {
    return data.find(obj => obj.price === price);
  }

  const handleDynamicData = (type) => {
    let dynamicData = {};
    return dynamicData = {
      "borrowerInformation": {
        "firstName": refinanceData.personalInformation.firstName,
        "lastName": refinanceData.personalInformation.lastName,
        "vaFirstTimeUse": false,
        "fico": refinanceData.creditScore
      },
      "propertyInformation": {
        "appraisedValue": refinanceData.propertyValue,
        "occupancy": refinanceData.occupancy,
        "propertyStreetAddress": refinanceData.addressInformation.address,
        "county": refinanceData.addressInformation.county,
        "state": refinanceData.addressInformation.stateCode,
        "zipCode": refinanceData.addressInformation.zipCode,
        "propertyType": refinanceData.propertyType,
        "salesPrice": refinanceData.propertyValue,
        "numberOfUnits": refinanceData.numberOfUnits
      },
      "loanInformation": {
        "amortizationTypes": [
          refinanceData.amortizationType
        ],
        "loanType": type,
        "armFixedTerms": refinanceData.armFixedTerm !== undefined ? refinanceData.armFixedTerm.split(",") : refinanceData.armFixedTerm,
        "cashOutAmount": refinanceData.cashOutAmount,
        "baseLoanAmount": refinanceData.cashOutAmount + refinanceData.firstMortgageAmount + refinanceData.secondMortgageAmount + refinanceData.revolvingTradesBalance,
        "loanTerms": refinanceData.loanTerm.split(","),
        "representativeFICO": refinanceData.creditScore,
        "loanLevelDebtToIncomeRatio": refinanceData.debtToIncomeRatio
      },
    }
  }

  const handleDynamicData_va = (type) => {
    return  {
      "borrowerInformation": {
        "firstName": refinanceData.personalInformation.firstName,
        "lastName": refinanceData.personalInformation.lastName,
        "vaFirstTimeUse": true,
        "exemptFromVAFundingFee": false,
        "typeOfVeteran":refinanceData.typeOfVeteran,
        "fico": refinanceData.creditScore
      },
      "propertyInformation": {
        "appraisedValue": refinanceData.propertyValue,
        "occupancy": refinanceData.occupancy,
        "propertyStreetAddress": refinanceData.addressInformation.address,
        "county": refinanceData.addressInformation.county,
        "state": refinanceData.addressInformation.stateCode,
        "zipCode": refinanceData.addressInformation.zipCode,
        "propertyType": refinanceData.propertyType,
        "salesPrice": refinanceData.propertyValue,
        "numberOfUnits": refinanceData.numberOfUnits
      },
      "loanInformation": {
        "amortizationTypes": [
          refinanceData.amortizationType
        ],
        "loanType": type,
        "armFixedTerms": refinanceData.armFixedTerm !== undefined ? refinanceData.armFixedTerm.split(",") : refinanceData.armFixedTerm,
        "cashOutAmount": refinanceData.cashOutAmount,
        "baseLoanAmount": refinanceData.cashOutAmount + refinanceData.firstMortgageAmount + refinanceData.secondMortgageAmount + refinanceData.revolvingTradesBalance,
        "loanTerms": refinanceData.loanTerm.split(","),
        "representativeFICO": refinanceData.creditScore,
        "loanLevelDebtToIncomeRatio": refinanceData.debtToIncomeRatio
      }
    }
  }

  const dynamicData_fha = handleDynamicData("fha");
  const dynamicData_conventional = handleDynamicData("Conventional");
  const dynamicData_va = handleDynamicData_va("VA");

  const getPayload_fha = () => {
    return {
      ...staticPayload,
      "borrowerInformation": {
        ...staticPayload.borrowerInformation,
        ...dynamicData_fha.borrowerInformation
      },
      "propertyInformation": {
        ...staticPayload.propertyInformation,
        ...dynamicData_fha.propertyInformation
      },
      "loanInformation": {
        ...staticPayload.loanInformation,
        ...dynamicData_fha.loanInformation
      },
      "customerInternalId": "OBSearch",
      "representativeFICO": refinanceData.creditScore,
      "loanLevelDebtToIncomeRatio": refinanceData.debtToIncomeRatio
    }
  }

  const getPayload_conventional = () => {
    return {
      ...staticPayload,
      "borrowerInformation": {
        ...staticPayload.borrowerInformation,
        ...dynamicData_conventional.borrowerInformation
      },
      "propertyInformation": {
        ...staticPayload.propertyInformation,
        ...dynamicData_conventional.propertyInformation
      },
      "loanInformation": {
        ...staticPayload.loanInformation,
        ...dynamicData_conventional.loanInformation
      },
      "customerInternalId": "OBSearch",
      "representativeFICO": refinanceData.creditScore,
      "loanLevelDebtToIncomeRatio": refinanceData.debtToIncomeRatio
    }
  }

  const getPayload_va = () => {
    return {
      ...staticPayload,
      "borrowerInformation": {
        ...staticPayload.borrowerInformation,
        ...dynamicData_va.borrowerInformation
      },
      "propertyInformation": {
        ...staticPayload.propertyInformation,
        ...dynamicData_va.propertyInformation
      },
      "loanInformation": {
        ...staticPayload.loanInformation,
        ...dynamicData_va.loanInformation
      },
      "customerInternalId": "OBSearch",
      "representativeFICO": refinanceData.creditScore,
      "loanLevelDebtToIncomeRatio": refinanceData.debtToIncomeRatio
    }
  }


  useEffect(() => {
    const fetchOBRates = async () => {
      try {
        let responseConventional = [];
        let responseFHA = [];
        let responseVA = [];
        if (refinanceData.isVeteran === "No") {
          responseConventional = await axios.post(`${API_URL}/customer-service/api/v1/refi/ob/rateSheet`, getPayload_conventional(), { headers: { "Content-Type": "application/json" } });
          responseFHA = await axios.post(`${API_URL}/customer-service/api/v1/refi/ob/rateSheet`, getPayload_fha(), { headers: { "Content-Type": "application/json" } });
        }
        else {
          responseVA = await axios.post(`${API_URL}/customer-service/api/v1/refi/ob/rateSheet`, getPayload_va(), { headers: { "Content-Type": "application/json" } });
        }
        let response = [];
        if(refinanceData.isVeteran=="No") {
        if ((responseFHA.data.products).length === 0) {
          response = getRateCards(responseConventional.data.products, 98, 100, 100.5);
          
        }
        else if ((responseConventional.data.products).length === 0) {
          response = getRateCards(responseFHA.data.products, 98, 100, 100.75);
        }
        else {
          response =getRateCards(responseConventional.data.products, 98, 100, 100.5);
         response = response.concat(getRateCards(responseFHA.data.products, 98, 100, 100.75));
        }
      }
      else {
        response = getRateCards(responseVA.data.products, 98, 100, 100.75);
      }
        setRatesData(response);
        setLoading(false);
      }
      catch (error) {
        console.error("Failed to fetch OB Rates", error);
      }
    };

    fetchOBRates();
  }, []);

  return (
    <div>
      {loading ? (<div className="spinner-container"><div className="spinner"></div></div>) : (
        !ratesData || ratesData.length === 0 ?
          (
            <div className="message-container"><div className="message">
              <b>Although our automated system did not return any programs, it doesn’t mean you do not qualify for an offer.</b>
             We have sent your contact information to a mortgage advisor, who will reach out to you shortly.
              <button className="message-button" onClick={handleHome}>Home</button></div></div>)
          : (
            <div className="rates-container">
              <div className="main-title">
                  <b>Congratulations!</b>You're one step closer to better interest rates and more manageable payments with our specialized refinance offers. Choose the rate to avail your offer.
              </div>
              <div className="rate-cards-container">
                {ratesData.map((product, index) => (
                  <div key={index}
                    className={`rate-card ${selectedRateCard === (index + 1) ? 'selected' : ''}`}
                    onClick={() => handleRateCardClick(index)}>
                    <div className="card-title">
                      <div className="prod-name">
                        <h3 className="product-name">{getProductName(product)}</h3>
                      </div>
                      <div className="interest">
                        <h5 className="rate"><b>{product.rate}%</b></h5>
                        <h5 className="interest-title">interest rate</h5>
                      </div>
                    </div>
                    <div className="rate-info">
                      <Box>
                        <Grid container>
                          <Grid item xs={6}>
                            <Typography sx={{ fontSize: '15px', color: '#808084', textAlign: 'left' }}>Rate</Typography>
                            <Typography sx={{ fontSize: '15px', color: '#808084', textAlign: 'left' }}>APR</Typography>
                            <Typography sx={{ fontSize: '15px', color: '#808084', textAlign: 'left' }}>Discount Points</Typography>
                            
                      
                    <Typography sx={{ fontSize: '15px', color: '#808084', textAlign: 'left' }}>Lender Credit</Typography>
                
                <Typography sx={{ fontSize: '15px', color: '#808084', textAlign: 'left' }}>Total Payment</Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography sx={{ fontSize: '15px', textAlign: 'right' }}>{product.rate}%</Typography>
                            <Typography sx={{ fontSize: '15px', textAlign: 'right' }}>{product.apr}%</Typography>
                            <Typography sx={{ fontSize: '15px', textAlign: 'right' }}>${product.closingCost}</Typography>
                           
                           
                    <Typography sx={{ fontSize: '15px', textAlign: 'right' }}>${product.lenderCredit}</Typography>
                
                             <Typography sx={{ fontSize: '15px', textAlign: 'right' }}>${product.totalPayment}</Typography>
                          </Grid>
                        </Grid>
                      </Box>
                    </div>
                  </div>
                ))}
              </div>
              <div className="select-button">
                <button className="refill" onClick={handleEdit}>Refill data</button>
                <button className="select" onClick={handleSelection}
                  disabled={!selectedRateCard}>Select</button>

              </div>
            </div>)
      )}
    </div>
  );
};

export default RateCards;