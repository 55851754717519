import { Button } from "@mui/material";
import React from "react";

class SubmittedLoanDashboardSaveGrid extends React.PureComponent {
  render() {
    return (
      <div
        className="save_grid_main_div"
      >
        {
          this.props.hideBackButton ? <div /> : <Button className="back_button">Back</Button>
        }
        <div className="save_next_buttons_div">
          {!this.props.hideSaveButton && <Button className="save_button">Save</Button>}
          <Button
            className= "next_button"
            onClick={() => this.props.submitFunc()}
          >
            Submit
          </Button>
        </div>
      </div>
    );
  }
}

export default SubmittedLoanDashboardSaveGrid;
