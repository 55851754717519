import React, { useEffect } from "react";
import JsonSchemaForms from "../../../json_schema_forms";
import list_of_Assets from "./list_of_Assets.json";
import { connect } from "react-redux";
import { mapDispatchToProps, mapStateToProps, setValueByRecursion } from "../../../helpers";
import _ from "lodash";

function ListOfAssets(props) {
  const [state, setState] = React.useState(props.componentState);
  const [jsonSchema, setJsonSchema] = React.useState(_.cloneDeep(list_of_Assets));
  const giftFilter = { "Gift of Cash": "GiftOfCash", "Gift of Property Equity": "GiftOfPropertyEquity", "Grant": "Grant" };

  const vodFilter = {
    "Checking Account": "checkingAccount", "Savings Account": "savingsAccount", "Money Market Fund": "moneyMarketFund", "Certificate of Deposit": "certificateOfDeposit",
    "Mutual Funds": "mutualFunds", "Stock": "stocks", "Stock Options": "stockOptions", "Bond": "bond", "Retirement Funds": "retirementFund", "Bridge Loan Not Deposited": "bridgeLoanNotdeposited",
    "Individual Development Account": "individualDevelopmentAccount", "Life Insurance": "lifeInsurance", "Trust Account": "trustAccount"
  }
  const otherAssetFilter = {
    "Annuity ": "Annuity", "Automobile ": "Automobile", "Boat ": "Boat", "Borrower Primary Home ": "BorrowerPrimaryHome",
    "Bridge Loan Not Deposited (FHA/VA)": "BridgeLoanNotDeposited", "Cash On Hand": "CashOnHand", "Earnest Money": "EarnestMoney", "Employer Assistance": "EmployerAssistedHousing", "Lease Purchase Fund": "LeasePurchaseFund",
    "Lot Equity": "LotEquity", "Net Worth of Business Owned ": "NetWorthOfBusinessOwned", "Pending Net Sale Proceeds From Real Estate Assets": "PendingNetSaleProceedsFromRealEstateAssets",
    "Proceeds From Sale of Non Real Estate Assets": "ProceedsFromSaleOfNonRealEstateAsset", "Proceeds From Secured Loan": "ProceedsFromSecuredLoan", "Proceeds From Unsecured Loan": "ProceedsFromUnsecuredLoan",
    "Other Asset": "Other", "Recreational Vehicle ": "RecreationalVehicle", "Relocation Funds": "RelocationFunds", "Savings Bond ": "SavingsBond",
    "Severance Package ": "SeverancePackage", "Sweat Equity": "SweatEquity", "Trade Equity From Property Swap": "TradeEquityFromPropertySwap"
  }
  useEffect(() => {
    return () => {
      setJsonSchema(_.cloneDeep(list_of_Assets))
    }
  }, []);
  useEffect(() => {
    if (!_.isEmpty(props.componentState)) {
      setState(props.componentState);
    }
  }, [props.componentState]);

  useEffect(() => {
    if (props.componentSchema?.listOfAssets) {
      setJsonSchema(props.componentSchema["listOfAssets"]);
    }
  }, [props.componentSchema]);

  function filterAssets(dictionary) {
    let Assets = _.get(props.componentState, ["ManualAssetsEntry", "Assets"], [])
    return Assets.filter((entry) => {
      return dictionary.hasOwnProperty(entry?.selectTypeOfAsset)
    }).map(e => {
      let temp = _.clone(e);
      temp.selectTypeOfAsset = dictionary[e.selectTypeOfAsset]
      return temp;
    });
  }

  useEffect(() => {
    if (!_.isEmpty(props.componentState)) {
      let giftsOrGrants = filterAssets(giftFilter);
      let vods = filterAssets(vodFilter);
      let otherAssets = filterAssets(otherAssetFilter);
      let tempState = state;
      tempState = setValueByRecursion(tempState, ["giftsGrants"], (i) => giftsOrGrants, []);
      tempState = setValueByRecursion(tempState, ["vods"], (i) => vods, []);
      tempState = setValueByRecursion(tempState, ["otherAssets"], (i) => otherAssets, []);
      props.copyStateToRedux(tempState);
    }

  }, [props.componentState?.ManualAssetsEntry?.Assets]);


  const onLoadFunc = (updatedSchema) => {
    props.copySchema(updatedSchema, "listOfAssets")
  }

  const changeHandler = (updatedState, updatedSchema) => {
    props.copyStateToRedux(updatedState);
    props.copySchema(updatedSchema, "listOfAssets");
    setState(updatedState);
  };

  useEffect(() => {
    if (state?.ManageYourProperty?.RealEstateOwned.length > 0) {
      state?.ManageYourProperty?.RealEstateOwned?.map((each, index) => {
        if (each?.propertyWillBeUsedAs === "Investment Property") {
          state.ManageYourProperty.RealEstateOwned[index].propertyWillBeUsedAs = "Investment"
        }
      })
      setState(state)
    }
  }, [props.componentState])


  return (
    <JsonSchemaForms
      state={state}
      jsonData={jsonSchema}
      changeHandler={changeHandler}
      pageKey={"ListOfAssets"}
      onLoadFunc={onLoadFunc}
    />
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ListOfAssets);
