import React from "react";
import { connect } from "react-redux";
import { mapDispatchToProps, mapStateToProps } from "../../helpers";
import { loginRequest, msalConfig } from "./authConfig";
import {
  PublicClientApplication,
  InteractionStatus,
} from "@azure/msal-browser";
import { MsalProvider, withMsal } from "@azure/msal-react";
import BsmLogo from "../../assets/bsmLogo.png";
import CircularProgress from "@mui/material/CircularProgress";
import "./login.scss";
import { setLocalStorageValues } from "../../helpers/loginHelpers";
function Login(props) {
  const [isLogin, setIsLogin] = React.useState(false);
  const msalInstance = new PublicClientApplication(msalConfig);
  const login = () => {
    const request = {
      ...loginRequest,
      account: props.msalContext.accounts[0],
      redirectUri: "/auth/redirect",
      state: window.location.href,
    };
    props.msalContext.instance
      .acquireTokenPopup(request)
      .then((response) => {
        setLocalStorageValues(response);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const authHandler = () => {
    if (
      !localStorage.getItem("cx_access_token") &&
      props.msalContext.inProgress === InteractionStatus.None
    ) {
      setIsLogin(true);
      login();
    }
    setIsLogin(false);
  };

  return (
    <MsalProvider instance={msalInstance}>
      <div className={isLogin ? "textfieldIcon loginmaindiv" : "loginmaindiv"}>
        <div className="loginPageCard" data-test-id="loginPageCard">
          <div className="loginPageTitle" data-test-id="loginPageTitle">
            BSM CX
          </div>
          <div className="loginPageBsmLogoDiv">
            <img
              alt="loginpagebsm_logo"
              src={BsmLogo}
              className="loginPageBsmLogo"
            />
          </div>
          <div
            className="loginPageButtonDiv"
            data-test-id="signinButtonWithOtherOrganization"
            onClick={() => authHandler()}
          >
            <span className="loginPageButtonName">
              &nbsp;Get started with your journey!
            </span>
            {isLogin ? <CircularProgress className="loadingCircle" /> : ""}
          </div>
        </div>
      </div>
    </MsalProvider>
  );
}
export default connect(mapStateToProps, mapDispatchToProps)(withMsal(Login));
export {Login};