import React, { useEffect } from "react";
import JsonSchemaForms from "../../../json_schema_forms";
import declarations from "./declarations.json";
import { connect } from "react-redux";
import {
  mapDispatchToProps,
  mapStateToProps,
  setValueByRecursion,
} from "../../../helpers";
import { ethinity, race, sex } from "./constArray";
import _ from "lodash";

function Declarations(props) {
  const [state, setState] = React.useState({ declarations: {} });
  const [jsonSchema, setJsonSchema] = React.useState(_.cloneDeep(declarations));
  const AreYouApplyingForAJointCreditWithACoBorrower =
    state?.JointCredit?.AreYouApplyingForAJointCreditWithACoBorrower;
  useEffect(() => {
    return () => {
      setJsonSchema(_.cloneDeep(declarations));
    };
  }, []);

  useEffect(() => {
    if (AreYouApplyingForAJointCreditWithACoBorrower === "Yes") {
      applicantDetails_viewchnaging(
        [
          "properties",
          "Declarations",
          "properties",
          "ApplicantDeclarations",
          "properties",
          "BorrowerDeclarations",
          "view",
        ],
        "accordion"
      );
      address_viewchanging(
        [   
        "properties",
        "Declarations",
        "properties",
        "ApplicantDemographicInformation",
        "properties",
        "BorrowerDemographicInformation",
        "view",
        ],
        "accordion"
      );

    } else {
      applicantDetails_viewchnaging(
        [
          "properties",
          "Declarations",
          "properties",
          "ApplicantDeclarations",
          "properties",
          "BorrowerDeclarations",
          "view",
        ],
        "tile"
      );
      address_viewchanging(
        [
          "properties",
          "Declarations",
          "properties",
          "ApplicantDemographicInformation",
          "properties",
          "BorrowerDemographicInformation",
          "view",
        ],
        "tile"
      );
    }
  }, [AreYouApplyingForAJointCreditWithACoBorrower]);

  useEffect(() => {
    if (!_.isEmpty(props.componentState)) {
      setState(props.componentState);
    }
  }, [props.componentState]);

  useEffect(() => {
    if (props.componentSchema?.declarations) {
      setJsonSchema(props.componentSchema["declarations"]);
    }
  }, [props.componentSchema]);

  let schema;
  const applicantDetails_viewchnaging = (path, value) => {
    schema = setValueByRecursion(jsonSchema, path, (i) => value, []);
    props.copySchema(schema, "declarations");
  };

  const address_viewchanging = (path, value) => {
    schema = setValueByRecursion(schema, path, (i) => value, []);
    props.copySchema(schema, "declarations");
  };

  const onLoadFunc = (updatedSchema) => {
    props.copySchema(updatedSchema, "declarations");
  };
  const changeHandler = (updatedState, updatedSchema) => {
    let borrower_data = updatedState?.Declarations?.ApplicantDemographicInformation?.BorrowerDemographicInformation;
    let coborrower_data = updatedState?.Declarations?.ApplicantDemographicInformation?.CoBorrowerDemographicInformation;
    updatedSchema = borrowergetUpdatedSchema(
      ethinity,
      borrower_data,
      "IDoNotWishToProvideThisInformation(Ethnicity)",
      updatedSchema
    );
    updatedSchema = borrowergetUpdatedSchema(
      race,
      borrower_data,
      "IDoNotWishToProvideThisInformation(Race)",
      updatedSchema
    );
    updatedSchema = borrowergetUpdatedSchema(
      sex,
      borrower_data,
      "IDoNotWishToProvideThisInformation(Sex)",
      updatedSchema
    );

    updatedSchema = coBorrowergetUpdatedSchema(
      ethinity,
      coborrower_data,
      "IDoNotWishToProvideThisInformation(Ethnicity)",
      updatedSchema
    );
    updatedSchema = coBorrowergetUpdatedSchema(
      race,
      coborrower_data,
      "IDoNotWishToProvideThisInformation(Race)",
      updatedSchema
    );
    updatedSchema = coBorrowergetUpdatedSchema(
      sex,
      coborrower_data,
      "IDoNotWishToProvideThisInformation(Sex)",
      updatedSchema
    );
    props.copyStateToRedux(updatedState);
    props.copySchema(updatedSchema, "declarations");
    setState(updatedState);
  };
  const borrowergetUpdatedSchema = (arr, data, path, schema) => {
    let toggle;
    if (!_.isEmpty(data)) {
      for (const [key, value] of Object.entries(data)) {
        if (arr?.includes(key) && value === true) {
          toggle = true;
          break;
        } else if (arr?.includes(key) && typeof value === "object") {
          if (value[key] === true) {
            toggle = true;
            break;
          } else {
            toggle = false;
          }
        } else {
          toggle = false;
        }
      }
    }
    return setValueByRecursion(
      schema,
      [
        "properties",
        "Declarations",
        "properties",
        "ApplicantDemographicInformation",
        "properties",
        "BorrowerDemographicInformation",
        "properties",
        path,
        "disable",
      ],
      (i) => toggle,
      []
    );
  };
  const coBorrowergetUpdatedSchema = (arr, data, path, schema) => {
    let toggle;
    if (!_.isEmpty(data)) {
      for (const [key, value] of Object.entries(data)) {
        if (arr?.includes(key) && value === true) {
          toggle = true;
          break;
        } else if (arr?.includes(key) && typeof value === "object") {
          if (value[key] === true) {
            toggle = true;
            break;
          } else {
            toggle = false;
          }
        } else {
          toggle = false;
        }
      }
    }
    return setValueByRecursion(
      schema,
      [
        "properties",
        "Declarations",
        "properties",
        "ApplicantDemographicInformation",
        "properties",
        "CoBorrowerDemographicInformation",
        "properties",
        path,
        "disable",
      ],
      (i) => toggle,
      []
    );
  };
  return (
    <div className="declarationsStyle">
      <JsonSchemaForms
        state={state}
        jsonData={jsonSchema}
        changeHandler={changeHandler}
        pageKey={"declarations"}
        onLoadFunc={onLoadFunc}
      />
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Declarations);
export { Declarations };
