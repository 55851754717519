import React, { useState, useEffect } from 'react';
import QuestionBox from './QuestionBox';
import questionsData from './questions.json';
import axios from "axios";
import ErrorPopup from '../popup/ErrorPopup';
import ProgressWithLabel from './LinearProgress';
import { API_URL } from '../../redux/constants';
import { useNavigate } from "react-router-dom";

const UserQuestionnaire = ({ handleFormPreview, userCode }) => {
  const navigate = useNavigate();
  const [questions, setQuestions] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [errorPopUp, setErrorPopUp] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [userInformation, setUserInformation] = useState({});
  const [errors, setErrors] = useState('');

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await axios.get(`${API_URL}/customer-service/api/v1/refi/customer/${userCode}`, { headers: { "Content-Type": "application/json" } });
        setQuestions(questionsData);
        setUserInformation(response.data);
      }
      catch (error) {
        console.error("Failed to fetch User Details");
      }
    };
    fetchUserDetails();
  }, [userCode], [errors]);


  const getInitialAnswer = (field) => {
    return userInformation[field] === undefined ? "" : userInformation[field];
  }

  const getNextQuestionIndex = () => {
    if (currentQuestion.condition) {
      const questionCondition = currentQuestion.condition;
      const isConditionSatisfied = userInformation[currentQuestion.field] === questionCondition.value;

      if ((userInformation[currentQuestion.field]==="SingleFamily" || userInformation[currentQuestion.field]==="Condo" || userInformation[currentQuestion.field]==="TownHouse")) { 
        userInformation['numberOfUnits']=1;
      }

      if(currentQuestion.field === 'isVeteran' && userInformation[currentQuestion.field]==='No'){
        userInformation["typeOfVeteran"]=null;
      }



      if (!isConditionSatisfied) { 
        return currentQuestionIndex + 2;
      }
    }

    return currentQuestionIndex + 1;

  };

  const getPreviousQuestionIndex = () => {
    if (currentQuestionIndex - 2 > 0) {
      const prevQuestion = questions[currentQuestionIndex - 2];
      if (prevQuestion.condition) {
        const questionCondition = prevQuestion.condition;
        const isConditionSatisfied = userInformation[prevQuestion.field] === questionCondition.value;

        if (!isConditionSatisfied) {
          return currentQuestionIndex - 2;
        }
      }
    }
    return currentQuestionIndex - 1;
  }


  const handleAnswerChange = (questionId, newAnswer) => {
    setUserInformation((prevUserInformation) => ({
      ...prevUserInformation,
      [questionId]: newAnswer
    }));
  };

  const handleNextQuestion = () => {
    if (currentQuestionIndex < questions.length - 1) {
      const nextQuestionIndex = getNextQuestionIndex();
      setCurrentQuestionIndex(nextQuestionIndex);
    }
  };

  const resetErrors = () => {
    setErrors({});
  };

  const handlePreviousQuestion = () => {
    resetErrors();
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(getPreviousQuestionIndex());
    }
  };

  const handlePreview = () => {
    handleFormPreview(userInformation);
  }

  const onErrorPopUpClose = () => {
    setErrorPopUp(false);
    navigate(process.env.REACT_APP_REFINANCE_URL);

  }

  const calculatePercentageCompletion = () => {
    const totalQuestions = questions.length;
    return Math.floor((currentQuestionIndex / (totalQuestions - 1)) * 100);
  };

  const currentQuestion = questions[currentQuestionIndex];
  const isDisabled = !userInformation[currentQuestion?.field] || Object.values(errors).some((error) => error !== "");


  return (
    <div className='container'>
      <div className='progress-bar'>
        <p>Approx. 3 min</p>
        <ProgressWithLabel value={calculatePercentageCompletion()} />
      </div>

      <div className="questionnaire-box">
        <div className='question-box'>
          {currentQuestion && (
            <QuestionBox
              question={currentQuestion}
              answer={getInitialAnswer(currentQuestion.field)}
              onChange={(newAnswer) =>
                handleAnswerChange(currentQuestion.field, newAnswer)
              }
              errors={errors}
              setErrors={setErrors}
            />
          )}
          <div className='navigation-buttons'>
            <div className="button-next">
              {currentQuestionIndex < questions.length - 1 && (
                <button
                  onClick={handleNextQuestion}
                  disabled={isDisabled}
                  className={
                    (!isDisabled)
                      ? 'next-button button'
                      : 'next-button disabled'
                  }
                >
                  Next
                </button>
              )}
            </div>
            <div className='button-preview'>
          {currentQuestionIndex === questions.length - 1 && (
            <button onClick={handlePreview}>Preview</button>
          )}
          </div>
            <div className="button-previous">
              {currentQuestionIndex > 0 && (
                <button onClick={handlePreviousQuestion}
                >Back</button>
              )}
            </div>
          
       </div>
        </div>

      </div>
      {errorPopUp && <ErrorPopup onErrorPopUpClose={onErrorPopUpClose} error={errorMessage} />}
    </div>
  );
};

export default UserQuestionnaire;